import { SettingsHeader } from "../Common/SettingsHeader";
import baseStyles from "../../index.module.scss";
import styles from "./index.module.scss";
import { Button } from "reactstrap";
import { CustomInput } from "../../../../../components";
import { BsTrash } from "react-icons/bs";
import clx from "classnames";
import {
  TRUNCATED_ACCOUNT_KEY_DESC,
  ACCOUNT_KEY_DESC,
  ACCOUNT_SECRET_DESC,
} from "../../../../../constants";
import { SettingsEmptyState } from "../Common/SettingsEmptyState";

const noOp = () => {};

export const AccountKeysSettings = ({
  accountKeys = [],
  onCreateNewAccountKey,
  onKeyNameChange,
  onDeleteAccountKey,
  keyNameErrIdx,
}) => {
  const accountKeysItem = accountKeys.map((keyData, idx) => {
    const { accountSecret, accountKey, name } = keyData;
    return (
      <div key={idx} className={styles.account_key_item}>
        <div className={styles.account_key_item_header}>
          <div className={styles.item_header}>{name || "Default"}</div>
          <div
            onClick={() => onDeleteAccountKey(idx)}
            className={styles.item_header_delete_icon}
          >
            <BsTrash title="Delete" />
          </div>
        </div>
        <div className={styles.account_key_item_body}>
          <CustomInput
            label="Key Name"
            fullWidth={true}
            onChange={(e) => onKeyNameChange(e, idx)}
            value={name}
            placeholder="Enter Key Name"
            id="accountKey"
            error={keyNameErrIdx === idx ? "Key name is required" : null}
            required={true}
          />
          <KeyPairValueComponent
            keyPrepend="account_key_"
            secretPrepend="account_secret_"
            accountKey={accountKey}
            accountSecret={accountSecret}
          />
        </div>
      </div>
    );
  });
  const isEmpty = accountKeys.length === 0;
  return (
    <div className={baseStyles.settings_item}>
      <SettingsHeader name="Account Keys" />
      <div className={baseStyles.settings_cotainer}>
        <div
          className={clx(
            styles.account_keys_item_wrapper,
            isEmpty && styles.max_height
          )}
        >
          <div className={styles.account_keys_cta}>
            <Button
              disabled={accountKeys.length === 2}
              onClick={onCreateNewAccountKey}
              className="primary-btn"
            >
              Create New
            </Button>
          </div>
          <div className={baseStyles.setting_item_info}>
            Account keys can be used to create and manage apps on your account.
            You can have up to 2 account keys at a time.
          </div>
          {isEmpty ? (
            <SettingsEmptyState text="No account keys created" />
          ) : (
            accountKeysItem
          )}
        </div>
      </div>
    </div>
  );
};

const KeyPairValueComponent = ({
  accountSecret = "",
  accountKey = "",
  keyPrepend = "",
  secretPrepend = "",
}) => {
  return (
    <>
      {accountSecret && (
        <div className="modal-note">
          Please copy your Account Key and Secret now. We won't be able to
          recover them later.
        </div>
      )}
      <CustomInput
        label="Account Key"
        fullWidth={true}
        disabled={true}
        type={accountSecret ? "textarea" : "text"}
        value={keyPrepend + accountKey}
        onChangeHandler={noOp}
        withCopy={true}
        id="accountKey"
        description={
          accountSecret ? ACCOUNT_KEY_DESC : TRUNCATED_ACCOUNT_KEY_DESC
        }
      />
      {accountSecret && (
        <CustomInput
          label="Account Secret"
          fullWidth={true}
          disabled={true}
          type={accountSecret ? "textarea" : "text"}
          value={secretPrepend + accountSecret}
          onChangeHandler={noOp}
          withCopy={true}
          id="accountKeySecret"
          description={ACCOUNT_SECRET_DESC}
        />
      )}
    </>
  );
};
