import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { isValidOrigin } from "../../../../../../utils";
import styles from "./index.module.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { UncontrolledTooltip } from "reactstrap";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export const AllowedOriginsInput = ({
  selectedOrigins,
  setWhitelistedOrigins,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(
    selectedOrigins.map((origin) => createOption(origin))
  );
  const [hasError, setHasError] = useState(false);

  const updateValues = (val) => {
    if (!isValidOrigin(val)) {
      setHasError(true);
      throw new Error("Invalid Origin");
    }
    if (selectedOrigins.includes(val.toLowerCase())) return; // avoid duplicates
    setValue((prev) => [...prev, createOption(val.toLowerCase())]);
    setWhitelistedOrigins([...selectedOrigins, val.toLowerCase()]);
  };

  const setValuesState = (vals) => {
    try {
      if (vals.includes(",")) {
        vals.split(",").forEach((val) => {
          if (val) updateValues(val.trim());
        });
        return;
      }
      updateValues(vals.trim());
    } catch (err) {
      console.log("Invalid input");
    } finally {
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValuesState(inputValue);
        event.preventDefault();
        break;
      default:
        setHasError(false);
        return;
    }
  };
  const onChangeHandler = (selectValues) => {
    setValue(selectValues);
    setWhitelistedOrigins(selectValues.map((item) => item.value));
  };
  const onBlurHandler = () => {
    if (!inputValue) return;
    setValuesState(inputValue);
  };
  return (
    <>
      <div className="label-wrapper">
        <label>Allowed Origins</label>
        <AiOutlineQuestionCircle id={`tooltip-whitelist-origins`} />
        <UncontrolledTooltip
          popperClassName="tooltip-popper"
          target={`tooltip-whitelist-origins`}
          placement="top"
          autohide={false}
        >
          Secure your user wallet by entering a comma separated list of allowed
          origins. Only allowed origins will be allowed to use your app id for
          user wallet operations. The origin must start with https or http and
          can include an optional port.
          <br />
          Here's an example: https://www.example.com, http://example.com,
          http://localhost:3000.
          <br />
          <b>
            Note that if no origin is provided, the user wallet will work on all
            origins.
          </b>
        </UncontrolledTooltip>
      </div>
      <CreatableSelect
        data-testid="whitelist_origins_input"
        components={components}
        inputValue={inputValue}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: hasError ? "red !important" : "#848484 !important",
          }),
        }}
        className={styles.react_select_container}
        isClearable
        isMulti
        menuIsOpen={false}
        onBlur={onBlurHandler}
        onChange={(newValue) => onChangeHandler(newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder="Whitelist Origins"
        value={value}
        classNamePrefix="react_select"
      />
      {hasError && (
        <span className={styles.domain_error}>Please enter a valid origin</span>
      )}
    </>
  );
};
