"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.signOut = exports.isValidSession = exports.verifyTOTPCode = exports.generateTOTPCodeSrc = void 0;
const aws_amplify_1 = require("aws-amplify");
const constants_1 = require("../constants");
const frontend_utils_1 = require("frontend-utils");
const _1 = require(".");
const logger_1 = require("frontend-utils/logger");
const generateTOTPCodeSrc = () => __awaiter(void 0, void 0, void 0, function* () {
    const user = yield aws_amplify_1.Auth.currentAuthenticatedUser();
    const code = yield aws_amplify_1.Auth.setupTOTP(user);
    const email = user.attributes.email; // TODO: Remove any
    const codeSrc = `otpauth://totp/${constants_1.TOTP_ISSUER}:` +
        email +
        "?secret=" +
        code +
        `&issuer=${constants_1.TOTP_ISSUER}`;
    return codeSrc;
});
exports.generateTOTPCodeSrc = generateTOTPCodeSrc;
const verifyTOTPCode = (otp) => __awaiter(void 0, void 0, void 0, function* () {
    const user = yield aws_amplify_1.Auth.currentAuthenticatedUser();
    yield aws_amplify_1.Auth.verifyTotpToken(user, otp);
    return true;
});
exports.verifyTOTPCode = verifyTOTPCode;
const isValidSession = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // Check if user is logged in
        const currentSession = yield aws_amplify_1.Auth.currentSession();
        const user = yield (0, _1.getUserInfo)();
        if (!user) {
            logger_1.logger.debug("isValidSession: User not found");
            return false;
        }
        const idToken = currentSession.getIdToken();
        if (!idToken) {
            logger_1.logger.debug("isValidSession: IdToken not found");
            return false;
        }
        // Check if user has a valid session key
        const key = yield frontend_utils_1.SessionKeysUtils.fetchKeysFromDb({
            keyPrefix: constants_1.SESSION_KEY_PREFIX,
            parsedUser: user,
            sessionId: idToken.payload.origin_jti,
            keyType: frontend_utils_1.SessionKeysUtils.SESSION_KEY_TYPES.CRYPTO,
        });
        if (!key) {
            logger_1.logger.debug("isValidSession: Session key not found");
            return false;
        }
        return true;
    }
    catch (error) {
        logger_1.logger.debug("isValidSession: Error", error);
        return false;
    }
});
exports.isValidSession = isValidSession;
const signOut = () => __awaiter(void 0, void 0, void 0, function* () { return yield aws_amplify_1.Auth.signOut(); });
exports.signOut = signOut;
