import React from "react";
import clx from "classnames";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styles from "../index.module.scss";
import { ToolTip } from "../../../../../components/ToolTip";
import { useSelector } from "react-redux";
import { ENVIRONMENTS_MAP, ENVIRONMENT_ITEM } from "../../../../../constants";
import { isFeatureEnabled } from "../../../../../redux/slice/dashboardSlice";
import { PlanTag } from "../../../../../components/PlanTag";

interface DropdownItemsProps {
  item: ENVIRONMENT_ITEM;
  keyToChange: string;
  keyToAdd: string;
  // TODO: Fix any type
  handleChange: (key: string, value: any) => void;
  // TODO: Fix any type
  rawAppData: any;
}

const DropdownItems: React.FC<DropdownItemsProps> = ({
  item,
  keyToChange,
  keyToAdd,
  handleChange,
  rawAppData,
}) => {
  const hasAccess = useSelector(isFeatureEnabled(item.featureWhitelist));
  const isDisabled = !!item.featureWhitelist && !hasAccess;

  return (
    <DropdownItem
      className={styles.create_app_dropdown_item}
      key={item.value}
      disabled={isDisabled}
      onClick={() => {
        if (keyToChange === "rawAppData") {
          handleChange(keyToChange, {
            ...rawAppData,
            [keyToAdd]: item.value,
          });
        }
      }}
    >
      {item.name}
      {item.featureWhitelist && (
        <div className={styles.plan_tag_container}>
          <PlanTag locked={!hasAccess} id={`chain-${item.value}`} />
        </div>
      )}
    </DropdownItem>
  );
};

interface CreateAppMenuDropdownProps {
  dropdownOpen: boolean;
  onDropdownToggle: () => void;
  // TODO: Fix any type
  handleChange: (key: string, value: any) => void;
  selectedValue: string;
  error: string;
  itemsMap: typeof ENVIRONMENTS_MAP;
  label: string;
  placeholder: string;
  keyToChange: string;
  keyToAdd?: string;
  // TODO: Fix any type
  rawAppData: any;
  tooltipContent: string;
  tooltipId: string;
}

export const CreateAppMenuDropdown = ({
  dropdownOpen,
  onDropdownToggle,
  handleChange,
  selectedValue,
  error,
  itemsMap,
  label,
  placeholder,
  keyToChange,
  keyToAdd = "",
  rawAppData,
  tooltipContent,
  tooltipId,
}: CreateAppMenuDropdownProps) => {
  const findDisplayNameByValue = (valueToFind: string) => {
    for (const key in itemsMap) {
      if (itemsMap[key as keyof typeof itemsMap].value === valueToFind) {
        return itemsMap[key as keyof typeof itemsMap].name;
      }
    }
    return null;
  };

  return (
    <div className="input-wrapper">
      <div className="label-wrapper">
        <label htmlFor={label}>
          {label} <sup>*</sup>
        </label>
        <ToolTip id={tooltipId} content={tooltipContent} />
      </div>
      <Dropdown isOpen={dropdownOpen} toggle={onDropdownToggle}>
        <DropdownToggle
          className={clx(
            "w-100 d-flex justify-content-between align-items-center",
            styles.create_app_dropdown_toggle,
            error && styles.has_error
          )}
          caret
          color=""
        >
          {selectedValue ? (
            findDisplayNameByValue(selectedValue)
          ) : (
            <div className={styles.dropdown_toggle_placeholder}>
              {placeholder}
            </div>
          )}
        </DropdownToggle>
        <DropdownMenu>
          {Object.values(itemsMap).map((item) => (
            <DropdownItems
              key={item.value}
              item={item}
              keyToChange={keyToChange}
              keyToAdd={keyToAdd}
              handleChange={handleChange}
              rawAppData={rawAppData}
            />
          ))}
        </DropdownMenu>
      </Dropdown>
      {error && <span className="error-message">{error}</span>}
    </div>
  );
};
