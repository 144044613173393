"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.base64Decode = void 0;
/**
 * Decodes a base64url string into a Uint8Array using normal base64 decoding.
 * @param {string} b64urlstring - The base64url string to decode.
 * @returns {Uint8Array} - The decoded Uint8Array.
 */
const base64Decode = (b64urlstring) => {
    const base64string = b64urlstring.replace(/-/g, "+").replace(/_/g, "/");
    const binaryString = atob(base64string);
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }
    return uint8Array;
};
exports.base64Decode = base64Decode;
