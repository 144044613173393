import { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { BiCopy } from "react-icons/bi";
export const CopyIcon = ({ text = "", id }) => {
  const [copied, setCopied] = useState(false);

  const onCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      <BiCopy
        className="input-icon"
        onClick={() => {
          onCopy(text);
        }}
        id={`copy-${id}`}
        data-testid={id}
      />
      <UncontrolledTooltip className="modal-tooltip" target={`copy-${id}`}>
        {copied ? "Copied" : "Copy"}
      </UncontrolledTooltip>
    </>
  );
};
