import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { BsThreeDots } from "react-icons/bs";
import { APP_MENU_TABS } from "../../../../../constants";

export const AppCardMenuDropdown = ({
  onToggle,
  isOpen,
  onDropdownItemClick,
  appType,
}) => {
  const menu = APP_MENU_TABS.filter(
    (item) => item.supported_app_types.indexOf(appType) !== -1
  );
  return (
    <Dropdown toggle={onToggle} isOpen={isOpen} className="app-dropdown">
      <DropdownToggle data-toggle="dropdown" tag="span">
        <BsThreeDots />
      </DropdownToggle>
      <DropdownMenu className="app-menu">
        {menu.map((item) => (
          <DropdownItem
            key={item.id}
            className="app-menu-item"
            id={item.id}
            onClick={onDropdownItemClick}
          >
            {item.icon} {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
