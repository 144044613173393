import clx from "classnames";
import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Collapse,
} from "reactstrap";
import {
  SUPPORTED_CHAINS,
  CHAIN_ID_FOR_NETWORK,
  CHAIN_ITEM,
  NETWORK_TYPES,
  SUPPORTED_CHAIN_KEYS,
} from "../../../../../constants";
import styles from "../index.module.scss";
import { ToolTip } from "../../../../../components/ToolTip";
import { networkTooltipContent } from "../constants";
import { isFeatureEnabled } from "../../../../../redux/slice/dashboardSlice";
import { useSelector } from "react-redux";
import { PlanTag } from "../../../../../components";

interface ItemProps {
  item: CHAIN_ITEM;
  handleChainChange: (key: string, value: any) => void;
  rawAppData: any;
  isNested?: boolean;
}

interface ChainDropdownProps {
  chainsDropdownOpen: boolean;
  onChainsDropdownToggle: () => void;
  //  TODO: Fix any type
  handleChainChange: (key: string, value: any) => void;
  selectedChain: NETWORK_TYPES | null;
  // TODO: Fix any type
  rawAppData: any;
  networkErr?: string;
}

const Item: React.FC<ItemProps> = ({
  item,
  handleChainChange,
  rawAppData,
  isNested = false,
}) => {
  const hasAccess = useSelector(isFeatureEnabled(item.featureWhitelist));
  return (
    <div className={styles.create_app_dropdown_item_container}>
      <DropdownItem
        className={clx(
          styles.create_app_dropdown_item,
          styles.indented,
          isNested && styles.sub_item
        )}
        key={item.value}
        disabled={!!item.featureWhitelist && !hasAccess}
        onClick={() => {
          handleChainChange("rawAppData", {
            ...rawAppData,
            network: item.value,
          });
        }}
      >
        {item.displayName}
        {item.featureWhitelist && (
          <div className={styles.plan_tag_container}>
            <PlanTag locked={!hasAccess} id={`chain-${item.value}`} />
          </div>
        )}
      </DropdownItem>
    </div>
  );
};

export const ChainDropdown: React.FC<ChainDropdownProps> = ({
  chainsDropdownOpen,
  onChainsDropdownToggle,
  handleChainChange,
  selectedChain,
  rawAppData,
  networkErr,
}) => {
  const [collapseStates, setCollapseStates] = useState<{
    [key in NETWORK_TYPES | "SKALE"]?: boolean;
  }>({});

  const chainsDropdown: JSX.Element[] = [];

  const toggleCollapse = (item: CHAIN_ITEM) => {
    setCollapseStates((states) => ({
      ...states,
      [item.value]: !states[item.value],
    }));
  };

  (Object.keys(SUPPORTED_CHAINS) as SUPPORTED_CHAIN_KEYS[]).forEach((key) => {
    const chainItems = SUPPORTED_CHAINS[key];

    chainsDropdown.push(
      <DropdownItem key={key} header>
        {key}
      </DropdownItem>
    );
    chainItems.forEach((item: CHAIN_ITEM) => {
      if (item.items) {
        chainsDropdown.push(
          <>
            <button
              type="button"
              tabIndex={0}
              onClick={() => toggleCollapse(item)}
              className={clx(
                styles.create_app_dropdown_item,
                styles.indented,
                "dropdown-item",
                "dropdown-toggle",
                "sub-dropdown-toggle"
              )}
            >
              {item.displayName}
            </button>
            <Collapse isOpen={!!collapseStates[item.value]}>
              {item.items.map((subItem) => (
                <Item
                  key={subItem.value}
                  item={subItem}
                  handleChainChange={handleChainChange}
                  rawAppData={rawAppData}
                  isNested
                />
              ))}
            </Collapse>
          </>
        );
      } else {
        chainsDropdown.push(
          <Item
            key={item.value}
            item={item}
            handleChainChange={handleChainChange}
            rawAppData={rawAppData}
          />
        );
      }
    });
  });

  return (
    <div className="input-wrapper">
      <div className="label-wrapper">
        <label htmlFor="network">
          Chain <sup>*</sup>
        </label>
        <ToolTip id="network-tooltip" content={networkTooltipContent} />
      </div>
      <Dropdown
        id="network"
        isOpen={chainsDropdownOpen}
        toggle={onChainsDropdownToggle}
      >
        <DropdownToggle
          className={clx(
            "w-100 d-flex justify-content-between align-items-center",
            styles.create_app_dropdown_toggle,
            networkErr && styles.has_error
          )}
          caret
          color=""
        >
          {selectedChain ? (
            CHAIN_ID_FOR_NETWORK[selectedChain].DISPLAY_NAME
          ) : (
            <div className={styles.dropdown_toggle_placeholder}>
              Select Chain
            </div>
          )}
        </DropdownToggle>
        <DropdownMenu>{chainsDropdown}</DropdownMenu>
      </Dropdown>
      {networkErr && <span className="error">{networkErr}</span>}
    </div>
  );
};
