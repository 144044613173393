"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCachedAppDataByAppId = exports.setCachedAppData = exports.getCachedAppData = exports.getAppTheme = exports.getSystemTheme = exports.base64Utils = exports.PasskeyUtils = exports.MetakeepAuth = exports.AAWalletUtils = exports.storage = exports.StorageWrapper = exports.SessionKeysUtils = exports.CryptoUtils = void 0;
const constants_1 = require("./constants");
const CryptoUtils = __importStar(require("./cryptoUtils"));
exports.CryptoUtils = CryptoUtils;
const SessionKeysUtils = __importStar(require("./sessionKeyUtils"));
exports.SessionKeysUtils = SessionKeysUtils;
const AAWalletUtils = __importStar(require("./aaWalletUtils"));
exports.AAWalletUtils = AAWalletUtils;
const PasskeyUtils = __importStar(require("./passkeyUtils"));
exports.PasskeyUtils = PasskeyUtils;
const storageWrapper_1 = require("./storageWrapper");
Object.defineProperty(exports, "StorageWrapper", { enumerable: true, get: function () { return storageWrapper_1.StorageWrapper; } });
Object.defineProperty(exports, "storage", { enumerable: true, get: function () { return storageWrapper_1.storage; } });
const MetakeepAuth_1 = require("./MetakeepAuth");
Object.defineProperty(exports, "MetakeepAuth", { enumerable: true, get: function () { return MetakeepAuth_1.MetakeepAuth; } });
const base64Utils = __importStar(require("./base64Utils"));
exports.base64Utils = base64Utils;
__exportStar(require("./ParsedUser"), exports);
__exportStar(require("./User"), exports);
const getSystemTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches
    ? constants_1.THEME.DARK
    : constants_1.THEME.LIGHT;
exports.getSystemTheme = getSystemTheme;
const getAppTheme = (appData = {}) => {
    const theme = (appData === null || appData === void 0 ? void 0 : appData.appTheme) || constants_1.THEME.LIGHT;
    return theme === constants_1.THEME.AUTO ? (0, exports.getSystemTheme)() : theme;
};
exports.getAppTheme = getAppTheme;
const getCachedAppData = () => {
    try {
        const cachedAppData = storageWrapper_1.storage.getItem(constants_1.LS_APP_DATA_KEY) || "{}";
        return JSON.parse(cachedAppData);
    }
    catch (err) {
        return {};
    }
};
exports.getCachedAppData = getCachedAppData;
const setCachedAppData = (appId, data) => {
    const cachedAppData = (0, exports.getCachedAppData)();
    const newData = Object.assign(Object.assign({}, cachedAppData), { [appId]: data });
    storageWrapper_1.storage.setItem(constants_1.LS_APP_DATA_KEY, JSON.stringify(newData));
};
exports.setCachedAppData = setCachedAppData;
const getCachedAppDataByAppId = (appId) => {
    const cachedAppData = (0, exports.getCachedAppData)();
    return cachedAppData[appId] || null;
};
exports.getCachedAppDataByAppId = getCachedAppDataByAppId;
