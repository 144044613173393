import { Spinner } from "reactstrap";

export const Loader = ({ backdropStyle = {} }) => {
  return (
    <div className="loader-wrapper">
      <div style={backdropStyle} className="loader-backdrop">
        <Spinner color="dark" />
      </div>
    </div>
  );
};
