import React, { useState } from "react";
import clx from "classnames";
import styles from "./index.module.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip,
} from "reactstrap";
import {
  USER_MESSAGE_TEMPLATE_OPTIONS_MAP,
  CUSTOM_USER_MESSAGE_TEMPLATE_FEATURE,
} from "../../../../../../constants";
import { withFeature } from "../../../../../../hoc";
import { PlanTag } from "../../../../../../components";

export const UserMessageTemplateSelect = withFeature(
  ({ onClick, value, isDisabled }) => {
    const [isActive, setActive] = useState(false);
    const onToggle = () => {
      setActive(!isActive);
    };
    const options = Object.values(USER_MESSAGE_TEMPLATE_OPTIONS_MAP).map(
      (option) => (
        <DropdownItem
          key={option.value}
          onClick={() => onClick(option.value)}
          value={option.value}
        >
          {option.displayName}
        </DropdownItem>
      )
    );
    const displayVal = USER_MESSAGE_TEMPLATE_OPTIONS_MAP[value]?.displayName;
    return (
      <div className={styles.reason_string_select_wrapper}>
        <div className={clx("label-wrapper")}>
          <label htmlFor="messageTemplateSelect">User Message Template</label>
          <AiOutlineQuestionCircle id={`tooltip-message-template`} />
          <UncontrolledTooltip
            popperClassName="tooltip-popper"
            target={`tooltip-message-template`}
            placement="top"
            autohide={false}
          >
            <div className={styles.custom_message_select_tooltip_info}>
              MetaKeep APIs and SDK allow you to provide a human-friendly
              explanation for a blockchain transaction through the "reason"
              attribute when requesting user consent. You can choose how the
              "reason" is explained to the user:
            </div>
            <div className={styles.custom_message_tooltip_title}>
              Raw Message:
            </div>
            <div className={styles.custom_message_select_tooltip_desc}>
              The confirmation dialog directly shows the "reason" as it is.
            </div>
            <div className={styles.custom_message_tooltip_title}>
              Brand Permission Message
            </div>
            <div className={styles.custom_message_select_tooltip_desc}>
              {
                'The confirmation dialog shown to the user will follow the format "<Brand> requests your permission to <Action>", where <Action> is directly copied from the specified "reason" attribute. This is the default option.'
              }
            </div>
          </UncontrolledTooltip>
          <PlanTag locked={isDisabled} id="message-template" />
        </div>
        <Dropdown
          id="messageTemplateSelect"
          isOpen={isActive}
          toggle={onToggle}
        >
          <DropdownToggle
            caret
            className="w-100 d-flex justify-content-between align-items-center"
            color=""
            disabled={isDisabled}
            data-testid="user-message-template-dropdown"
          >
            {displayVal || "Select Message Template"}
          </DropdownToggle>
          <DropdownMenu>{options}</DropdownMenu>
        </Dropdown>
      </div>
    );
  },
  CUSTOM_USER_MESSAGE_TEMPLATE_FEATURE
);
