import { UncontrolledTooltip } from "reactstrap";
import clx from "classnames";

import { CustomInput, PlanTag } from "../../../../../../components";
import styles from "./index.module.scss";

import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useState } from "react";
import { withFeature } from "../../../../../../hoc";
import { CUSTOM_FONT_SIZE_FEATURE } from "../../../../../../constants";

const MAX_VALUE = 1.5;
const MIN_VALUE = 1;
const STEP = 0.01;

export const FontScalingFactorInput = withFeature(
  ({ value, onChange, isDisabled }) => {
    const [tempValue, setTempValue] = useState(value);

    const updateValues = (val) => {
      setTempValue(val);
      onChange(val);
    };

    const onChangeHandler = (e) => {
      const val = e.target.value;
      updateValues(val);
    };

    const onInputBoxChange = (e) => {
      const val = e.target.value;
      if (val && val > MAX_VALUE) return;
      if (val && val < MIN_VALUE) return;
      if (val && val.length > 4) return;
      if (!val) setTempValue("");
      else updateValues(val.toString());
    };

    const onBlurHandler = () => {
      if (!tempValue) {
        setTempValue(value);
      }
    };

    return (
      <div className={styles.font_size_select_container}>
        <div className={clx("label-wrapper")}>
          <label htmlFor="fontSizeSelect">Font Size Scaling</label>
          <AiOutlineQuestionCircle id={`tooltip-font-size`} />
          <UncontrolledTooltip
            popperClassName="tooltip-popper"
            target={`tooltip-font-size`}
            placement="top"
            autohide={false}
          >
            Enter a value to scale the font size. For example, entering 1.2 will
            increase the font size by 20%.
          </UncontrolledTooltip>
          <PlanTag locked={isDisabled} id="font-scaling" />
        </div>
        <div className={styles.font_size_input_wrapper}>
          <div className={styles.font_size_input}>
            <CustomInput
              isDisabled={isDisabled}
              wrapperClass={styles.input_wrapper}
              customCss={styles.font_size_range_input}
              type="range"
              onChangeHandler={(e) => onChangeHandler(e)}
              value={value}
              step={STEP}
              min={MIN_VALUE}
              max={MAX_VALUE}
            />
          </div>
          <CustomInput
            isDisabled={isDisabled}
            type="number"
            wrapperClass={styles.input_wrapper_text}
            customCss={styles.font_size_output}
            value={tempValue}
            onBlur={onBlurHandler}
            onChangeHandler={(e) => onInputBoxChange(e)}
          ></CustomInput>
        </div>
      </div>
    );
  },
  CUSTOM_FONT_SIZE_FEATURE
);
