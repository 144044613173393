import React, { useCallback, useEffect, useState } from "react";
import clx from "classnames";
import styles from "./index.module.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip,
} from "reactstrap";
import {
  DEFAULT_USER_WALLET_FONT,
  FONT_FAMILY_FEATURE,
} from "../../../../../../constants";
import { withFeature } from "../../../../../../hoc";
import { useSelector } from "react-redux";
import { selectAllowedFonts } from "../../../../../../redux/slice/dashboardSlice";
import { captlizeFirstLetterOnly } from "../../../../../../utils";
import { PlanTag } from "../../../../../../components";

export const FontFamilySelect = withFeature(
  ({ onClick, value, isDisabled }) => {
    const [isActive, setActive] = useState(false);
    const allowedFonts = useSelector(selectAllowedFonts);
    const onToggle = () => {
      setActive(!isActive);
    };

    const loadFont = useCallback(async () => {
      if (isDisabled || allowedFonts?.length === 0) return;
      for (let idx = 0; idx < allowedFonts.length; idx++) {
        const font = allowedFonts[idx];
        const { fontName, url } = font;
        if (fontName === DEFAULT_USER_WALLET_FONT) continue;
        const fontFace = new FontFace(
          captlizeFirstLetterOnly(fontName),
          `url(${url})`,
          {
            weight: 400,
            style: "normal",
          }
        );
        await fontFace.load();
        document.fonts.add(fontFace);
      }
    }, [allowedFonts, isDisabled]);

    useEffect(() => {
      loadFont();
    }, [loadFont]);

    const options = allowedFonts?.map((option) => {
      const { fontName } = option;
      const displayName =
        fontName === DEFAULT_USER_WALLET_FONT
          ? `${captlizeFirstLetterOnly(fontName)} (Default)`
          : captlizeFirstLetterOnly(fontName);
      return (
        <DropdownItem
          key={fontName}
          onClick={() => onClick(fontName)}
          value={fontName}
          style={{
            fontFamily:
              fontName === DEFAULT_USER_WALLET_FONT
                ? ""
                : captlizeFirstLetterOnly(fontName),
            fontSize: "14px",
          }}
        >
          {displayName}
        </DropdownItem>
      );
    });
    const displayVal = captlizeFirstLetterOnly(
      allowedFonts?.find((val) => val.fontName === value)?.fontName
    );
    const fontStyle = {
      fontFamily:
        displayVal.toLocaleUpperCase() === DEFAULT_USER_WALLET_FONT
          ? ""
          : displayVal,
    };
    return (
      <div className={styles.font_family_select}>
        <div className={clx("label-wrapper")}>
          <label htmlFor="fontFamilySelect">Text Font</label>
          <AiOutlineQuestionCircle id={`tooltip-font-family`} />
          <UncontrolledTooltip
            popperClassName="tooltip-popper"
            target={`tooltip-font-family`}
            placement="top"
            autohide={false}
          >
            Pick a font for the user wallet text. The default is Inter, a
            modern, versatile font.
          </UncontrolledTooltip>
          <PlanTag locked={isDisabled} id="font-family" />
        </div>
        <Dropdown id="fontFamilySelect" isOpen={isActive} toggle={onToggle}>
          <DropdownToggle
            caret
            className="w-100 d-flex justify-content-between align-items-center"
            color=""
            style={fontStyle}
            disabled={isDisabled}
          >
            {displayVal ? displayVal : "Select Font Family"}
          </DropdownToggle>
          <DropdownMenu>{options}</DropdownMenu>
        </Dropdown>
      </div>
    );
  },
  FONT_FAMILY_FEATURE
);
