"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParsedUser = exports.INVALID_PHONE_ERROR = exports.INVALID_EMAIL_ERROR = exports.USER_TYPE = void 0;
const libphonenumber_js_1 = require("libphonenumber-js");
const logger_1 = require("./logger");
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;
// User types
var USER_TYPE;
(function (USER_TYPE) {
    USER_TYPE["TYPE_EMAIL"] = "email";
    USER_TYPE["TYPE_PHONE"] = "phone";
    USER_TYPE["TYPE_DEVELOPER_EMAIL"] = "developerEmail";
})(USER_TYPE || (exports.USER_TYPE = USER_TYPE = {}));
// Error codes
exports.INVALID_EMAIL_ERROR = "INVALID_EMAIL";
exports.INVALID_PHONE_ERROR = "INVALID_PHONE";
class ParsedUser {
    constructor(user) {
        // Check that exactly one user identifier is present.
        if ([user.email, user.phone, user.developerEmail].filter(Boolean).length !== 1) {
            throw new Error("Exactly one user identifier should be present.");
        }
        // Check that no other fields are present.
        if (Object.keys(user).filter((key) => ![
            USER_TYPE.TYPE_EMAIL,
            USER_TYPE.TYPE_PHONE,
            USER_TYPE.TYPE_DEVELOPER_EMAIL,
        ].includes(key)).length > 0) {
            throw new Error("Invalid keys in user object.");
        }
        // Parse email
        if (user === null || user === void 0 ? void 0 : user.email) {
            // Validate email
            if (!EMAIL_REGEX.test(user.email)) {
                throw new Error(exports.INVALID_EMAIL_ERROR);
            }
            // Normalize email
            const email = user.email.toLowerCase();
            this._type = USER_TYPE.TYPE_EMAIL;
            this.user = { email: email };
            this._serializedUser = `${USER_TYPE.TYPE_EMAIL}:${email}`;
            this._displayString = email;
            return;
        }
        // Parse phone
        if (user === null || user === void 0 ? void 0 : user.phone) {
            // Validate phone number
            let parsedPhoneNumber;
            try {
                parsedPhoneNumber = (0, libphonenumber_js_1.parsePhoneNumber)(user.phone);
                if (!parsedPhoneNumber) {
                    throw new Error(exports.INVALID_PHONE_ERROR);
                }
                if (!parsedPhoneNumber.isValid()) {
                    throw new Error(exports.INVALID_PHONE_ERROR);
                }
            }
            catch (error) {
                logger_1.logger.debug("Error parsing phone number: ", error);
                throw new Error(exports.INVALID_PHONE_ERROR);
            }
            // Normalize phone number
            this._type = USER_TYPE.TYPE_PHONE;
            this.user = { phone: parsedPhoneNumber.format("E.164") };
            this._serializedUser = `${USER_TYPE.TYPE_PHONE}:${this.user.phone}`;
            this._displayString = parsedPhoneNumber.format("INTERNATIONAL", {
                humanReadable: true,
            });
            return;
        }
        // Parse developer email
        if (user === null || user === void 0 ? void 0 : user.developerEmail) {
            // Validate email
            if (!EMAIL_REGEX.test(user.developerEmail)) {
                throw new Error(exports.INVALID_EMAIL_ERROR);
            }
            // Normalize email
            const email = user.developerEmail.toLowerCase();
            this._type = USER_TYPE.TYPE_DEVELOPER_EMAIL;
            this.user = { developerEmail: email };
            this._serializedUser = `${USER_TYPE.TYPE_DEVELOPER_EMAIL}:${email}`;
            this._displayString = email;
            return;
        }
        throw new Error("Invalid user object.");
    }
    get serializedUser() {
        return this._serializedUser;
    }
    static fromSerializedUser(serializedUser) {
        const parts = serializedUser.split(":");
        if (parts.length !== 2) {
            throw new Error("Invalid serialized user.");
        }
        const type = parts[0];
        const value = parts[1];
        let user = {};
        if (type === USER_TYPE.TYPE_EMAIL) {
            user.email = value;
        }
        else if (type === USER_TYPE.TYPE_PHONE) {
            user.phone = value;
        }
        else if (type === USER_TYPE.TYPE_DEVELOPER_EMAIL) {
            user.developerEmail = value;
        }
        else {
            throw new Error("Invalid serialized user.");
        }
        return new ParsedUser(user);
    }
    get displayString() {
        return this._displayString;
    }
    get rawUser() {
        return this.user;
    }
    equals(other) {
        return this.serializedUser === other.serializedUser;
    }
    get type() {
        return this._type;
    }
}
exports.ParsedUser = ParsedUser;
