import { ModalBody, UncontrolledTooltip } from "reactstrap";

import {
  CHAIN_CONFIG,
  DISABLED,
  ENABLED,
  CRYPTOGRAPHY_ALGORITHMS_CONFIG,
  IDENTITY_PROTOCOL_CONFIG,
  APP_TYPE_CRYPTOGRAPHY,
  APP_TYPE_BLOCKCHAIN,
  APP_TYPE_IDENTITY,
  ENVIRONMENTS_MAP,
  APP_STATE_MAP,
} from "../../../../constants";
import { CustomInput, Switch } from "../../../../components";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import clx from "classnames";
import styles from "./index.module.scss";
import { AppTypeData, ValidationErrors } from "../../../../utils";

// TODO: Remove any types
type AppInfoContainerProps = {
  appId: string;
  errors: ValidationErrors;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  appName: string;
  state: APP_STATE_MAP;
  apiSignatureRequired: boolean;
  setDirectValue: (key: string, value: any) => void;
  appTypeData: AppTypeData;
};

export const AppInfoContainer = ({
  appId,
  appTypeData,
  errors,
  onChangeHandler,
  appName,
  state,
  apiSignatureRequired,
  setDirectValue,
}: AppInfoContainerProps) => {
  let config: {
    name?: string;
    env?: string;
  } = {};

  if (appTypeData.appType === APP_TYPE_BLOCKCHAIN) {
    config = CHAIN_CONFIG[appTypeData.blockchain.chainId];
  }
  if (appTypeData.appType === APP_TYPE_CRYPTOGRAPHY) {
    config = CRYPTOGRAPHY_ALGORITHMS_CONFIG[appTypeData.cryptography.algorithm];
    config.env =
      appTypeData.cryptography.environment === ENVIRONMENTS_MAP.PRODUCTION.value
        ? ENVIRONMENTS_MAP.PRODUCTION.name
        : ENVIRONMENTS_MAP.DEVELOPMENT.name;
  }
  if (appTypeData.appType === APP_TYPE_IDENTITY) {
    config = IDENTITY_PROTOCOL_CONFIG[appTypeData.identity.protocol];
  }
  const { name = "", env = "" } = config;

  const onAppStateToggle = (isOn: boolean) => {
    const value = isOn ? DISABLED : ENABLED;
    setDirectValue("state", value);
  };

  const onapiSignatureRequiredToggle = (isOn: boolean) => {
    setDirectValue("apiSignatureRequired", isOn);
  };

  const isAppStateToggleOn = state === DISABLED;
  const isapiSignatureRequiredToggleOn = apiSignatureRequired;

  return (
    <ModalBody>
      <CustomInput
        id="name"
        label="Application Name"
        placeholder="App Name"
        error={errors.name}
        onChangeHandler={onChangeHandler}
        value={appName}
        required
      />
      <CustomInput
        label="App Id"
        fullWidth={true}
        disabled={true}
        value={appId}
        id="appId"
        description="This is your app id, you can use it to initialize the SDK"
        withCopy={true}
      />

      {appTypeData.appType === APP_TYPE_BLOCKCHAIN && (
        <div>
          <CustomInput
            label="Environment"
            fullWidth={true}
            disabled={true}
            value={env}
            id="env"
          />
          <CustomInput
            label="Chain"
            fullWidth={true}
            disabled={true}
            value={name}
            id="chain"
          />
        </div>
      )}
      {appTypeData.appType === APP_TYPE_CRYPTOGRAPHY && (
        <div>
          <CustomInput
            label="Environment"
            fullWidth={true}
            disabled={true}
            value={env}
            id="env"
          />
          <CustomInput
            label="Cryptography Algorithm"
            fullWidth={true}
            disabled={true}
            value={name}
            id="cryptographyKey"
          />
        </div>
      )}
      {appTypeData.appType === APP_TYPE_IDENTITY && (
        <div>
          <CustomInput
            label="Identity Type"
            fullWidth={true}
            disabled={true}
            value={name}
            id="identityType"
          />
          <CustomInput
            label="Environment"
            fullWidth={true}
            disabled={true}
            value={env}
            id="env"
          />
        </div>
      )}
      <div
        className={clx(
          styles.info_toggle,
          "d-flex justify-content-between align-items-center"
        )}
      >
        <div style={{ alignItems: "center" }} className="label-wrapper">
          <label
            style={{
              fontSize: "14px",
              marginBottom: "0px",
            }}
          >
            API Signature Required
          </label>
          <AiOutlineQuestionCircle
            style={{ marginBottom: "0px" }}
            id={`tooltip-enforce-api-signature`}
          />
          <UncontrolledTooltip
            popperClassName="tooltip-popper"
            target={`tooltip-enforce-api-signature`}
            placement="top"
            autohide={false}
          >
            If enabled, the API will only accept requests with a valid API
            signature. Please take a look at the{" "}
            <a href="https://docs.metakeep.xyz/reference/api-signature">
              API Signature documentation
            </a>{" "}
            for more details.
          </UncontrolledTooltip>
        </div>
        <div>
          <Switch
            isOn={isapiSignatureRequiredToggleOn}
            onToggle={onapiSignatureRequiredToggle}
            role="apiSignatureRequiredToggle"
          />
        </div>
      </div>
      <div
        className={clx(
          styles.info_toggle,
          "d-flex justify-content-between align-items-center"
        )}
      >
        <div style={{ alignItems: "center" }} className="label-wrapper">
          <label
            style={{
              color: "red",
              fontSize: "14px",
              marginBottom: "0px",
            }}
          >
            Disable App
          </label>
          <AiOutlineQuestionCircle
            style={{ color: "red", marginBottom: "0px" }}
            id={`tooltip-disable-app`}
          />
          <UncontrolledTooltip
            popperClassName="tooltip-popper"
            target={`tooltip-disable-app`}
            placement="top"
            autohide={false}
          >
            Warning! Disabling an app will cause all backend API calls and
            frontend SDK operations on the app to fail. This may disrupt the
            functionality of your application.
          </UncontrolledTooltip>
        </div>
        <div>
          <Switch
            type="danger"
            isOn={isAppStateToggleOn}
            onToggle={onAppStateToggle}
          />
        </div>
      </div>
    </ModalBody>
  );
};
