import { useState } from "react";
import { Button, Collapse, UncontrolledTooltip } from "reactstrap";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsChevronUp } from "react-icons/bs";
import clx from "classnames";

import { PlanTag, Switch } from "../../../../../../components";
import { AllowedOriginsInput } from "../AllowedOriginsInput";
import { UserMessageTemplateSelect } from "../UserMessageTemplateSelect";
import { CTAButtonStyleSelect } from "../CTAButtonStyleSelect";
import styles from "./index.module.scss";
import { CustomCssTextArea } from "../CustomCssTextArea";
import { withFeature } from "../../../../../../hoc";
import {
  HIDE_WALLET_OPERATION_DETAILS_SECTION_FEATURE,
  HIDE_USER_PROFILE_SECTION_FEATURE,
  PHONE_AUTHENTICATION_FEATURE,
  HIDE_PROCESSING_LOADER_FEATURE,
} from "../../../../../../constants";
import { FontFamilySelect } from "../FontFamilySelect";
import { FontScalingFactorInput } from "../FontScalingFactorInput";
import {
  AUTH_PROVIDER_STATE,
  AuthProviders,
  CTA_BUTTON_STYLE,
  PROCESSING_LOADER_STATE,
  USER_MESSAGE_TEMPLATE,
} from "../../../../../../utils";

const DESCRIPTION_MAP = {
  SKIP_FINAL_SCREEN:
    "Wallet shows a success or failure dialog after every interaction with the user wallet. Turn on the toggle to skip the dialog.",
  HIDE_DETAILS_SECTION:
    "Toggle this switch to hide wallet operation details from the UI. This can be useful if you find the details to be distracting for the user.",
  HIDE_USER_SECTION:
    "Toggle this switch to hide the user profile information from the UI. Note that if you did not provide a user when initializing the SDK, this toggle will have no effect.",
  ENABLE_PHONE_AUTHENTICATION:
    "Toggle this switch to allow users to log in using their phone number. This will provide an additional login option alongside the existing email login.",
  PROCESSING_LOADER:
    "The users are shown a processing loader for a few seconds before the request is processed allowing them to undo the action if needed. This is enabled by default for all mutating actions(e.g. NFT transfer, lambda invocation, or any action that will create and broadcast a transaction). Note that message and transaction signing APIs are excluded from this behavior. You can disable the processing loader by turning off the toggle.",
};

interface AdvancedSettingsProps {
  skipFinalScreen: boolean;
  onSkipFinalScreenChange: (isOn: boolean) => void;

  whitelistedOrigins: string[];
  setWhitelistedOrigins: (origins: string[]) => void;

  userMessageTemplate: string;
  onUserMessageTemplateSelect: (value: USER_MESSAGE_TEMPLATE) => void;

  customCssValue: string;
  onCustomCssChange: (value: string) => void;

  hideUserProfileSection: boolean;
  onHideUserSectionChange: (isOn: boolean) => void;

  hideWalletOperationDetailsSection: boolean;
  onHideDetailsChange: (isOn: boolean) => void;

  ctaButtonStyle: string;
  onCTAButtonStyleSelect: (value: CTA_BUTTON_STYLE) => void;

  fontName: string;
  setFontName: (value: string) => void;

  fontSizeScaling: string;
  setFontSizeScaling: (value: string) => void;

  authProviders: AuthProviders;
  onUserPhoneAuthenticationChange: (isOn: boolean) => void;

  processingLoader: PROCESSING_LOADER_STATE;
  onProcessingLoaderToggle: (isOn: boolean) => void;
}

// TODO: Rename/refactor this since this has multiple Collapsible sections
export const AdvancedSettings = ({
  skipFinalScreen,
  onSkipFinalScreenChange,
  whitelistedOrigins,
  setWhitelistedOrigins,
  onUserMessageTemplateSelect,
  userMessageTemplate,
  customCssValue,
  processingLoader,
  onCustomCssChange,
  hideUserProfileSection,
  hideWalletOperationDetailsSection,
  onHideDetailsChange,
  onHideUserSectionChange,
  ctaButtonStyle,
  onCTAButtonStyleSelect,
  fontName,
  setFontName,
  fontSizeScaling,
  setFontSizeScaling,
  authProviders,
  onUserPhoneAuthenticationChange,
  onProcessingLoaderToggle,
}: AdvancedSettingsProps) => {
  // For Authentication Settings Collapsible
  const [isOpenAuthSettings, toggleAuthSettings] = useState(false);

  // For Advanced Settings Collapsible
  const [isOpenAdvncSettings, toggleAdvncSettings] = useState(false);

  return (
    <>
      {/* Auth Settings Container */}
      <div className={styles.advanced_settings_container}>
        <Button
          onClick={() => toggleAuthSettings((prevState) => !prevState)}
          className={clx(
            styles.advanced_sttings_toggle,
            !isOpenAuthSettings && styles.closed
          )}
        >
          <span>Authentication Settings</span>
          <BsChevronUp className={styles.chev_icon} />
        </Button>
        <Collapse isOpen={isOpenAuthSettings}>
          <div className={styles.advanced_setting_wrapper}>
            <div className={styles.settings_item_wrapper}>
              <PhoneAuthenticationSwitch
                isOn={
                  authProviders?.phone?.state === AUTH_PROVIDER_STATE.ENABLED
                }
                onToggle={onUserPhoneAuthenticationChange}
                label="Enable Phone Number Login"
                id="enable-phone-authentication"
                description={DESCRIPTION_MAP.ENABLE_PHONE_AUTHENTICATION}
              />
            </div>
          </div>
        </Collapse>
      </div>

      {/* Advanced Settings Container */}
      <div className={styles.advanced_settings_container}>
        <Button
          onClick={() => toggleAdvncSettings((prevState) => !prevState)}
          className={clx(
            styles.advanced_sttings_toggle,
            !isOpenAdvncSettings && styles.closed
          )}
        >
          <span>Advanced Settings</span>
          <BsChevronUp className={styles.chev_icon} />
        </Button>
        <Collapse isOpen={isOpenAdvncSettings}>
          <div className={styles.advanced_setting_wrapper}>
            <div
              className={clx(
                styles.settings_item_wrapper,
                styles.whitelist_input_wrapper
              )}
            >
              <AllowedOriginsInput
                selectedOrigins={whitelistedOrigins}
                setWhitelistedOrigins={setWhitelistedOrigins}
              />
            </div>
            <div className={styles.settings_item_wrapper}>
              <UserMessageTemplateSelect
                value={userMessageTemplate}
                onClick={onUserMessageTemplateSelect}
              />
            </div>
            <div className={styles.settings_item_wrapper}>
              <CTAButtonStyleSelect
                value={ctaButtonStyle}
                onClick={onCTAButtonStyleSelect}
              />
            </div>
            <div className={styles.settings_item_wrapper}>
              <FontFamilySelect value={fontName} onClick={setFontName} />
            </div>
            <div className={styles.settings_item_wrapper}>
              <FontScalingFactorInput
                value={fontSizeScaling}
                onChange={setFontSizeScaling}
              />
            </div>
            <div className={styles.settings_item_wrapper}>
              <CustomCssTextArea
                onChange={onCustomCssChange}
                value={customCssValue}
              />
            </div>
            <div className={styles.settings_item_wrapper}>
              <SettingsSwitch
                isOn={skipFinalScreen}
                onToggle={onSkipFinalScreenChange}
                label="Skip Final Dialog"
                id="skip-screen"
                description={DESCRIPTION_MAP.SKIP_FINAL_SCREEN}
                isDisabled={false}
              />
            </div>
            <div className={styles.settings_item_wrapper}>
              <HideDetailsSectionSwitch
                isOn={hideWalletOperationDetailsSection}
                onToggle={onHideDetailsChange}
                label="Hide Wallet Operation Details"
                id="hide-details-section"
                description={DESCRIPTION_MAP.HIDE_DETAILS_SECTION}
              />
            </div>
            <div className={styles.settings_item_wrapper}>
              <HideUserSectionSwitch
                isOn={hideUserProfileSection}
                onToggle={onHideUserSectionChange}
                label="Hide User Profile Details"
                id="hide-user-section"
                description={DESCRIPTION_MAP.HIDE_USER_SECTION}
              />
            </div>
            <div className={styles.settings_item_wrapper}>
              <HideProcessingLoaderSwitch
                isOn={processingLoader === "ENABLED"}
                onToggle={onProcessingLoaderToggle}
                label="Show processing loader"
                id="show-processing-loader"
                description={DESCRIPTION_MAP.PROCESSING_LOADER}
                isDisabled={false}
                isPaidFeature
              />
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

interface SettingsSwitchProps {
  isOn: boolean;
  onToggle: (isOn: boolean) => void;
  label: string;
  description: string;
  id: string;
  isDisabled: boolean;
  isPaidFeature?: boolean;
}

const SettingsSwitch = ({
  isOn,
  onToggle,
  label,
  description,
  id,
  isDisabled,
  isPaidFeature = false,
}: SettingsSwitchProps) => {
  return (
    <div
      className={clx(
        styles.switch_settings_item,
        "d-flex justify-content-between align-items-center"
      )}
    >
      <div style={{ alignItems: "center" }} className={clx("label-wrapper")}>
        <label
          style={{
            fontSize: "14px",
            marginBottom: "0px",
          }}
        >
          {label}
        </label>

        {description ? (
          <>
            <AiOutlineQuestionCircle style={{ marginBottom: "0px" }} id={id} />
            <UncontrolledTooltip
              popperClassName="tooltip-popper"
              target={id}
              placement="top"
              autohide={false}
            >
              {description}
            </UncontrolledTooltip>
          </>
        ) : null}
        {isPaidFeature && <PlanTag locked={isDisabled} id={id} />}
      </div>

      <div>
        <Switch
          isOn={isOn}
          onToggle={onToggle}
          isDisabled={isDisabled}
          id={id}
        />
      </div>
    </div>
  );
};

const HideDetailsSectionSwitch = withFeature(
  ({
    isOn,
    onToggle,
    label,
    description,
    id,
    isDisabled,
  }: SettingsSwitchProps) => (
    <SettingsSwitch
      isOn={isOn}
      onToggle={onToggle}
      label={label}
      description={description}
      id={id}
      isDisabled={isDisabled}
      isPaidFeature
    />
  ),
  HIDE_WALLET_OPERATION_DETAILS_SECTION_FEATURE
);

const HideUserSectionSwitch = withFeature(
  ({
    isOn,
    onToggle,
    label,
    description,
    id,
    isDisabled,
  }: SettingsSwitchProps) => (
    <SettingsSwitch
      isOn={isOn}
      onToggle={onToggle}
      label={label}
      description={description}
      id={id}
      isDisabled={isDisabled}
      isPaidFeature
    />
  ),
  HIDE_USER_PROFILE_SECTION_FEATURE
);

const PhoneAuthenticationSwitch = withFeature(
  ({
    isOn,
    onToggle,
    label,
    description,
    id,
    isDisabled,
  }: SettingsSwitchProps) => (
    <SettingsSwitch
      isOn={isOn}
      onToggle={onToggle}
      label={label}
      description={description}
      id={id}
      isDisabled={isDisabled}
      isPaidFeature
    />
  ),
  PHONE_AUTHENTICATION_FEATURE
);

const HideProcessingLoaderSwitch = withFeature(
  ({
    isOn,
    onToggle,
    label,
    description,
    id,
    isDisabled,
  }: SettingsSwitchProps) => (
    <SettingsSwitch
      isOn={isOn}
      onToggle={onToggle}
      label={label}
      description={description}
      id={id}
      isDisabled={isDisabled}
      isPaidFeature
    />
  ),
  HIDE_PROCESSING_LOADER_FEATURE
);
