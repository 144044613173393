import { useCallback, useState } from "react";
import clx from "classnames";
import { Badge, Button } from "reactstrap";
import { SettingsHeader } from "../Common/SettingsHeader";
import { QRCodeModal } from "../../../../Home/Auth/QRCodeModal";
import { CodeModal } from "../../../../Home/Auth/CodeModal";
import styles from "./index.module.scss";
import baseStyles from "../../index.module.scss";
import {
  generateTOTPCodeSrc,
  verifyTOTPCode,
  signOut,
} from "../../../../../utils/authUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UPDATE_MFA, UPDATE_MFA_CODE } from "../../../../../constants";

export const TwoFactorAuthSetting = () => {
  const navigate = useNavigate();
  const [showCodeModal, toggleCodeModal] = useState(false);
  const [showQRModal, toggleQRModal] = useState(false);
  const [codeSrc, setCodeSrc] = useState(null);
  const [existingCodeTries, setExistingCodeTries] = useState(1);
  const [newCodeTries, setNewCodeTries] = useState(1);

  const setupMFA = useCallback(async () => {
    const codeSrc = await generateTOTPCodeSrc();
    setCodeSrc(codeSrc);
  }, []);

  const onVerifyNewAuthenticatorCode = useCallback(async (otp) => {
    if (!(await verifyTOTPCode(otp))) return;
    onQrModalClose();
    toast.success("Your 2-factor authentication has been updated");
  }, []);

  const onInvalidNewCodeInput = () => {
    if (newCodeTries < 3) {
      setNewCodeTries((prev) => prev + 1);
    } else {
      onQrModalClose();
      toast.error(
        "You have entered an invalid code too many times. Please try again"
      );
    }
  };

  const onQrModalClose = () => {
    toggleQRModal(false);
    setCodeSrc(null);
    setNewCodeTries(1);
  };
  const onCodeModalClose = () => {
    toggleCodeModal(false);
    setExistingCodeTries(1);
  };

  const onVerifyExistingAuthenticatorCode = useCallback(async (otp) => {
    await verifyTOTPCode(otp);
    onCodeModalClose();
    toggleQRModal(true);
  }, []);

  const onInvalidExistingCodeInput = async () => {
    if (existingCodeTries < 3) {
      setExistingCodeTries((prev) => prev + 1);
    } else {
      toggleCodeModal(false);
      await signOut();
      navigate("/");
      toast.error(
        "You have entered an invalid code too many times. Please login again"
      );
    }
  };

  return (
    <div className={baseStyles.settings_item}>
      <SettingsHeader name="2-Factor Authentication" />
      <div
        className={clx(
          baseStyles.settings_cotainer,
          styles.two_factor_auth_container
        )}
      >
        <div className={styles.two_factor_auth_setting_wrapper}>
          <div className={baseStyles.setting_item_info}>
            Two-factor authentication (2FA) adds an extra layer of security to
            your account by requiring you to enter a code from your
            authenticator app in addition to your password when you sign in.
          </div>
          <div className={styles.authenticator_app_container}>
            <div className={styles.authenticator_app_title}>
              Authenticator App
              <Badge className={styles.configured_badge}>Enabled</Badge>
            </div>
            <div className={styles.two_factor_setting_cta}>
              <Button
                onClick={() => toggleCodeModal(true)}
                className={clx("primary-btn lg")}
              >
                Update 2FA
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showCodeModal && (
        <CodeModal
          modalType={UPDATE_MFA_CODE}
          isOpen={showCodeModal}
          onSubmit={onVerifyExistingAuthenticatorCode}
          onClose={onCodeModalClose}
          onInvalidCodeCallback={onInvalidExistingCodeInput}
        />
      )}
      {showQRModal && (
        <QRCodeModal
          isOpen={showQRModal}
          codeSrc={codeSrc}
          setUpMFA={setupMFA}
          onSubmit={onVerifyNewAuthenticatorCode}
          modalType={UPDATE_MFA}
          onInvalidCodeCallback={onInvalidNewCodeInput}
          onClose={onQrModalClose}
        />
      )}
    </div>
  );
};
