import { ModalBody } from "reactstrap";
import { LambdaTransactionSpeedSelect } from "./components/LambdaTransactionSpeedSelect";

export const LambdaContainer = ({ transactionSpeed, setDirectValue }) => {
  const onTransactionSpeedChange = (e) => {
    const transactionSpeed = e.target.value;
    setDirectValue("transactionSpeed", transactionSpeed);
  };

  return (
    <ModalBody>
      <LambdaTransactionSpeedSelect
        onChange={onTransactionSpeedChange}
        activeSelection={transactionSpeed}
      />
    </ModalBody>
  );
};
