import clx from "classnames";
import styles from "./index.module.scss";

interface SwitchProps {
  isOn: boolean;
  onToggle?: (isOn: boolean) => void;
  type?: string;
  role?: string;
  id?: string;
  isDisabled?: boolean;
}

export const Switch = ({
  isOn = false,
  onToggle,
  type = "",
  role = "",
  id = "",
  isDisabled = false,
}: SwitchProps) => {
  const onToggleClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (onToggle && typeof onToggle === "function" && !isDisabled)
      onToggle(!isOn);
  };

  return (
    <div
      data-testid={id}
      onClick={onToggleClickHandler}
      className={clx(styles.switch, isDisabled && "disabled")}
      role={role}
    >
      <span
        className={clx(styles.slider, isOn && styles.on, type && styles[type])}
      />
    </div>
  );
};
