import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { QRCodeSVG } from "qrcode.react";

import { OTPField, Loader } from "../../../../components";
import { useForm } from "../../../../hooks";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { SETUP_MFA, UPDATE_MFA } from "../../../../constants";
import { AiOutlineClose } from "react-icons/ai";

const STORE_URL_MAP = {
  GOOGLE:
    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
  APPLE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
};

const MODAL_VALUES = {
  [SETUP_MFA]: {
    title: "Setup 2FA",
  },
  [UPDATE_MFA]: {
    title: "Update 2FA",
  },
};

export const QRCodeModal = ({
  isOpen,
  codeSrc,
  onSubmit,
  setUpMFA,
  modalType = SETUP_MFA,
  onInvalidCodeCallback = null,
  onClose = () => {},
}) => {
  const { setDirectValue, values, checkFormValidity } = useForm({
    OTP: null,
  });
  const [authErr, setAuthErr] = useState(null);
  // In case the user is updating their 2FA app, show the QR code screen directly
  const [showQR, toggleQR] = useState(modalType === UPDATE_MFA);
  const [inProgress, setProgress] = useState(false);
  const { OTP } = values;

  const initMFA = useCallback(async () => {
    if (!showQR) return;
    setProgress(true);
    if (typeof setUpMFA === "function") await setUpMFA();
    setProgress(false);
  }, [setUpMFA, showQR]);

  useEffect(() => {
    initMFA();
  }, [initMFA]);

  const onVerify = useCallback(async () => {
    const isValid = checkFormValidity();
    if (!isValid) return;
    try {
      setProgress(true);
      await onSubmit(OTP);
    } catch (err) {
      if (err.message === "Invalid session for the user, session is expired.") {
        setDirectValue("OTP", null);
        setAuthErr(
          "The QR code has expired. Please re-scan the QR code and try again"
        );
        await setUpMFA();
      } else setAuthErr(err.message);
      if (typeof onInvalidCodeCallback === "function") onInvalidCodeCallback();
      console.log("err", err.message);
    } finally {
      setProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OTP, checkFormValidity, onSubmit, setDirectValue, setUpMFA]);

  useEffect(() => {
    if (OTP && OTP.length === 6) onVerify();
  }, [OTP, onVerify]);

  const handleOtpChange = useCallback(
    (val) => {
      setDirectValue("OTP", val);
    },
    [setDirectValue]
  );

  return (
    <Modal centered className="auth-modal" isOpen={isOpen}>
      <Title title={MODAL_VALUES[modalType].title} onClose={onClose} />
      {!showQR ? (
        <InstallAuthenticator onNext={() => toggleQR(true)} />
      ) : (
        <>
          <div className="qr-code-container">
            <div className="qr-code-copy">
              Scan the code using the Google authenticator app on your phone and
              enter the code below
            </div>
            {codeSrc && (
              <QRCodeSVG data-testid="qr-code" size={200} value={codeSrc} />
            )}
          </div>
          {authErr && <span className="auth-err-msg">{authErr}</span>}
          <OTPField
            resetOn={codeSrc}
            onChangeHandler={handleOtpChange}
            hasError={authErr}
          />
        </>
      )}
      <div className="auth-modal-footer">
        Secured By <b>MetaKeep</b>
      </div>
      {inProgress && <Loader />}
    </Modal>
  );
};

const InstallAuthenticator = ({ onNext }) => {
  const onButtonClick = (linkTo) => {
    window.open(linkTo, "_blank");
  };
  return (
    <>
      <div className="auth-copy large">
        Install the Google authenticator app. Tap next once you are ready.
      </div>
      <div className="auth-modal-cta">
        <Button
          onClick={() => onButtonClick(STORE_URL_MAP.GOOGLE)}
          className="primary-btn clr-black"
        >
          <div className="button-content">
            <div className="button-icon">
              <FaGooglePlay />
            </div>
            <div className="button-text">
              <span>GET IT ON</span> Google Play
            </div>
          </div>
        </Button>
        <Button
          onClick={() => {
            onButtonClick(STORE_URL_MAP.APPLE);
          }}
          className="primary-btn clr-black"
        >
          <div className="button-content">
            <div className="button-icon apple">
              <FaApple />
            </div>
            <div className="button-text">
              <span>Download on the</span> App Store
            </div>
          </div>
        </Button>
      </div>
      <div className="next-link">
        <Button onClick={onNext} className="primary-btn link">
          Next
        </Button>
      </div>
    </>
  );
};

const Title = ({ title, onClose }) => {
  const canClose = typeof onClose === "function";
  return (
    <div data-testid={title} className="auth-modal-tile-container">
      <div className="auth-modal-title">{title}</div>
      {canClose && (
        <AiOutlineClose
          data-testid="close-btn"
          onClick={onClose}
          className="auth-modal-close-btn"
        />
      )}
    </div>
  );
};
