import React, { useState } from "react";
import clx from "classnames";
import styles from "./index.module.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import {
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { DARK_THEME, LIGHT_THEME } from "../../../../../../constants";

export const AppThemeSelect = ({ activeSelection, onChange }) => {
  const [isActive, setActive] = useState(false);

  const onToggle = () => {
    setActive(!isActive);
  };

  return (
    <div className={clx("input-wrapper")}>
      <div className={clx("label-wrapper")}>
        <label htmlFor="theme">Theme</label>
        <AiOutlineQuestionCircle id={`tooltip-app-theme`} />
        <UncontrolledTooltip
          popperClassName="tooltip-popper"
          target={`tooltip-app-theme`}
          placement="top"
          autohide={false}
        >
          <div className={styles.theme_tooltip_info}>
            Choose a theme for your user's wallet
          </div>
          <div className={styles.theme_tooltip_title}>Light:</div>
          <div className={styles.them_tooltip_desc}>
            The wallet will use a light color scheme with white background and
            dark text.
          </div>
          <div className={styles.theme_tooltip_title}>Dark:</div>
          <div className={styles.them_tooltip_desc}>
            The wallet will use a dark color scheme with a dark background and
            light text.
          </div>
        </UncontrolledTooltip>
      </div>
      <Dropdown isOpen={isActive} toggle={onToggle}>
        <DropdownToggle
          caret
          className="w-100 d-flex justify-content-between align-items-center"
          color=""
        >
          {" "}
          {activeSelection}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={onChange} value={LIGHT_THEME}>
            Light
          </DropdownItem>
          <DropdownItem onClick={onChange} value={DARK_THEME}>
            Dark
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
