import {
  BsPencil,
  BsEye,
  BsWallet2,
  BsKey,
  BsFileText,
  BsGraphUp,
  BsFileRuled,
} from "react-icons/bs";
import { TbLambda } from "react-icons/tb";
export const APP_REGION = process.env.REACT_APP_AWS_REGION; //AWS APP REGION
export const POOL_ID = process.env.REACT_APP_COGNITO_POOL_ID; // AWS COGNITO POOL ID
export const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID; // AWS COGNITO CLIENT ID
export const SDK_ENV = process.env.REACT_APP_SDK_ENV; //METAKEEP SDK ENVIRONMENT
export const ENABLE_ACCOUNT_SHARING = Boolean(
  process.env.REACT_APP_ENABLE_ACCOUNT_SHARING
); //ENABLE ACCOUNT SHARING

// FEATURES

export const CUSTOM_USER_MESSAGE_TEMPLATE_FEATURE =
  "CUSTOM_USER_MESSAGE_TEMPLATE";
export const CUSTOM_CSS_FEATURE = "CUSTOM_CSS";

export const HIDE_USER_PROFILE_SECTION_FEATURE = "HIDE_USER_PROFILE_SECTION";

export const PHONE_AUTHENTICATION_FEATURE = "PHONE_AUTH_PROVIDER";

export const HIDE_PROCESSING_LOADER_FEATURE = "HIDE_PROCESSING_LOADER";

export const HIDE_WALLET_OPERATION_DETAILS_SECTION_FEATURE =
  "HIDE_WALLET_OPERATION_DETAILS_SECTION";

export const CTA_BUTTON_STYLE_FEATURE = "CTA_BUTTON_STYLE";
export const FONT_FAMILY_FEATURE = "CUSTOM_FONT_FAMILY";
export const CUSTOM_FONT_SIZE_FEATURE = "CUSTOM_FONT_SIZE";

export const ETHEREUM_APP_CREATION_FEATURE = "ETHEREUM_APP_CREATION";
export const BASE_APP_CREATION_FEATURE = "BASE_APP_CREATION";
export const PRODUCTION_APP_CREATION_FEATURE = "PRODUCTION_APP_CREATION";

// END FEATURES

export type NETWORK_TYPES =
  // Polygon
  | "POLYGON"
  // Binance
  | "BINANCE"
  // Solana
  | "SOLANA"
  // EOS
  | "EOS"
  // Avalanche
  | "AVALANCHE"
  // Arbitrum
  | "ARBITRUM"
  // SKALE Europa DeFi Hub
  | "SKALE_EUROPA_DEFI_HUB"
  // SKALE Titan AI Hub
  | "SKALE_TITAN_AI_HUB"
  // SKALE Calypso Hub
  | "SKALE_CALYPSO_HUB"
  // SKALE Nebula Gaming Hub
  | "SKALE_NEBULA_GAMING_HUB"
  // Ethereum
  | "ETHEREUM"
  // BASE
  | "BASE";

export type CHAIN_ID_TYPES =
  // Polygon
  | "CHAIN_ID_POLYGON_AMOY"
  | "CHAIN_ID_POLYGON_MUMBAI"
  | "CHAIN_ID_POLYGON_MAINNET"
  // Binance
  | "CHAIN_ID_BINANCE_TESTNET"
  | "CHAIN_ID_BINANCE_MAINNET"
  // Solana
  | "CHAIN_ID_SOLANA_TESTNET"
  | "CHAIN_ID_SOLANA_MAINNET"
  // EOS
  | "CHAIN_ID_EOS_TESTNET"
  | "CHAIN_ID_EOS_MAINNET"
  // Avalanche
  | "CHAIN_ID_AVALANCHE_TESTNET"
  | "CHAIN_ID_AVALANCHE_MAINNET"
  // Arbitrum
  | "CHAIN_ID_ARBITRUM_NOVA"
  // SKALE Europa DeFi Hub
  | "CHAIN_ID_SKALE_EUROPA_DEFI_HUB_TESTNET"
  | "CHAIN_ID_SKALE_EUROPA_DEFI_HUB_MAINNET"
  // SKALE Titan AI Hub
  | "CHAIN_ID_SKALE_TITAN_AI_HUB_TESTNET"
  | "CHAIN_ID_SKALE_TITAN_AI_HUB_MAINNET"
  // SKALE Calypso Hub
  | "CHAIN_ID_SKALE_CALYPSO_HUB_TESTNET"
  | "CHAIN_ID_SKALE_CALYPSO_HUB_MAINNET"
  // SKALE Nebula Gaming Hub
  | "CHAIN_ID_SKALE_NEBULA_GAMING_HUB_TESTNET"
  | "CHAIN_ID_SKALE_NEBULA_GAMING_HUB_MAINNET"
  // Ethereum
  | "CHAIN_ID_ETHEREUM_MAINNET"
  | "CHAIN_ID_ETHEREUM_SEPOLIA"
  // BASE
  | "CHAIN_ID_BASE_MAINNET"
  | "CHAIN_ID_BASE_SEPOLIA";

export type ENVIRONMENT_TYPES = "DEVELOPMENT" | "PRODUCTION";
type ENVIRONMENT_TYPES_ARBITRUM = "PRODUCTION_NOVA";

type CHAIN_INFO = {
  DISPLAY_NAME: string;
} & Partial<
  Record<ENVIRONMENT_TYPES | ENVIRONMENT_TYPES_ARBITRUM, CHAIN_ID_TYPES>
>;

export const CHAIN_ID_FOR_NETWORK: Record<NETWORK_TYPES, CHAIN_INFO> = {
  POLYGON: {
    DEVELOPMENT: "CHAIN_ID_POLYGON_AMOY",
    PRODUCTION: "CHAIN_ID_POLYGON_MAINNET",
    DISPLAY_NAME: "Polygon",
  },
  BINANCE: {
    DEVELOPMENT: "CHAIN_ID_BINANCE_TESTNET",
    PRODUCTION: "CHAIN_ID_BINANCE_MAINNET",
    DISPLAY_NAME: "Binance",
  },
  SOLANA: {
    DEVELOPMENT: "CHAIN_ID_SOLANA_TESTNET",
    PRODUCTION: "CHAIN_ID_SOLANA_MAINNET",
    DISPLAY_NAME: "Solana",
  },
  EOS: {
    DEVELOPMENT: "CHAIN_ID_EOS_TESTNET",
    PRODUCTION: "CHAIN_ID_EOS_MAINNET",
    DISPLAY_NAME: "EOS",
  },
  AVALANCHE: {
    DEVELOPMENT: "CHAIN_ID_AVALANCHE_TESTNET",
    PRODUCTION: "CHAIN_ID_AVALANCHE_MAINNET",
    DISPLAY_NAME: "Avalanche",
  },
  ARBITRUM: {
    PRODUCTION_NOVA: "CHAIN_ID_ARBITRUM_NOVA",
    DISPLAY_NAME: "Arbitrum",
  },
  SKALE_EUROPA_DEFI_HUB: {
    DEVELOPMENT: "CHAIN_ID_SKALE_EUROPA_DEFI_HUB_TESTNET",
    PRODUCTION: "CHAIN_ID_SKALE_EUROPA_DEFI_HUB_MAINNET",
    DISPLAY_NAME: "SKALE Europa Defi Hub",
  },
  SKALE_TITAN_AI_HUB: {
    DEVELOPMENT: "CHAIN_ID_SKALE_TITAN_AI_HUB_TESTNET",
    PRODUCTION: "CHAIN_ID_SKALE_TITAN_AI_HUB_MAINNET",
    DISPLAY_NAME: "SKALE Titan AI Hub",
  },
  SKALE_CALYPSO_HUB: {
    DEVELOPMENT: "CHAIN_ID_SKALE_CALYPSO_HUB_TESTNET",
    PRODUCTION: "CHAIN_ID_SKALE_CALYPSO_HUB_MAINNET",
    DISPLAY_NAME: "SKALE Calypso Hub",
  },
  SKALE_NEBULA_GAMING_HUB: {
    DEVELOPMENT: "CHAIN_ID_SKALE_NEBULA_GAMING_HUB_TESTNET",
    PRODUCTION: "CHAIN_ID_SKALE_NEBULA_GAMING_HUB_MAINNET",
    DISPLAY_NAME: "SKALE Nebula Gaming Hub",
  },
  ETHEREUM: {
    DEVELOPMENT: "CHAIN_ID_ETHEREUM_SEPOLIA",
    PRODUCTION: "CHAIN_ID_ETHEREUM_MAINNET",
    DISPLAY_NAME: "Ethereum",
  },
  BASE: {
    DEVELOPMENT: "CHAIN_ID_BASE_SEPOLIA",
    PRODUCTION: "CHAIN_ID_BASE_MAINNET",
    DISPLAY_NAME: "Base",
  },
};

export type CHAIN_ITEM = {
  value: NETWORK_TYPES | "SKALE";
  displayName: string;
  items?: { value: NETWORK_TYPES; displayName: string }[];
  featureWhitelist?:
    | typeof ETHEREUM_APP_CREATION_FEATURE
    | typeof BASE_APP_CREATION_FEATURE;
};

export type SUPPORTED_CHAIN_KEYS = "Popular chains" | "Other chains";

type SUPPORTED_CHAINS_TYPE = {
  "Popular chains": CHAIN_ITEM[];
  "Other chains": CHAIN_ITEM[];
};

export const SUPPORTED_CHAINS: SUPPORTED_CHAINS_TYPE = {
  "Popular chains": [
    { value: "POLYGON", displayName: "Polygon" },
    { value: "SOLANA", displayName: "Solana" },
    {
      value: "ETHEREUM",
      displayName: "Ethereum",
      featureWhitelist: ETHEREUM_APP_CREATION_FEATURE,
    },
    {
      value: "SKALE",
      displayName: "SKALE",
      items: [
        {
          value: "SKALE_EUROPA_DEFI_HUB",
          displayName: "Europa DeFi Hub",
        },
        { value: "SKALE_TITAN_AI_HUB", displayName: "Titan AI Hub" },
        { value: "SKALE_CALYPSO_HUB", displayName: "Calypso Hub" },
        {
          value: "SKALE_NEBULA_GAMING_HUB",
          displayName: "Nebula Gaming Hub",
        },
      ],
    },
  ],
  "Other chains": [
    { value: "AVALANCHE", displayName: "Avalanche" },
    {
      value: "BASE",
      displayName: "Base",
      featureWhitelist: BASE_APP_CREATION_FEATURE,
    },
    { value: "EOS", displayName: "EOS/FIO" },
    { value: "ARBITRUM", displayName: "Arbitrum" },
    { value: "BINANCE", displayName: "Binance" },
  ],
};

export type IDENTITY_TYPES = "POLYGON_ID";

export const IDENTITY_MAP: Record<
  IDENTITY_TYPES,
  { value: IDENTITY_TYPES; name: string }
> = {
  POLYGON_ID: {
    value: "POLYGON_ID",
    name: "Polygon ID",
  },
};

export type ENVIRONMENT_ITEM = {
  value: ENVIRONMENT_TYPES;
  name: string;
  color: string;
  featureWhitelist?: typeof PRODUCTION_APP_CREATION_FEATURE;
};

export const ENVIRONMENTS_MAP: Record<ENVIRONMENT_TYPES, ENVIRONMENT_ITEM> = {
  DEVELOPMENT: {
    value: "DEVELOPMENT",
    name: "Development",
    color: "secondary",
  },
  PRODUCTION: {
    value: "PRODUCTION",
    name: "Production",
    color: "success",
    featureWhitelist: PRODUCTION_APP_CREATION_FEATURE,
  },
};

export const ARBITRUM_ENVIRONMENTS_MAP: Record<
  "PRODUCTION_NOVA",
  {
    value: ENVIRONMENT_TYPES_ARBITRUM;
    name: string;
    featureWhitelist?: typeof PRODUCTION_APP_CREATION_FEATURE;
  }
> = {
  PRODUCTION_NOVA: {
    value: "PRODUCTION_NOVA",
    name: "Production (Nova)",
    featureWhitelist: PRODUCTION_APP_CREATION_FEATURE,
  },
};

export type CRYPTOGRAPHY_ALGORITHMS_TYPES =
  | "CRYPTOGRAPHY_ALGORITHM_BABY_JUBJUB_POLYGON_ID"
  | "CRYPTOGRAPHY_ALGORITHM_ENCRYPTION";

export const CRYPTOGRAPHY_ALGORITHMS_CONFIG: Record<
  CRYPTOGRAPHY_ALGORITHMS_TYPES,
  { value: CRYPTOGRAPHY_ALGORITHMS_TYPES; name: string; image: string }
> = {
  CRYPTOGRAPHY_ALGORITHM_BABY_JUBJUB_POLYGON_ID: {
    value: "CRYPTOGRAPHY_ALGORITHM_BABY_JUBJUB_POLYGON_ID",
    name: "BabyJubJub Polygon ID",
    image: "/images/icons/encryption-icon.svg",
  },
  CRYPTOGRAPHY_ALGORITHM_ENCRYPTION: {
    value: "CRYPTOGRAPHY_ALGORITHM_ENCRYPTION",
    name: "Encryption",
    image: "/images/icons/data-encryption.svg",
  },
};

export type IDENTITY_PROTOCOL_TYPES =
  | "IDENTITY_PROTOCOL_POLYGON_ID_CHAIN_ID_POLYGON_AMOY"
  | "IDENTITY_PROTOCOL_POLYGON_ID_CHAIN_ID_POLYGON_MAINNET";

export const IDENTITY_FOR_NETWORK: Record<
  IDENTITY_TYPES,
  Record<ENVIRONMENT_TYPES, IDENTITY_PROTOCOL_TYPES> & { DISPLAY_NAME: string }
> = {
  POLYGON_ID: {
    DEVELOPMENT: "IDENTITY_PROTOCOL_POLYGON_ID_CHAIN_ID_POLYGON_AMOY",
    PRODUCTION: "IDENTITY_PROTOCOL_POLYGON_ID_CHAIN_ID_POLYGON_MAINNET",
    DISPLAY_NAME: "Polygon Identity",
  },
};

export const IDENTITY_PROTOCOL_CONFIG: Record<
  IDENTITY_PROTOCOL_TYPES,
  { name: string; env: string; color: string; image: string }
> = {
  IDENTITY_PROTOCOL_POLYGON_ID_CHAIN_ID_POLYGON_AMOY: {
    name: "Polygon ID (Mumbai)",
    env: "Development",
    color: "secondary",
    image: "/images/icons/identity.svg",
  },
  IDENTITY_PROTOCOL_POLYGON_ID_CHAIN_ID_POLYGON_MAINNET: {
    name: "Polygon ID",
    env: "Production",
    color: "success",
    image: "/images/icons/identity.svg",
  },
};

export const APP_TYPE_BLOCKCHAIN = "blockchain";
export const APP_TYPE_CRYPTOGRAPHY = "cryptography";
export const APP_TYPE_IDENTITY = "identity";

export type APP_TYPES =
  | typeof APP_TYPE_BLOCKCHAIN
  | typeof APP_TYPE_CRYPTOGRAPHY
  | typeof APP_TYPE_IDENTITY;

export const APP_TYPES_MAP: Record<
  "BLOCKCHAIN" | "CRYPTOGRAPHY" | "IDENTITY",
  { value: APP_TYPES; name: string }
> = {
  BLOCKCHAIN: {
    value: APP_TYPE_BLOCKCHAIN,
    name: "Blockchain",
  },
  CRYPTOGRAPHY: {
    value: APP_TYPE_CRYPTOGRAPHY,
    name: "Cryptography",
  },
  IDENTITY: {
    value: APP_TYPE_IDENTITY,
    name: "Identity",
  },
};

export const TOTP_ISSUER = process.env.REACT_APP_TOTP_ISSUER || null; // Authenticator App TOTP issuer

const API_VERSION = process.env.REACT_APP_API_VERSION; // API VERSION
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || ""; // BACKEND URL
export const APP_ENDPOINT = `${BACKEND_URL}/${API_VERSION}/app`; // AWS API GW APP ENDPOINT

export const Messages = {
  APP_CREATED_MSG: "App created successfully",
  APP_UPDATED_MSG: "App updated",
  ERROR_MSG: "Something went wrong, please try again",
  FIELD_REQUIRED_MSG: "This field is required",
  INVALID_EMAIL_MSG: "Please enter a valid email address",
  MIN_PASSWORD_LENGTH_MSG: "Password should have minimum 8 characters",
  MIN_UPPERCASE_MSG: "Password must have at least 1 uppercase letter",
  MIN_SYMBOLS_MSG: "Password must contain at least one symbol",
  MIN_DIGITS_MSG: "Password must contain at least one number",
  MIN_OTP_LENGTH_MSG: "OTP should be 6 digits long",
  MAX_OTP_LENGTH_MSG: "OTP cannot be more than 6 digits",
  NO_LETTERS_MSG: "Must not contain letters",
  NO_SYMBOLS_MSG: "Must not contain symbols",
  NO_SPACES_MSG: "OTP cannot have spaces",
  USER_NOT_CONFIRMED_MSG: "User is not confirmed.",
  SUCCESSFULLY_SIGNUP_MSG: "Sign up successful. Please sign in to continue.",
  SETTINGS_UPDATED_MSG: "Settings updated successfully",
  MAX_SHARE_ACCOUNT_ITEM_LENGTH_MSG:
    "Maximum 10 account updates are allowed at a time",
  USER_NOT_REGISTERED_MSG: "Users are not registered with MetaKeep",
  INVALID_CUSTOM_CSS:
    "The custom CSS you provided is invalid. Please check the syntax and try again.",
};

export const {
  APP_CREATED_MSG,
  APP_UPDATED_MSG,
  ERROR_MSG,
  FIELD_REQUIRED_MSG,
  INVALID_EMAIL_MSG,
  MIN_PASSWORD_LENGTH_MSG,
  MAX_OTP_LENGTH_MSG,
  MIN_DIGITS_MSG,
  MIN_OTP_LENGTH_MSG,
  MIN_SYMBOLS_MSG,
  MIN_UPPERCASE_MSG,
  NO_LETTERS_MSG,
  NO_SPACES_MSG,
  NO_SYMBOLS_MSG,
  USER_NOT_CONFIRMED_MSG,
  SUCCESSFULLY_SIGNUP_MSG,
  SETTINGS_UPDATED_MSG,
  MAX_SHARE_ACCOUNT_ITEM_LENGTH_MSG,
  USER_NOT_REGISTERED_MSG,
} = Messages;

const DESCRIPTION_MAP = {
  API_SECRET_DESC:
    "This is your API Secret, keep it safe and don't share it with anyone.",
  API_KEY_DESC:
    "This is your API Key, keep it safe and don't share it with anyone.",
  TRUNCATED_KEY_DESC:
    "Full API Keys are never stored, only the first four characters will be shown. You can only view the full API Key at the time of creation.",
  ACCOUNT_SECRET_DESC:
    "This is your Account Secret, keep it safe and don't share it with anyone.",
  ACCOUNT_KEY_DESC:
    "This is your Account Key, keep it safe and don't share it with anyone.",
  TRUNCATED_ACCOUNT_KEY_DESC:
    "Full Account Keys are never stored, only the first four characters will be shown. You can only view the full Account Key at the time of creation.",
};

export const {
  TRUNCATED_KEY_DESC,
  API_SECRET_DESC,
  API_KEY_DESC,
  ACCOUNT_KEY_DESC,
  ACCOUNT_SECRET_DESC,
  TRUNCATED_ACCOUNT_KEY_DESC,
} = DESCRIPTION_MAP;

export const X_API_SIGNATURE_HEADER = "X-Api-Signature";

export const X_TIMESTAMP_HEADER = "X-Timestamp";

export const LIGHT_THEME = "LIGHT";
export const DARK_THEME = "DARK";
export const SYSTEM_THEME = "AUTO";

export const INSANE_TRANSACTION_SPEED = "INSANE";
export const FAST_TRANSACTION_SPEED = "FAST";

export const APPS_OWNER_PERMISSION = "APPS_OWNER";

export const USER_MESSAGE_TEMPLATE_OPTIONS_MAP = {
  RAW_MESSAGE: {
    value: "RAW_MESSAGE",
    displayName: "Raw Message",
  },
  BRAND_PERMISSION_MESSAGE: {
    value: "BRAND_PERMISSION_MESSAGE",
    displayName: "Brand Permission Message",
  },
};

const QR_MODAL_TYPE_MAP = {
  SETUP_MFA: "SETUP_MFA",
  UPDATE_MFA: "UPDATE_MFA",
};

const CODE_MODAL_TYPE = {
  VERIFY_EMAIL_CODE: "VERIFY_EMAIL_CODE",
  VERIFY_MFA_CODE: "VERIFY_MFA_CODE",
  UPDATE_MFA_CODE: "UPDATE_MFA_CODE",
};

export const { SETUP_MFA, UPDATE_MFA } = QR_MODAL_TYPE_MAP;
export const { VERIFY_EMAIL_CODE, VERIFY_MFA_CODE, UPDATE_MFA_CODE } =
  CODE_MODAL_TYPE;

export enum APP_STATE_MAP {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export const { ENABLED, DISABLED } = APP_STATE_MAP;

export const ARBITRUM_CHAIN_NAME = "ARBITRUM";
export const DEFAULT_USER_WALLET_FONT = "INTER";

export const CHAIN_CONFIG: Record<
  CHAIN_ID_TYPES,
  {
    name: string;
    env: string;
    showLambdaTab: boolean;
    color: string;
    image: string;
    symbol: string;
    decimal: number;
    display_precision: number;
    isDeprecated?: boolean;
    deprecationMessage?: React.ReactNode;
  }
> = {
  CHAIN_ID_POLYGON_MAINNET: {
    name: "Polygon",
    env: "Production",
    showLambdaTab: true,
    color: "success",
    image: "/images/icons/polygon.svg",
    symbol: "MATIC",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_POLYGON_MUMBAI: {
    name: "Polygon Mumbai",
    env: "Development",
    showLambdaTab: true,
    color: "secondary",
    image: "/images/icons/polygon.svg",
    symbol: "MATIC",
    decimal: 18,
    display_precision: 4,
    // Deprecated
    isDeprecated: true,
    deprecationMessage: (
      <>
        The Polygon Mumbai network has been discontinued by the Polygon team.
        MetaKeep now supports Polygon Amoy testnet as the replacement.
        <br />
        Please create a new Polygon Development app and take the necessary steps
        to migrate your applications and contracts to the new app.
      </>
    ),
  },
  CHAIN_ID_POLYGON_AMOY: {
    name: "Polygon Amoy",
    env: "Development",
    showLambdaTab: true,
    color: "secondary",
    image: "/images/icons/polygon.svg",
    symbol: "MATIC",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_BINANCE_MAINNET: {
    name: "Binance",
    env: "Production",
    showLambdaTab: true,
    color: "success",
    image: "/images/icons/binance.svg",
    symbol: "BNB",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_BINANCE_TESTNET: {
    name: "Binance Testnet",
    env: "Development",
    showLambdaTab: true,
    color: "secondary",
    image: "/images/icons/binance.svg",
    symbol: "BNB",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_SOLANA_MAINNET: {
    name: "Solana Mainnet",
    env: "Production",
    showLambdaTab: false,
    color: "success",
    image: "/images/icons/solana.svg",
    symbol: "SOL",
    decimal: 9,
    display_precision: 6,
  },
  CHAIN_ID_SOLANA_TESTNET: {
    name: "Solana Devnet",
    env: "Development",
    showLambdaTab: false,
    color: "secondary",
    image: "/images/icons/solana.svg",
    symbol: "SOL",
    decimal: 9,
    display_precision: 6,
  },
  CHAIN_ID_EOS_MAINNET: {
    name: "EOS Mainnet",
    env: "Production",
    showLambdaTab: false,
    color: "success",
    image: "/images/icons/EOS.svg",
    symbol: "EOS",
    decimal: 4,
    display_precision: 4,
  },
  CHAIN_ID_EOS_TESTNET: {
    name: "EOS Testnet",
    env: "Development",
    showLambdaTab: false,
    color: "secondary",
    image: "/images/icons/EOS.svg",
    symbol: "EOS",
    decimal: 4,
    display_precision: 4,
  },
  CHAIN_ID_AVALANCHE_TESTNET: {
    name: "Avalanche Fuji Testnet",
    env: "Development",
    showLambdaTab: true,
    color: "secondary",
    image: "/images/icons/avax.svg",
    symbol: "AVAX",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_AVALANCHE_MAINNET: {
    name: "Avalanche C-Chain Mainnet",
    env: "Production",
    showLambdaTab: true,
    color: "success",
    image: "/images/icons/avax.svg",
    symbol: "AVAX",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_ARBITRUM_NOVA: {
    name: "Arbitrum Nova",
    env: "Production",
    showLambdaTab: false,
    color: "success",
    image: "/images/icons/arbitrum.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_SKALE_EUROPA_DEFI_HUB_TESTNET: {
    name: "SKALE Europa DeFi Hub Testnet",
    env: "Development",
    showLambdaTab: false,
    color: "secondary",
    image: "/images/icons/skale-europa.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_SKALE_EUROPA_DEFI_HUB_MAINNET: {
    name: "SKALE Europa DeFi Hub Mainnet",
    env: "Production",
    showLambdaTab: false,
    color: "success",
    image: "/images/icons/skale-europa.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_SKALE_TITAN_AI_HUB_TESTNET: {
    name: "SKALE Titan AI Hub Testnet",
    env: "Development",
    showLambdaTab: false,
    color: "secondary",
    image: "/images/icons/skale-titan.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_SKALE_TITAN_AI_HUB_MAINNET: {
    name: "SKALE Titan AI Hub Mainnet",
    env: "Production",
    showLambdaTab: false,
    color: "success",
    image: "/images/icons/skale-titan.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_SKALE_CALYPSO_HUB_TESTNET: {
    name: "SKALE Calypso Hub Testnet",
    env: "Development",
    showLambdaTab: false,
    color: "secondary",
    image: "/images/icons/skale-calypso.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_SKALE_CALYPSO_HUB_MAINNET: {
    name: "SKALE Calypso Hub Mainnet",
    env: "Production",
    showLambdaTab: false,
    color: "success",
    image: "/images/icons/skale-calypso.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_SKALE_NEBULA_GAMING_HUB_TESTNET: {
    name: "SKALE Nebula Gaming Hub Testnet",
    env: "Development",
    showLambdaTab: false,
    color: "secondary",
    image: "/images/icons/skale-nebula.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_SKALE_NEBULA_GAMING_HUB_MAINNET: {
    name: "SKALE Nebula Gaming Hub Mainnet",
    env: "Production",
    showLambdaTab: false,
    color: "success",
    image: "/images/icons/skale-nebula.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 4,
  },
  CHAIN_ID_ETHEREUM_MAINNET: {
    name: "Ethereum Mainnet",
    env: "Production",
    showLambdaTab: false,
    color: "success",
    image: "/images/icons/ethereum.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 6,
  },
  CHAIN_ID_ETHEREUM_SEPOLIA: {
    name: "Ethereum Sepolia",
    env: "Development",
    showLambdaTab: false,
    color: "secondary",
    image: "/images/icons/ethereum.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 6,
  },
  CHAIN_ID_BASE_MAINNET: {
    name: "Base Mainnet",
    env: "Production",
    showLambdaTab: false,
    color: "success",
    image: "/images/icons/base.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 6,
  },
  CHAIN_ID_BASE_SEPOLIA: {
    name: "Base Sepolia",
    env: "Development",
    showLambdaTab: false,
    color: "secondary",
    image: "/images/icons/base.svg",
    symbol: "ETH",
    decimal: 18,
    display_precision: 6,
  },
};

export const TAB_INFO = "Info";
export const TAB_KEYS = "Api Key(s)";
export const TAB_DISPLAY = "User Wallet";
export const TAB_LAMBDA = "Lambda";
export const TAB_LOGS = "Logs";
export const TAB_TRANSACTIONS = "Transactions";
export const TAB_STATS = "Stats";
export const TAB_VIEW_WALLET = "View Wallet";

export type TAB_TYPES =
  | typeof TAB_INFO
  | typeof TAB_KEYS
  | typeof TAB_DISPLAY
  | typeof TAB_LAMBDA
  | typeof TAB_LOGS
  | typeof TAB_TRANSACTIONS
  | typeof TAB_STATS
  | typeof TAB_VIEW_WALLET;

export const APP_MENU_TABS: {
  id: TAB_TYPES;
  icon: React.ReactNode;
  label: string;
  supported_app_types: APP_TYPES[];
}[] = [
  {
    id: TAB_INFO,
    icon: <BsPencil />,
    label: "Edit App Info",
    supported_app_types: [
      APP_TYPE_CRYPTOGRAPHY,
      APP_TYPE_BLOCKCHAIN,
      APP_TYPE_IDENTITY,
    ],
  },
  {
    id: TAB_KEYS,
    icon: <BsKey />,
    label: "Edit API Keys",
    supported_app_types: [
      APP_TYPE_CRYPTOGRAPHY,
      APP_TYPE_BLOCKCHAIN,
      APP_TYPE_IDENTITY,
    ],
  },
  {
    id: TAB_DISPLAY,
    icon: <BsWallet2 />,
    label: "Edit User Wallet",
    supported_app_types: [
      APP_TYPE_CRYPTOGRAPHY,
      APP_TYPE_BLOCKCHAIN,
      APP_TYPE_IDENTITY,
    ],
  },
  {
    id: TAB_VIEW_WALLET,
    icon: <BsEye />,
    label: "View User Wallet",
    supported_app_types: [
      APP_TYPE_CRYPTOGRAPHY,
      APP_TYPE_BLOCKCHAIN,
      APP_TYPE_IDENTITY,
    ],
  },
  {
    id: TAB_LAMBDA,
    icon: <TbLambda />,
    label: "Edit Lambda",
    supported_app_types: [APP_TYPE_BLOCKCHAIN],
  },
  {
    id: TAB_LOGS,
    icon: <BsFileText />,
    label: "View Logs",
    supported_app_types: [
      APP_TYPE_CRYPTOGRAPHY,
      APP_TYPE_BLOCKCHAIN,
      APP_TYPE_IDENTITY,
    ],
  },
  {
    id: TAB_TRANSACTIONS,
    icon: <BsFileRuled />,
    label: "View Transactions",
    supported_app_types: [APP_TYPE_BLOCKCHAIN],
  },
  {
    id: TAB_STATS,
    icon: <BsGraphUp />,
    label: "View Stats",
    supported_app_types: [
      APP_TYPE_CRYPTOGRAPHY,
      APP_TYPE_BLOCKCHAIN,
      APP_TYPE_IDENTITY,
    ],
  },
];

export const SESSION_KEY_PREFIX = "sessionKey";
export const AMPLIFY_NOT_AUTHENTICATED_ERROR = "The user is not authenticated";

// Dismissible alerts that should be displayed to the user
// If dismissed, the alert should not be shown again by storing the alert id in local storage
export const ALERTS: {
  id: string;
  message: React.ReactNode;
  expiresAt: number;
}[] = [
  {
    id: "polygon-mumbai-shutdown",
    message: (
      <div>
        The Polygon Mumbai network has been discontinued by the Polygon team.
        MetaKeep now supports Polygon Amoy testnet as the replacement. Please
        create a new Polygon Development app and take the necessary steps to
        migrate your applications and contracts to the new app.
      </div>
    ),
    expiresAt: Date.parse("2024-05-12T00:00:00Z"),
  },
];
