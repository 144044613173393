import { useState } from "react";
import clx from "classnames";
import { Input, UncontrolledTooltip } from "reactstrap";
import {
  AiOutlineEyeInvisible,
  AiOutlineEye,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { CopyIcon } from "../CopyIcon";

interface CustomInputProps {
  id: string;
  label: string;
  value: string;
  type?: "text" | "password";
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  customCss?: string;
  placeholder?: string;
  wrapperClass?: string;
  error?: string | null;
  description?: string | null;
  fullWidth?: boolean;
  withCopy?: boolean;
  isDisabled?: boolean;
  hidden?: boolean;
  required?: boolean;
  disabled?: boolean;
}

export const CustomInput = ({
  type = "text",
  onChangeHandler,
  customCss,
  placeholder = "",
  label,
  wrapperClass,
  id,
  error = null,
  description = null,
  fullWidth = false,
  withCopy = false,
  isDisabled = false,
  ...rest
}: CustomInputProps) => {
  const [showPass, toggle] = useState(false);
  return (
    <div
      hidden={rest.hidden}
      className={clx(
        "input-wrapper",
        fullWidth && "full-width",
        wrapperClass && wrapperClass
      )}
    >
      <div className="label-wrapper">
        {label && (
          <label htmlFor={id}>
            {label}
            {rest.required && <sup>*</sup>}
          </label>
        )}
        {description && (
          <>
            <AiOutlineQuestionCircle id={`tooltip-${id}`} />
            <UncontrolledTooltip
              popperClassName="tooltip-popper"
              target={`tooltip-${id}`}
              placement="top"
              autohide={false}
            >
              {description}
            </UncontrolledTooltip>
          </>
        )}
      </div>
      <div className="input-container">
        <Input
          disabled={isDisabled}
          id={id}
          placeholder={placeholder}
          type={type === "password" && showPass ? "text" : type}
          onChange={onChangeHandler}
          className={clx(
            "text-input",
            customCss && customCss,
            error && "error"
          )}
          required
          {...rest}
        />
        {withCopy && <CopyIcon text={rest.value} id={id} />}
      </div>
      {type === "password" && (
        <div
          onClick={() => {
            toggle((prevState) => !prevState);
          }}
          className={clx("eye-icon", withCopy && "with-copy")}
        >
          {showPass ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
        </div>
      )}
      {error && <span>{error}</span>}
    </div>
  );
};
