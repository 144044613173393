import React, { useState } from "react";
import clx from "classnames";
import styles from "./index.module.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip,
} from "reactstrap";
import { withFeature } from "../../../../../../hoc";
import { CTA_BUTTON_STYLE_FEATURE } from "../../../../../../constants";
import { PlanTag } from "../../../../../../components";

const CTA_BUTTON_STYLE_TYPE_MAP = {
  ALLOW: {
    value: "ALLOW",
    displayName: "Allow",
  },
  YES_NO: {
    value: "YES_NO",
    displayName: "Yes / No",
  },
};

export const CTAButtonStyleSelect = withFeature(
  ({ onClick, value, isDisabled }) => {
    const [isActive, setActive] = useState(false);
    const onToggle = () => {
      setActive(!isActive);
    };
    const options = Object.values(CTA_BUTTON_STYLE_TYPE_MAP).map((option) => (
      <DropdownItem
        key={option.value}
        onClick={() => onClick(option.value)}
        value={option.value}
      >
        {option.displayName}
      </DropdownItem>
    ));
    const displayVal = CTA_BUTTON_STYLE_TYPE_MAP[value]?.displayName;
    return (
      <div className={styles.reason_string_select_wrapper}>
        <div className={clx("label-wrapper")}>
          <label htmlFor="ctaButtonStyleSelect">CTA Button Style</label>
          <AiOutlineQuestionCircle id={`tooltip-cta-button-style`} />
          <UncontrolledTooltip
            popperClassName="tooltip-popper"
            target={`tooltip-cta-button-style`}
            placement="top"
            autohide={false}
          >
            <div className={styles.custom_message_select_tooltip_info}>
              Choose how you want the CTA button to be displayed:
            </div>
            <ul>
              <li>
                <b>Allow:</b> A single button with the text "Allow".
              </li>
              <li>
                <b>Yes/No:</b> Two buttons, one with the text "Yes" and the
                other with the text "No".
              </li>
            </ul>
          </UncontrolledTooltip>
          <PlanTag locked={isDisabled} id="cta-button-style" />
        </div>
        <Dropdown id="ctaButtonStyleSelect" isOpen={isActive} toggle={onToggle}>
          <DropdownToggle
            caret
            className="w-100 d-flex justify-content-between align-items-center"
            color=""
            disabled={isDisabled}
          >
            {displayVal || "Select CTA Button Style"}
          </DropdownToggle>
          <DropdownMenu>{options}</DropdownMenu>
        </Dropdown>
      </div>
    );
  },
  CTA_BUTTON_STYLE_FEATURE
);
