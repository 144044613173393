import React, { useState } from "react";
import clx from "classnames";
import styles from "./index.module.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import {
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import {
  FAST_TRANSACTION_SPEED,
  INSANE_TRANSACTION_SPEED,
} from "../../../../../../constants";

export const LambdaTransactionSpeedSelect = ({ activeSelection, onChange }) => {
  const [isActive, setActive] = useState(false);

  const onToggle = () => {
    setActive(!isActive);
  };

  return (
    <div className={clx("input-wrapper")}>
      <div className={clx("label-wrapper")}>
        <label htmlFor="theme">Transaction Speed</label>
        <AiOutlineQuestionCircle id={`tooltip-app-transaction-speed`} />
        <UncontrolledTooltip
          popperClassName="tooltip-popper"
          target={`tooltip-app-transaction-speed`}
          placement="top"
          autohide={false}
        >
          <div className={styles.transaction_speed_tooltip_info}>
            Choose the transaction speed for your App.
          </div>
          <div className={styles.transaction_speed_tooltip_title}>Fast:</div>
          <div className={styles.transaction_speed_tooltip_desc}>
            {" "}
            This is the recommended option. It uses AI and statistical models to
            estimate the transaction speed, so you don't overpay during high
            network activity.
          </div>
          <div className={styles.transaction_speed_tooltip_title}>Insane:</div>
          <div className={styles.transaction_speed_tooltip_desc}>
            This option has no throttles. Your transaction will be pushed out
            immediately to be executed at insane speeds, limited only by the
            physical limits of the blockchain.
          </div>
        </UncontrolledTooltip>
      </div>
      <Dropdown isOpen={isActive} toggle={onToggle}>
        <DropdownToggle
          caret
          className="w-100 d-flex justify-content-between align-items-center"
          color=""
        >
          {" "}
          {activeSelection}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={onChange} value={FAST_TRANSACTION_SPEED}>
            Fast
          </DropdownItem>
          <DropdownItem onClick={onChange} value={INSANE_TRANSACTION_SPEED}>
            Insane
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
