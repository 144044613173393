export const networkTooltipContent = (
  <>
    <span>Select the chain for your app:</span>
    <p />
    <h6>Popular Chains</h6>
    <ul>
      <li>
        <b>
          <u>Polygon</u>
        </b>
        : Polygon supports cheap and fast transactions. It is a great choice for
        apps that need to support a large number of users{" "}
      </li>
      <li>
        <b>
          <u>Solana </u>
        </b>
        : Solana offers high throughput and low transaction fees.
      </li>
      <li>
        <b>
          <u>Ethereum </u>
        </b>
        : Ethereum is the most popular blockchain platform for building
        decentralized applications. The transactions fees are high, so we
        recommend using a different chain for apps that need to support a large
        number of users.
      </li>
      <li>
        <b>
          <u>SKALE </u>
        </b>
        : SKALE is a limitlessly scalable, fast, on-demand blockchain network
        with zero gas fees, allowing quick deployment of interoperable
        EVM-compatible chains without compromising security or decentralization.
      </li>
    </ul>
    <p></p>
    <h6>Others Chains</h6>
    <ul>
      <li>
        <b>
          <u>Avalanche </u>
        </b>
        : Avalanche is a decentralized blockchain platform that offers high
        scalability and low transaction fees.
      </li>
      <li>
        <b>
          <u>Base </u>
        </b>
        : Base is Coinbase's Ethereum Layer 2 (L2) chain that offers a low-cost
        and fast transaction experience for users.
      </li>
      <li>
        <b>
          <u>EOS </u>
        </b>
        : EOS is a decentralized blockchain platform that offers high
        scalability and low transaction fees.
      </li>
      <li>
        <b>
          <u>Arbitrum </u>
        </b>
        : Arbitrum is a layer 2 solution for Ethereum that offers high
        throughput and low transaction fees.
      </li>
      <li>
        <b>
          <u>Binance </u>
        </b>
        : Binance has higher transaction fees and throughput.
      </li>
    </ul>
    <p></p>
    <p>
      <i>
        Please note that if you require User Wallets on an EVM-compatible chain
        not present in the list, you can choose any EVM-compatible chain, such
        as Polygon or Binance Smart Chain.
      </i>
    </p>
  </>
);

export const environmentTooltipContent = (
  <>
    <span>Choose the environment:</span>
    <ul>
      <li>
        <b>
          <u>Production </u>
        </b>
        : Select this option for production apps.
      </li>
      <li>
        <b>
          <u>Development </u>
        </b>
        : Select this option for development and testing apps.
      </li>
    </ul>
  </>
);

export const appTypeTooltipContent = (
  <>
    <span>Select the type of app you want to create:</span>
    <ul>
      {" "}
      <li>
        <b>
          <u>Cryptography </u>
        </b>
        : Create apps that encrypt sensitive information, generate digital
        signatures, and implement advanced cryptographic protocols.
      </li>
      <li>
        <b>
          <u>Blockchain </u>
        </b>
        : Build apps that interact with blockchain networks like Ethereum,
        Solana, and others to create tokens, manage digital assets, and develop
        decentralized applications.
      </li>
      <li>
        <b>
          <u>Identity </u>
        </b>
        : Build apps that manage user identities, authenticate users, and manage
        user data.
      </li>
    </ul>
  </>
);

export const cryptographyAlgorithmTooltipContent = (
  <>
    <span>Select the cryptography algorithm:</span>
    <ul>
      {" "}
      <li>
        <b>
          <u>BabyJubJub Polygon Id </u>
        </b>
        : User identity management and signing on Polygon ID
        infrastructure(https://polygonid.com/).
      </li>
      <li>
        <b>
          <u>Encryption </u>
        </b>
        : User data encryption using state-of-the-art encryption algorithms
        including quantum-safe encryption algorithms.
      </li>
    </ul>
  </>
);

export const identityProtocolTooltipContent = (
  <>
    <span>Select the identity protocol:</span>
    <ul>
      {" "}
      <li>
        <b>
          <u>Polygon ID </u>
        </b>
        : Polygon ID provides privacy focused tools to put users in control of
        their identity across every digital surface
        (https://www.polygonid.com/).
      </li>
    </ul>
  </>
);
