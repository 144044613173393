import { Badge } from "reactstrap";
import {
  CHAIN_CONFIG,
  CRYPTOGRAPHY_ALGORITHMS_CONFIG,
  IDENTITY_PROTOCOL_CONFIG,
  APP_TYPE_BLOCKCHAIN,
  APP_TYPE_CRYPTOGRAPHY,
  APP_TYPE_IDENTITY,
  TAB_INFO,
  TAB_DISPLAY,
  TAB_KEYS,
  TAB_LAMBDA,
  TAB_LOGS,
  TAB_TRANSACTIONS,
  TAB_STATS,
  TAB_VIEW_WALLET,
  ENVIRONMENTS_MAP,
  TAB_TYPES,
} from "../../../../constants";
import { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import clx from "classnames";
import { AppCardMenuDropdown } from "./components/AppCardMenuDropdown";
import { TbAlertCircleFilled } from "react-icons/tb";
import { AppTypeData } from "../../../../utils";

type AppCardProps = {
  appName: string;
  onClick: () => void;
  onViewWallet: () => void;
  onCardEditAction: (tab: TAB_TYPES) => void;
  isDisabled: boolean;
  appTypeData: AppTypeData;
};

export const AppCard = ({
  appName,
  onClick,
  onViewWallet,
  onCardEditAction,
  isDisabled,
  appTypeData,
}: AppCardProps) => {
  let config: {
    name?: string;
    env?: string;
    color?: string;
    image?: string;
  } = {};
  if (appTypeData.appType === APP_TYPE_BLOCKCHAIN) {
    config = CHAIN_CONFIG[appTypeData.blockchain.chainId];
  }
  if (appTypeData.appType === APP_TYPE_CRYPTOGRAPHY) {
    config = CRYPTOGRAPHY_ALGORITHMS_CONFIG[appTypeData.cryptography.algorithm];
    config.env = ENVIRONMENTS_MAP[appTypeData.cryptography.environment]?.name;
    config.color =
      ENVIRONMENTS_MAP[appTypeData.cryptography.environment]?.color;
  }
  if (appTypeData.appType === APP_TYPE_IDENTITY) {
    config = IDENTITY_PROTOCOL_CONFIG[appTypeData.identity.protocol];
  }
  const { name = "", env = "", color = "", image = "" } = config;

  const [isOpen, toggle] = useState(false);
  const onToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggle((prevState) => !prevState);
  };
  const onDropdownItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const { id } = e.target as HTMLDivElement;
    switch (id) {
      case TAB_INFO:
        onCardEditAction(TAB_INFO);
        break;
      case TAB_DISPLAY:
        onCardEditAction(TAB_DISPLAY);
        break;
      case TAB_KEYS:
        onCardEditAction(TAB_KEYS);
        break;
      case TAB_LAMBDA:
        onCardEditAction(TAB_LAMBDA);
        break;
      case TAB_VIEW_WALLET:
        onViewWallet();
        break;
      case TAB_LOGS:
        onCardEditAction(TAB_LOGS);
        break;
      case TAB_TRANSACTIONS:
        onCardEditAction(TAB_TRANSACTIONS);
        break;
      case TAB_STATS:
        onCardEditAction(TAB_STATS);
        break;
      default:
        break;
    }
  };
  return (
    <div
      onClick={onClick}
      className={clx("app-card", isDisabled && "disabled-card")}
    >
      <div className="app-card-img">
        <img src={image} alt="" />
        <Badge color={color} className="chain-badge">
          {env}
        </Badge>
        {isDisabled && (
          <Badge color="secondary" className="disable-badge">
            Disabled
          </Badge>
        )}
        {/* Show alert tooltip if the chain is deprecated */}
        {appTypeData.appType === APP_TYPE_BLOCKCHAIN &&
          CHAIN_CONFIG[appTypeData.blockchain.chainId].isDeprecated && (
            <div className="app-alert">
              <TbAlertCircleFilled
                id="app-alert"
                // Prevent clicks on the tooltip from opening the app
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <UncontrolledTooltip
                popperClassName="tooltip-popper"
                target={`app-alert`}
                placement="top"
                autohide={false}
              >
                {
                  CHAIN_CONFIG[appTypeData.blockchain.chainId]
                    .deprecationMessage
                }
              </UncontrolledTooltip>
            </div>
          )}
      </div>
      <div className="app-desc">
        <AppCardMenuDropdown
          onToggle={onToggle}
          isOpen={isOpen}
          onDropdownItemClick={onDropdownItemClick}
          appType={appTypeData.appType}
        />
        <div className="app-name">Name: {appName}</div>
        {appTypeData.appType === APP_TYPE_BLOCKCHAIN && (
          <div className="app-chain">
            Chain: <span>{name}</span>
          </div>
        )}

        {appTypeData.appType === APP_TYPE_CRYPTOGRAPHY && (
          <div className="app-chain">
            Algorithm: <span>{name}</span>
          </div>
        )}
        {appTypeData.appType === APP_TYPE_IDENTITY && (
          <div className="app-chain">
            Protocol: <span>{name}</span>
          </div>
        )}
      </div>
    </div>
  );
};
