"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storage = exports.StorageWrapper = exports.MemoryStorage = void 0;
let _memoryStorage = {};
class MemoryStorage {
    static setItem(key, value) {
        _memoryStorage[key] = value;
    }
    static getItem(key) {
        return Object.prototype.hasOwnProperty.call(_memoryStorage, key)
            ? _memoryStorage[key]
            : undefined;
    }
    static removeItem(key) {
        return delete _memoryStorage[key];
    }
    static clear() {
        return (_memoryStorage = {});
    }
}
exports.MemoryStorage = MemoryStorage;
class StorageWrapper {
    constructor() {
        try {
            this._storage = window.localStorage;
            this._storage.setItem("metakeep_ls_test", "test");
            this._storage.removeItem("metakeep_ls_test");
        }
        catch (e) {
            this._storage = MemoryStorage;
        }
    }
}
exports.StorageWrapper = StorageWrapper;
exports.storage = new StorageWrapper()._storage;
