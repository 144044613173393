import Chart from "react-apexcharts";
import styles from "../../index.module.scss";
import {
  APP_TYPE_BLOCKCHAIN,
  APP_TYPE_CRYPTOGRAPHY,
  APP_TYPE_IDENTITY,
  CHAIN_CONFIG,
} from "../../../../../../constants";
import { memo } from "react";
import { AppTypeData, formatTotalCost } from "../../../../../../utils";

export interface ChartData {
  name: string;
  data: number[];
}

export interface AppStats {
  totalTransactionsSeries: ChartData[];
  totalCallsSeries: ChartData[];
  uniqueUsersSeries: ChartData[];
  categories: string[];
  totalCalls: number | null;
  totalTransactions: number | null;
  totalCost: number | null;
  totalCostSeries: ChartData[];
}

interface StatsChartsProps {
  appStats: AppStats;
  noDataText: string;
  appTypeData: AppTypeData;
}

const StatsCharts = ({
  appStats,
  noDataText,
  appTypeData,
}: StatsChartsProps) => {
  const SUPPORTED_CHARTS_BASED_ON_APP_TYPE = {
    TOTAL_CALLS: {
      series: appStats.totalCallsSeries,
      supportedAppTypes: [
        APP_TYPE_BLOCKCHAIN,
        APP_TYPE_CRYPTOGRAPHY,
        APP_TYPE_IDENTITY,
      ],
      color: "#27aeef",
      label: "Total Calls",
    },
    UNIQUE_USERS: {
      series: appStats.uniqueUsersSeries,
      supportedAppTypes: [
        APP_TYPE_BLOCKCHAIN,
        APP_TYPE_CRYPTOGRAPHY,
        APP_TYPE_IDENTITY,
      ],
      color: "#f46a9b",
      label: "Unique Users",
    },
    TOTAL_TRANSACTIONS: {
      series: appStats.totalTransactionsSeries,
      supportedAppTypes: [APP_TYPE_BLOCKCHAIN],
      color: "#ede15b",
      label: "Total Transactions",
    },
    TOTAL_COST: {
      series: appStats.totalCostSeries,
      supportedAppTypes: [APP_TYPE_BLOCKCHAIN],
      color: "#27aaef",
      label: `Total Cost (${
        appTypeData.appType === APP_TYPE_BLOCKCHAIN
          ? CHAIN_CONFIG[appTypeData.blockchain.chainId]?.symbol || ""
          : ""
      })`,
    },
  };

  const supportedCharts = Object.keys(
    SUPPORTED_CHARTS_BASED_ON_APP_TYPE
  ).filter((chartType) =>
    SUPPORTED_CHARTS_BASED_ON_APP_TYPE[
      chartType as keyof typeof SUPPORTED_CHARTS_BASED_ON_APP_TYPE
    ].supportedAppTypes.includes(appTypeData.appType)
  );

  const renderCharts = () => {
    const rows: JSX.Element[] = [];
    let currentRow: JSX.Element[] = [];

    supportedCharts.forEach((chartType, index) => {
      const chartData =
        SUPPORTED_CHARTS_BASED_ON_APP_TYPE[
          chartType as keyof typeof SUPPORTED_CHARTS_BASED_ON_APP_TYPE
        ];

      currentRow.push(
        <div key={chartType} style={{ flex: 1 }}>
          <Chart
            // TODO: Fix the following type errors and remove ts-ignore
            // @ts-ignore
            options={
              getChartOptions(
                chartData.series,
                chartData.color,
                chartData.label,
                noDataText,
                appStats.categories
              ).options
            }
            series={chartData.series}
            type="line"
            height={225}
            width={425}
            style={{
              alignItems: "center",
            }}
          />
        </div>
      );

      if ((index + 1) % 2 === 0 || index === supportedCharts.length - 1) {
        rows.push(
          <div
            key={`row-${index}`}
            style={{ display: "flex", flexDirection: "row", gap: "30px" }}
          >
            {currentRow}
          </div>
        );
        currentRow = [];
      }
    });

    return rows;
  };

  const getChartOptions = (
    series: ChartData[],
    color: string,
    name: string,
    noDataText: string,
    categories: string[]
  ) => {
    return {
      series: series,
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: [color],
        stroke: {
          width: [2, 2],
          curve: "smooth",
        },
        markers: {
          size: 4,
        },
        noData: {
          text: noDataText,
        },
        title: {
          text: name,
          align: "left",
        },
        xaxis: {
          categories: categories,
          tickAmount: 7,
          labels: {
            hideOverlappingLabels: true,
            floating: true,
            rotate: -70,
            style: {
              cssClass: styles.stats_x_axis_label,
            },
          },
        },
        yaxis: {
          forceNiceScale: true,
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        marginLeft: "30px",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "flex-end",
          marginTop: "10px",
          marginBottom: "10px",
          flexBasis: "100%",
        }}
      >
        <TotalValue value={appStats.totalCalls} label="Total Calls" />
        {appTypeData.appType === APP_TYPE_BLOCKCHAIN && (
          <TotalValue
            value={appStats.totalTransactions}
            label="Total Transactions"
          />
        )}
        {appTypeData.appType === APP_TYPE_BLOCKCHAIN && (
          <TotalValue
            value={formatTotalCost(
              appStats.totalCost || 0,
              appTypeData.blockchain.chainId
            )}
            label="Total Cost"
          />
        )}
      </div>
      {renderCharts()}
    </div>
  );
};

interface TotalValueProps {
  value: number | string | null;
  label: string;
}

const TotalValue = ({ value, label }: TotalValueProps) => {
  if (value === null) return null;
  return (
    <div className={styles.total_value}>
      <div>
        {label}: {value}
      </div>
    </div>
  );
};

export const MemoizedStatsCharts = memo(StatsCharts);
