import { useState, useEffect, useCallback } from "react";
import { getAppTransactions } from "../../../../api";
import {
  Button,
  Dropdown,
  DropdownToggle,
  ModalBody,
  Table,
  DropdownItem,
  DropdownMenu,
  UncontrolledTooltip,
} from "reactstrap";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Loader } from "../../../../components";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FiRefreshCw } from "react-icons/fi";
import styles from "./index.module.scss";
import clx from "classnames";
import { toast } from "react-toastify";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { selectActiveAccount } from "../../../../redux/slice/dashboardSlice";
import { getNormalizedCost, formatTotalCost } from "../../../../utils";
dayjs.extend(relativeTime);

const PAGE_SIZES = [10, 20, 30];

const TRANSACTION_STATUS_CONFIG = {
  COMPLETED: {
    displayName: "Success",
    color: "green",
  },
  FAILED: {
    displayName: "Failed",
    color: "red",
  },
  QUEUED: {
    displayName: "Queued",
    color: "yellow",
  },
  CANCELLED: {
    displayName: "Cancelled",
    color: "red",
  },
  UNKNOWN_STATUS: {
    displayName: "Unknown",
    color: "black",
  },
};

const TRANSACTIONS_TABLE_HEADERS = [
  {
    name: "Transaction Id",
    description: "Transaction Id",
    id: "transactionId",
    customCss: styles.path_header,
  },
  {
    name: "Status",
    description: "Transaction status",
    id: "transactionStatus",
    customCss: styles.method_header,
  },
  {
    name: "Cost",
    description: "Total cost of the transaction",
    id: "transactionTotalCost",
    customCss: styles.method_header,
  },
  {
    name: "Time",
    description: "Transaction time",
    id: "transactionCreatedAt",
    customCss: styles.time_header,
  },
  {
    name: "Explorer URL",
    description: "Transaction explorer URL to view the transaction details",
    id: "transactionChainScanUrl",
    customCss: styles.method_header,
  },
];

export const AppTransactionsContainer = ({ appId, chainId }) => {
  const [transactionsTableMap, setTransactionsTableMap] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, toggleLoading] = useState(false);
  const [isActive, setActive] = useState(false);
  const [hasError, setError] = useState(false);
  const activeSharedAccount = useSelector(selectActiveAccount);

  const onToggle = () => {
    setActive(!isActive);
  };

  const fetchAppTransactions = useCallback(
    async (paginationToken = null) => {
      try {
        setError(false);
        toggleLoading(true);
        const body = {
          appId,
          pageSize,
          paginationToken,
          activeSharedAccount,
        };
        const response = await getAppTransactions(body);

        setTransactionsTableMap((prevState) => {
          const currentPageNumber = Object.keys(prevState).length + 1;
          return {
            ...prevState,
            [currentPageNumber]: response,
          };
        });
        if (paginationToken) setActivePage((prevState) => prevState + 1);
        else setActivePage(1);
      } catch (err) {
        console.error(err);
        toast.error("Failed to fetch transactions, please try again");
        setError(true);
      } finally {
        toggleLoading(false);
      }
    },
    [appId, pageSize, activeSharedAccount]
  );

  useEffect(() => {
    fetchAppTransactions();
  }, [fetchAppTransactions]);

  const onNextPageClick = () => {
    if (transactionsTableMap[activePage + 1]) {
      setActivePage((prev) => prev + 1);
    } else {
      fetchAppTransactions(transactionsTableMap[activePage].paginationToken);
    }
  };

  const onBackPageClick = () => {
    if (activePage === 1) return;
    setActivePage(activePage - 1);
  };
  const handlePageSizeChange = (size) => {
    setTransactionsTableMap({});
    setPageSize(size);
  };

  const onRefreshTransactions = () => {
    setTransactionsTableMap({});
    fetchAppTransactions();
  };

  const pageSizeOptions = PAGE_SIZES.map((size) => (
    <DropdownItem key={size} onClick={() => handlePageSizeChange(size)}>
      {size}
    </DropdownItem>
  ));
  const currentPage = transactionsTableMap[activePage] || {};
  const transactions = currentPage?.transactions || [];
  const nextPageExists =
    transactionsTableMap[activePage + 1] || currentPage?.paginationToken;
  const pages = Object.keys(transactionsTableMap);
  return (
    <ModalBody className={styles.log_table_container}>
      <div className={styles.log_controls}>
        <div className={styles.logs_info}>
          <BsFillInfoCircleFill /> You can only view transactions created using
          the MetaKeep APIs.
        </div>
        <Dropdown id="pageSizeSelect" isOpen={isActive} toggle={onToggle}>
          <DropdownToggle
            caret
            className="w-100 d-flex justify-content-between align-items-center"
            color=""
            disabled={hasError || transactions.length === 0}
          >
            {`Page Size: ${pageSize}`}
          </DropdownToggle>
          <DropdownMenu>{pageSizeOptions}</DropdownMenu>
        </Dropdown>
        <Button
          className={clx("primary-btn", "clr-transp", styles.refresh_control)}
          onClick={onRefreshTransactions}
        >
          <FiRefreshCw /> Refresh Transactions
        </Button>
      </div>
      <TransactionsTable
        transactions={transactions}
        isLoading={isLoading}
        hasError={hasError}
        chainId={chainId}
      />

      <PageinationControls
        onNextPageClick={onNextPageClick}
        onBackPageClick={onBackPageClick}
        hasNext={nextPageExists}
        canGoBack={activePage > 1}
        pages={pages}
        activePage={activePage}
        setActivePage={setActivePage}
      />

      {isLoading && <Loader />}
    </ModalBody>
  );
};

const TransactionsTable = ({
  transactions = [],
  isLoading,
  hasError,
  chainId,
}) => {
  if (!isLoading && transactions.length === 0)
    return <TransactionsTableEmptyState hasError={hasError} />;
  const tableRows = (transactions || []).map((transaction, idx) => {
    const {
      transactionId = "",
      transactionCost = "",
      transactionChainscanUrl = "",
      timestampUsec,
      transactionStatus,
    } = transaction;
    const statusColor =
      TRANSACTION_STATUS_CONFIG[transactionStatus]?.color || "black";
    const statusDisplayName =
      TRANSACTION_STATUS_CONFIG[transactionStatus]?.displayName || "Unknown";
    return (
      <tr className={styles.table_data_row} key={idx}>
        <td style={{ whiteSpace: "nowrap" }}>{transactionId}</td>
        <td style={{ color: statusColor }}>{statusDisplayName}</td>
        <td>
          {transactionCost
            ? formatTotalCost(
                getNormalizedCost(transactionCost, chainId),
                chainId
              )
            : "N/A"}
        </td>
        <td>{timestampUsec ? dayjs(timestampUsec / 1000).fromNow() : "-"}</td>
        <td>
          <a
            href={transactionChainscanUrl}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#0000FF" }}
          >
            View in Explorer
          </a>
        </td>
      </tr>
    );
  });
  const tableHeaders = TRANSACTIONS_TABLE_HEADERS.map((header) => (
    <TableHead key={header.id} {...header} />
  ));
  return (
    <Table bordered responsive hover>
      <thead>
        <tr className={styles.table_header_row}>{tableHeaders}</tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </Table>
  );
};

const PageinationControls = ({
  onNextPageClick,
  onBackPageClick,
  hasNext,
  canGoBack,
  pages,
  activePage,
  setActivePage,
}) => {
  const pageButtons = pages.map((page) => {
    const isActive = parseInt(page) === activePage;
    return (
      <Button
        key={page}
        className={clx(
          styles.pagination_button,
          styles.number_button,
          isActive && styles.active_page
        )}
        onClick={() => setActivePage(parseInt(page))}
      >
        {page}
      </Button>
    );
  });
  return (
    <div className={styles.pagination_cta}>
      <Button
        disabled={!canGoBack}
        onClick={onBackPageClick}
        className={styles.pagination_button}
      >
        <IoIosArrowBack />
      </Button>
      {pageButtons}
      <Button
        onClick={onNextPageClick}
        className={styles.pagination_button}
        disabled={!hasNext}
      >
        <IoIosArrowForward />
      </Button>
    </div>
  );
};

const TransactionsTableEmptyState = ({ hasError = false }) => {
  const message = hasError
    ? "Failed to fetch transactions"
    : "No transactions found";
  return <div className={styles.table_empty_state}>{message}</div>;
};

export const TableHead = ({ name, description, id, customCss }) => {
  return (
    <th className={customCss}>
      <span className={styles.log_head_value}>
        {name}
        <AiOutlineQuestionCircle id={id} />
        <UncontrolledTooltip
          autohide={false}
          popperClassName="tooltip-popper"
          target={id}
        >
          {description}
        </UncontrolledTooltip>
      </span>
    </th>
  );
};
