import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import clx from "classnames";

interface PlanTagProps {
  locked?: boolean;
  id: string;
}

export const PlanTag: React.FC<PlanTagProps> = ({ locked = true, id }) => (
  <>
    <UncontrolledTooltip
      popperClassName="tooltip-popper"
      target={`plan-tag-${id}`}
      placement="top"
      autohide={false}
    >
      {locked
        ? "Please upgrade your plan to access this feature."
        : "Your enterprise plan includes access to this feature."}
    </UncontrolledTooltip>
    <div className="paid_feature" id={`plan-tag-${id}`}>
      <div className={clx("indicator", locked ? "unlock" : "available")}>
        {locked ? "Upgrade to unlock" : "Enterprise"}
      </div>
    </div>
  </>
);
