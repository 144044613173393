import { useCallback, useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { Loader, OTPField } from "../../../../components";
import { useForm } from "../../../../hooks";
import { AiOutlineClose } from "react-icons/ai";
import {
  VERIFY_EMAIL_CODE,
  VERIFY_MFA_CODE,
  UPDATE_MFA_CODE,
} from "../../../../constants";

const MODAL_VALUES_MAP = {
  [VERIFY_EMAIL_CODE]: {
    title: "Check your email",
    getCopy: (email = "") => (
      <>
        Enter the security code we sent to your email <b>{email}</b>
      </>
    ),
  },
  [VERIFY_MFA_CODE]: {
    title: "Verify 2FA code",
    getCopy: () => <>Enter the code generated by your authenticator app</>,
  },
  [UPDATE_MFA_CODE]: {
    title: "First, verify current 2FA code",
    getCopy: () => <>Enter the code generated by your authenticator app</>,
  },
};

const getAuthErrorMsg = (error) => {
  if (typeof error === "string" && error.includes("Invalid session"))
    return "The login session has expired. Please refresh the page to continue.";
  return error || "An error occurred. Please try again.";
};

export const CodeModal = ({
  isOpen = true,
  onSubmit,
  resendCode,
  email,
  onClose = () => {},
  onInvalidCodeCallback = null,
  modalType = VERIFY_MFA_CODE,
}) => {
  const { setDirectValue, values, checkFormValidity } = useForm({
    OTP: null,
  });
  const [inProgress, setProgress] = useState(false);
  const [authMsg, setAuthMsg] = useState({ msg: null, isError: false });

  const { OTP } = values;

  const onVerify = useCallback(async () => {
    const isValid = checkFormValidity();
    if (!isValid) return;
    try {
      setProgress(true);
      await onSubmit(OTP);
    } catch (err) {
      const message = getAuthErrorMsg(err.message);
      setAuthMsg({ msg: message, isError: true });
      setProgress(false);
      if (typeof onInvalidCodeCallback === "function") onInvalidCodeCallback();
      console.log("err", err.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OTP, checkFormValidity, onSubmit]);

  useEffect(() => {
    if (OTP && OTP.length === 6) onVerify();
  }, [OTP, onVerify]);

  const onResendCodeClick = async () => {
    try {
      await resendCode();
      setAuthMsg({
        msg: "A new code has been sent to your email",
        isError: false,
      });
    } catch (err) {
      setAuthMsg({ msg: err.message, isError: true });
    }
  };

  const handleCodeChange = useCallback(
    (val) => {
      setAuthMsg({ msg: null, isError: false });
      setDirectValue("OTP", val);
    },
    [setDirectValue]
  );
  const { msg, isError } = authMsg;
  const modalCopy = MODAL_VALUES_MAP[modalType].getCopy(email);
  const showResend = modalType === VERIFY_EMAIL_CODE;
  return (
    <Modal centered className="auth-modal" isOpen={isOpen}>
      <Title title={MODAL_VALUES_MAP[modalType].title} onClose={onClose} />
      <ModalCopy modalCopy={modalCopy} />
      <ModalMsg msg={msg} isError={isError} />
      <OTPField onChangeHandler={handleCodeChange} hasError={isError} />
      <ResendCode
        onResendCodeClick={onResendCodeClick}
        showResend={showResend}
      />
      <div className="auth-modal-footer">
        Secured By <b>MetaKeep</b>
      </div>
      {inProgress && <Loader />}
    </Modal>
  );
};

const Title = ({ title, onClose }) => {
  const canClose = typeof onClose === "function";
  return (
    <div className="auth-modal-tile-container">
      <div className="auth-modal-title">{title}</div>
      {canClose && (
        <AiOutlineClose
          data-testid="close-btn"
          onClick={onClose}
          className="auth-modal-close-btn"
        />
      )}
    </div>
  );
};

const ModalCopy = ({ modalCopy }) => {
  return <div className="auth-copy">{modalCopy}</div>;
};

const ResendCode = ({ onResendCodeClick, showResend }) => {
  if (showResend)
    return (
      <div onClick={onResendCodeClick} className="code-link">
        Resend Code
      </div>
    );
  return null;
};

const ModalMsg = ({ msg, isError }) => {
  if (!msg) return null;
  return (
    <span
      className="auth-err-msg"
      style={{
        color: !isError && "green",
      }}
    >
      {msg}
    </span>
  );
};
