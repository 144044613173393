"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isWebAuthnAuthenticatorAvailable = exports.signRequest = exports.createKeyPair = exports.REUSE_PASSKEY_ERROR = void 0;
const cryptoUtils_1 = require("./cryptoUtils");
const MetakeepAuth_1 = require("./MetakeepAuth");
const logger_1 = require("./logger");
const constants_1 = require("./constants");
exports.REUSE_PASSKEY_ERROR = "InvalidStateError";
const AVAILABLE_TRANSPORTS = ["internal"];
const CREDENTIAL_TYPE = "public-key";
const getUser = (parsedUser) => ({
    id: crypto.getRandomValues(new Uint8Array(32)),
    name: parsedUser.displayString,
    displayName: parsedUser.displayString,
});
const getPublicKeyCredentialCreationOptions = (parsedUser, excludeWebauthnKey) => __awaiter(void 0, void 0, void 0, function* () {
    const rp = process.env.REACT_APP_WEBAUTHN_RELYING_PARTY;
    if (!rp) {
        throw new Error("Relying party not set in env.");
    }
    if (!process.env.REACT_APP_WEBAUTHN_REGISTRATION_DUMMY_CHALLENGE_B64_STRING) {
        throw new Error("WebAuthn challenge string not set in env.");
    }
    if (!constants_1.IS_METAKEEP_IDENTITY_PROVIDER_PREFIX_SET) {
        throw new Error("MetakeepAuth user identity prefix not set in env.");
    }
    return {
        challenge: (0, cryptoUtils_1.base64ToBytesArray)(process.env.REACT_APP_WEBAUTHN_REGISTRATION_DUMMY_CHALLENGE_B64_STRING),
        rp: JSON.parse(rp),
        user: getUser(parsedUser),
        pubKeyCredParams: [
            { alg: -7, type: "public-key" }, // ES256 (Webauthn's default algorithm)
            { alg: -257, type: "public-key" }, // RS256 (for Windows Hello and others)
        ],
        timeout: 60000,
        authenticatorSelection: {
            userVerification: "required",
            residentKey: "preferred",
            authenticatorAttachment: "platform",
        },
        excludeCredentials: (excludeWebauthnKey === null || excludeWebauthnKey === void 0 ? void 0 : excludeWebauthnKey.credentialId)
            ? [
                {
                    type: "public-key",
                    id: (0, cryptoUtils_1.base64ToBytesArray)(excludeWebauthnKey.credentialId),
                },
            ]
            : [],
        attestation: "direct",
    };
});
const createAssertionObjectData = (assertion) => {
    return {
        authenticatorAttachment: assertion.authenticatorAttachment,
        id: assertion.id,
        rawId: (0, cryptoUtils_1.bytesArrayToBase64)(assertion.rawId),
        response: {
            authenticatorData: (0, cryptoUtils_1.bytesArrayToBase64)(assertion.response.authenticatorData),
            clientDataJSON: (0, cryptoUtils_1.bytesArrayToBase64)(assertion.response.clientDataJSON),
            signature: (0, cryptoUtils_1.bytesArrayToBase64)(assertion.response.signature),
        },
        type: CREDENTIAL_TYPE,
    };
};
const createCredentialObjectData = (credential) => {
    return {
        id: credential.id,
        rawId: (0, cryptoUtils_1.bytesArrayToBase64)(credential.rawId),
        response: Object.assign(Object.assign({}, credential.response), { attestationObject: (0, cryptoUtils_1.bytesArrayToBase64)(credential.response.attestationObject), clientDataJSON: (0, cryptoUtils_1.bytesArrayToBase64)(credential.response.clientDataJSON) }),
        type: credential.type,
    };
};
const createKeyPair = (_a) => __awaiter(void 0, [_a], void 0, function* ({ parsedUser, reuseWebauthnKeyIfExists, }) {
    if (!(yield (0, exports.isWebAuthnAuthenticatorAvailable)())) {
        throw new Error("Passkey is not available on this device.");
    }
    let credentialId = "";
    let credentialForRegistration = {};
    try {
        const credential = (yield navigator.credentials.create({
            publicKey: yield getPublicKeyCredentialCreationOptions(parsedUser, reuseWebauthnKeyIfExists),
        }));
        if (!credential) {
            throw Error("Failed to generate key pair.");
        }
        credentialForRegistration = createCredentialObjectData(credential);
        credentialId = (0, cryptoUtils_1.bytesArrayToBase64)(credential.rawId);
    }
    catch (error) {
        logger_1.logger.error(error);
        if ((error === null || error === void 0 ? void 0 : error.name) === exports.REUSE_PASSKEY_ERROR) {
            logger_1.logger.debug("Reusing existing passkey");
            return reuseWebauthnKeyIfExists;
        }
        throw new Error("Failed to create key pair.");
    }
    return {
        credentialId,
        webAuthnRegistrationCredential: (0, cryptoUtils_1.bytesArrayToBase64)(new TextEncoder().encode(JSON.stringify(credentialForRegistration))),
    };
});
exports.createKeyPair = createKeyPair;
// Map to track cancellations per credentialId within a window
// If we detect too many consecutive cancellations for a credentialId, it's likely
// that the credential is not valid. To unstuck users, we will sign out the user.
// Note that this can happen too if the user decides to cancel too many times.
// TODO: Find a better method to detect `cancel operation` vs `missing credential`.
const cancellationMap = new Map();
const signRequest = (_b) => __awaiter(void 0, [_b], void 0, function* ({ hostname, method, path, timestampHeader, requestData, credentialId, }) {
    // Track authentication attempts
    try {
        const hashedRequest = yield (0, cryptoUtils_1.hashRequest)({
            hostname,
            method,
            path,
            timestampHeader,
            requestData,
        });
        const publicKeyCredentialRequestOptions = {
            challenge: hashedRequest,
            allowCredentials: [
                {
                    id: (0, cryptoUtils_1.base64ToBytesArray)(credentialId),
                    type: CREDENTIAL_TYPE,
                    transports: AVAILABLE_TRANSPORTS,
                },
            ],
            // No timeout has been added since we want the user to explicitly
            // cancel the operation.
        };
        const assertion = (yield navigator.credentials.get({
            publicKey: publicKeyCredentialRequestOptions,
        }));
        if (!assertion) {
            throw new Error("Failed to get key pair.");
        }
        // Remove entry if exists from cancellationMap on successful sign-in attempt
        cancellationMap.delete(credentialId);
        return (0, cryptoUtils_1.bytesArrayToBase64)(new TextEncoder().encode(JSON.stringify(createAssertionObjectData(assertion))));
    }
    catch (error) {
        console.error(error);
        let cancellationCount = cancellationMap.get(credentialId) || 0;
        // Check if another cancellation has occurred for the same credentialId
        if (cancellationCount >= 2) {
            // Sign out user if failing for 3rd time
            yield MetakeepAuth_1.MetakeepAuth.signOut();
            throw new Error(`Three consecutive cancellations detected for the credentialId.`);
        }
        else {
            // Setting cancellation occurred to true for this credentialId
            cancellationCount++;
            cancellationMap.set(credentialId, cancellationCount);
            throw new Error("User request denied or credentialId doesn't exist.");
        }
    }
});
exports.signRequest = signRequest;
// Check if biometrics is available
const isWebAuthnAuthenticatorAvailable = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (![
            window.PublicKeyCredential,
            navigator.credentials.get,
            navigator.credentials.create,
        ].every((func) => typeof func !== "undefined")) {
            return false;
        }
        return yield window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    }
    catch (error) {
        console.error("Error checking WebAuthn availability:", error);
        return false;
    }
});
exports.isWebAuthnAuthenticatorAvailable = isWebAuthnAuthenticatorAvailable;
