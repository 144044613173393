import { API_PATHS, baseApi } from "./baseApi";

export const createAppCall = async (
  requestBody,
  activeSharedAccount = null
) => {
  const body = { ...requestBody };
  if (activeSharedAccount) {
    body.requestHeader = {
      credential: {
        crossAccountAccessEmail: activeSharedAccount,
      },
    };
  }
  return await baseApi({ apiPath: API_PATHS.APP_CREATE, requestBody: body });
};

export const updateAppCall = async (
  requestBody,
  activeSharedAccount = null
) => {
  const body = { ...requestBody };
  if (activeSharedAccount) {
    body.requestHeader = {
      credential: {
        crossAccountAccessEmail: activeSharedAccount,
      },
    };
  }
  return await baseApi({ apiPath: API_PATHS.APP_UPDATE, requestBody: body });
};

export const completeAuth = async () => {
  return await baseApi({ apiPath: API_PATHS.COMPLETE_AUTH });
};

export const getApps = async ({ activeSharedAccount = null, ...rest }) => {
  const requestBody = {
    ...rest,
    ...(activeSharedAccount
      ? {
          requestHeader: {
            credential: {
              crossAccountAccessEmail: activeSharedAccount,
            },
          },
        }
      : {}),
  };
  return await baseApi({ apiPath: API_PATHS.APP_LIST, requestBody });
};

export const fetchAccountInfoApiCall = async () => {
  return await baseApi({ apiPath: API_PATHS.ACCOUNT_INFO });
};

export const manageAccount = async (data) => {
  return await baseApi({
    apiPath: API_PATHS.ACCOUNT_MANAGE,
    requestBody: data,
  });
};

export const getAppLogs = async (body) => {
  const { activeSharedAccount, ...rest } = body;
  const requestBody = {
    ...rest,
    ...(activeSharedAccount
      ? {
          requestHeader: {
            credential: {
              crossAccountAccessEmail: activeSharedAccount,
            },
          },
        }
      : {}),
  };
  return await baseApi({ apiPath: API_PATHS.APP_LOGS, requestBody });
};

export const getAppTransactions = async (body) => {
  const { activeSharedAccount, ...rest } = body;
  const requestBody = {
    ...rest,
    ...(activeSharedAccount
      ? {
          requestHeader: {
            credential: {
              crossAccountAccessEmail: activeSharedAccount,
            },
          },
        }
      : {}),
  };
  return await baseApi({ apiPath: API_PATHS.APP_TRANSACTIONS, requestBody });
};

export const getAppStats = async (body) => {
  const { activeSharedAccount, ...rest } = body;
  const requestBody = {
    ...rest,
    ...(activeSharedAccount
      ? {
          requestHeader: {
            credential: {
              crossAccountAccessEmail: activeSharedAccount,
            },
          },
        }
      : {}),
  };
  return await baseApi({ apiPath: API_PATHS.APP_STATS, requestBody });
};
