import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { UncontrolledTooltip } from "reactstrap";

export const ToolTip = ({ id, content }) => (
  <>
    <AiOutlineQuestionCircle id={id} />
    <UncontrolledTooltip
      popperClassName="tooltip-popper"
      target={id}
      placement="left"
      autohide={false}
    >
      {content}
    </UncontrolledTooltip>
  </>
);
