import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  ModalBody,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { getAppStats } from "../../../../api";
import styles from "./index.module.scss";
import clx from "classnames";
import { Loader } from "../../../../components";
import { StatsCharts } from "./components";
import { toast } from "react-toastify";
import { FiRefreshCw } from "react-icons/fi";
import { useSelector } from "react-redux";
import { selectActiveAccount } from "../../../../redux/slice/dashboardSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { AppTypeData, getNormalizedCost } from "../../../../utils";
import { APP_TYPE_BLOCKCHAIN, CHAIN_CONFIG } from "../../../../constants";
import { AppStats, ChartData } from "./components/StatsCharts";

dayjs.extend(utc);

const GRANULARITY_OPTIONS = [
  {
    value: "HOURLY",
    label: "Hourly (Last 48 hours)",
    chartAxisFormat: "DD-MMM HH:mm",
    infoText: "Data is updated every 10m",
  },
  {
    value: "DAILY",
    label: "Daily (Last 14 days)",
    chartAxisFormat: "DD-MMM HH:mm",
    infoText: "Data is updated every 1h",
  },
  {
    value: "MONTHLY",
    label: "Monthly (Last 12 months)",
    chartAxisFormat: "YYYY-MMM",
    infoText: "Data is updated every day",
  },
];

const NO_DATA_TEXT_MAP = {
  INITIAL: "Loading...",
  NO_DATA: "No data available",
};

interface AggregatedApiStats {
  timestampUsec: string;
  uniqueUsers: number;
  totalCalls: number;
  totalTransactions: number;
  totalCost: string;
}

interface AppStatsContainerProps {
  appId: string;
  appTypeData: AppTypeData;
}

export const AppStatsContainer = ({
  appId,
  appTypeData,
}: AppStatsContainerProps) => {
  const activeSharedAccount = useSelector(selectActiveAccount);
  const [isLoading, toggleLoading] = useState(false);
  const [appStats, setAppStats] = useState<AppStats>({
    totalTransactionsSeries: [],
    totalCallsSeries: [],
    uniqueUsersSeries: [],
    categories: [],
    totalCalls: null,
    totalTransactions: null,
    totalCost: null,
    totalCostSeries: [],
  });
  const [granularity, setGranularity] = useState(GRANULARITY_OPTIONS[0]);
  const [isActive, setActive] = useState(false);
  const [noDataText, setNoDataText] = useState(NO_DATA_TEXT_MAP.INITIAL);

  const onToggle = () => {
    setActive(!isActive);
  };

  const onRefreshStats = () => {
    setAppStats({
      totalTransactionsSeries: [],
      totalCallsSeries: [],
      uniqueUsersSeries: [],
      categories: [],
      totalCalls: null,
      totalTransactions: null,
      totalCost: null,
      totalCostSeries: [],
    });
    getAppStatsCall();
  };

  const getAppStatsCall = useCallback(async () => {
    try {
      toggleLoading(true);
      const { value, chartAxisFormat = "" } = granularity || {};
      const {
        stats = [],
      }: {
        stats: AggregatedApiStats[];
      } = await getAppStats({
        appId,
        granularity: value,
        activeSharedAccount,
      });

      const {
        callSeries,
        uniqueUsersSeries,
        transactionSeries,
        totalCostSeries,
        categories,
        totalCalls,
        totalTransactions,
        totalCost,
      } = stats.reduce<{
        callSeries: ChartData;
        transactionSeries: ChartData;
        uniqueUsersSeries: ChartData;
        totalCostSeries: ChartData;
        categories: string[];
        totalCalls: number;
        totalTransactions: number;
        totalCost: number;
      }>(
        (acc, stat) => {
          const {
            totalCalls = 0,
            uniqueUsers = 0,
            timestampUsec = 0,
            totalTransactions = 0,
            totalCost = "0",
          } = stat;
          acc.totalCalls += totalCalls;
          acc.callSeries.data.push(totalCalls);
          acc.totalTransactions += totalTransactions;
          acc.transactionSeries.data.push(totalTransactions);
          acc.uniqueUsersSeries.data.push(uniqueUsers);
          acc.categories.push(
            // Since data aggregation is done in UTC timezone,
            // for monthly we show timestamp in UTC. Otherwise, for timezones
            // that are behind UTC(e.g. PST) will show the previous month instead.
            granularity?.value === "MONTHLY"
              ? dayjs.utc(Number(timestampUsec) / 1000).format(chartAxisFormat)
              : dayjs(Number(timestampUsec) / 1000).format(chartAxisFormat)
          );
          acc.totalCostSeries.data.push(
            appTypeData.appType === APP_TYPE_BLOCKCHAIN
              ? (getNormalizedCost(
                  totalCost,
                  appTypeData.blockchain.chainId
                ) as number)
              : 0
          );
          acc.totalCost +=
            appTypeData.appType === APP_TYPE_BLOCKCHAIN
              ? (getNormalizedCost(
                  totalCost,
                  appTypeData.blockchain.chainId
                ) as number)
              : 0;
          return acc;
        },
        {
          callSeries: {
            name: "Total Calls",
            data: [],
          },
          transactionSeries: {
            name: "Total Transactions",
            data: [],
          },
          uniqueUsersSeries: {
            name: "Unique Users",
            data: [],
          },
          totalCostSeries: {
            name: `Total Cost (${
              appTypeData.appType === APP_TYPE_BLOCKCHAIN
                ? CHAIN_CONFIG[appTypeData.blockchain.chainId]?.symbol || ""
                : ""
            })`,
            data: [],
          },
          categories: [],
          totalCalls: 0,
          totalTransactions: 0,
          totalCost: 0,
        }
      );

      setAppStats({
        totalCallsSeries: [callSeries],
        totalTransactionsSeries: [transactionSeries],
        uniqueUsersSeries: [uniqueUsersSeries],
        categories,
        totalCalls,
        totalTransactions,
        totalCost,
        totalCostSeries: [totalCostSeries],
      });
    } catch (err) {
      console.error(err);
      toast.error("Failed to fetch app stats, please try again");
    } finally {
      toggleLoading(false);
      setNoDataText(NO_DATA_TEXT_MAP.NO_DATA);
    }
  }, [granularity, appId, activeSharedAccount, appTypeData]);

  useEffect(() => {
    getAppStatsCall();
  }, [getAppStatsCall]);

  const handleGranularityChange = (value: typeof GRANULARITY_OPTIONS[0]) => {
    if (value.value === granularity?.value) return;
    setGranularity(value);
  };

  const granularityOptions = GRANULARITY_OPTIONS.map((option) => {
    const { value, label } = option;
    return (
      <DropdownItem
        key={value}
        onClick={() => {
          handleGranularityChange(option);
        }}
      >
        {label}
      </DropdownItem>
    );
  });

  const { label } = granularity || {};

  return (
    <ModalBody className={styles.stat_modal_container}>
      <div className={styles.app_stats_header}>
        <div className={styles.logs_info}>
          <BsFillInfoCircleFill /> Time is displayed in your local timezone{" "}
          <br></br>
          <BsFillInfoCircleFill /> {granularity?.infoText}
        </div>

        <div className={styles.app_stats_control}>
          <Dropdown id="granularitySelect" isOpen={isActive} toggle={onToggle}>
            <DropdownToggle
              caret
              className="w-100 d-flex justify-content-between align-items-center"
              color=""
            >
              {label ? label : GRANULARITY_OPTIONS[0].label}
            </DropdownToggle>
            <DropdownMenu>{granularityOptions}</DropdownMenu>
          </Dropdown>
          <Button
            className={clx("primary-btn", "clr-transp", styles.refresh_control)}
            onClick={onRefreshStats}
          >
            <FiRefreshCw /> Refresh Stats
          </Button>
        </div>
      </div>
      <StatsCharts
        appStats={appStats}
        noDataText={noDataText}
        appTypeData={appTypeData}
      />
      {isLoading && <Loader />}
      <p className={styles.logs_note}></p>
    </ModalBody>
  );
};
