import { useCallback, useEffect, useState } from "react";
import { Button, Collapse } from "reactstrap";
import { toast } from "react-toastify";
import { MetaKeep } from "metakeep";
import { CryptoUtils } from "frontend-utils";
import { EmptyState, Loader } from "../../../components";
import { CreateAppModal } from "./CreateAppModal";
import { AppCard } from "./AppCard";
import { DetailedAppModal, ParsedAppWithNewApiKey } from "./DetailedAppModal";
import {
  APP_CREATED_MSG,
  APP_UPDATED_MSG,
  ERROR_MSG,
  CHAIN_ID_FOR_NETWORK,
  IDENTITY_FOR_NETWORK,
  SDK_ENV,
  Messages,
  APP_TYPE_CRYPTOGRAPHY,
  APP_TYPE_IDENTITY,
  APP_TYPE_BLOCKCHAIN,
  TAB_INFO,
  TAB_KEYS,
  CHAIN_ID_TYPES,
  NETWORK_TYPES,
  ENVIRONMENT_TYPES,
  IDENTITY_PROTOCOL_TYPES,
  CRYPTOGRAPHY_ALGORITHMS_TYPES,
  IDENTITY_TYPES,
  TAB_TYPES,
} from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import {
  selectActiveAccount,
  fetchAppList,
  selectDashbordApiLoading,
  selectApplistError,
  selectActiveAppsListMemoized,
  selectDisabledAppsListMemoized,
} from "../../../redux/slice/dashboardSlice";

import { createAppCall, updateAppCall } from "../../../api";
import {
  getAppUpdateRequestBody,
  getAppDataFromLegacyOrUpdatedFields,
  AppDataFromBackendWithAddedAppTypes,
  UpdatedAppValues,
} from "../../../utils";
import styles from "./index.module.scss";
import { BsChevronUp } from "react-icons/bs";
import clx from "classnames";
import { ExtractableKeyPair } from "frontend-utils/cryptoUtils";

type CreateNewAppData = {
  name: string;
  rawAppData:
    | {
        type: typeof APP_TYPE_BLOCKCHAIN;
        network: NETWORK_TYPES;
        environment: ENVIRONMENT_TYPES;
      }
    | {
        type: typeof APP_TYPE_CRYPTOGRAPHY;
        algorithm: CRYPTOGRAPHY_ALGORITHMS_TYPES;
        environment: ENVIRONMENT_TYPES;
      }
    | {
        type: typeof APP_TYPE_IDENTITY;
        identityType: IDENTITY_TYPES;
        environment: ENVIRONMENT_TYPES;
      };
};

type CreateNewAppRequest = {
  name: string;
  api_key: string;
  signed_hello_message: string;
  blockchain?: {
    chainId?: CHAIN_ID_TYPES;
  };
  cryptography?: {
    algorithm: CRYPTOGRAPHY_ALGORITHMS_TYPES;
    environment: ENVIRONMENT_TYPES;
  };
  identity?: {
    protocol: IDENTITY_PROTOCOL_TYPES;
  };
};

//TODO: Move state to redux
export const AppDashboard = () => {
  const dispatch = useDispatch();
  const activeSharedAccount: string | null = useSelector(selectActiveAccount);
  const [createModalOpen, toggleCreateModal] = useState(false);
  const [selectedApp, setSelectedApp] = useState<ParsedAppWithNewApiKey | null>(
    null
  );
  const isLoading: boolean = useSelector(selectDashbordApiLoading);
  const [appModalState, setAppModalState] = useState<{
    isOpen: boolean;
    currentTab: TAB_TYPES;
  }>({
    isOpen: false,
    currentTab: TAB_INFO,
  });

  const fetchUserApps = useCallback(async () => {
    // TODO: Remove ts-ignore and fix the type
    // @ts-ignore
    return await dispatch(fetchAppList({ activeSharedAccount })).unwrap();
  }, [activeSharedAccount, dispatch]);

  useEffect(() => {
    fetchUserApps();
  }, [fetchUserApps]);

  const createNewApp = async ({ name, rawAppData }: CreateNewAppData) => {
    const { publicKey, privateKey } = (await CryptoUtils.generateKeyPair(
      true
    )) as ExtractableKeyPair;
    const signedMsg = await CryptoUtils.signMessage(
      "Hello",
      privateKey,
      publicKey
    );
    let requestBody: CreateNewAppRequest = {
      name,
      api_key: publicKey,
      signed_hello_message: signedMsg,
    };
    if (rawAppData.type === APP_TYPE_BLOCKCHAIN) {
      requestBody = {
        ...requestBody,
        blockchain: {
          chainId:
            CHAIN_ID_FOR_NETWORK[rawAppData.network][rawAppData.environment],
        },
      };
    }
    if (rawAppData.type === APP_TYPE_CRYPTOGRAPHY) {
      requestBody = {
        ...requestBody,
        cryptography: {
          algorithm: rawAppData.algorithm,
          environment: rawAppData.environment ?? undefined,
        },
      };
    }
    if (rawAppData.type === APP_TYPE_IDENTITY) {
      requestBody = {
        ...requestBody,
        identity: {
          protocol:
            IDENTITY_FOR_NETWORK[rawAppData.identityType][
              rawAppData.environment
            ],
        },
      };
    }
    try {
      // TODO: Remove ts-ignore and fix the type
      // @ts-ignore
      await createAppCall(requestBody, activeSharedAccount);
      toast.success(APP_CREATED_MSG, {
        toastId: "app-created",
      });
    } catch (err) {
      toast.error(ERROR_MSG, {
        toastId: "app-error",
      });
      return;
    }
    toggleCreateModal(false);
    const updatedList: AppDataFromBackendWithAddedAppTypes[] =
      await fetchUserApps();
    const tempApp = {
      ...updatedList[0],
      // Since the API key sent by the backend will be truncated,
      // we override it with the original key to allow the user to copy it.
      apiKeysInfo: {
        apiKeys: [
          { apiKey: publicKey, createdAtMillis: Date.now().toString() },
        ],
      },
    };

    setSelectedApp({
      ...getAppDataFromLegacyOrUpdatedFields(tempApp),
      privateKey: privateKey,
    });
    setAppModalState({ isOpen: true, currentTab: TAB_KEYS });
  };

  const updateApp = async (values: UpdatedAppValues) => {
    if (!selectedApp) return;

    const body = getAppUpdateRequestBody(selectedApp, values);
    try {
      // TODO: Remove ts-ignore and fix the type
      // @ts-ignore
      await updateAppCall(body, activeSharedAccount);
      await fetchUserApps();
      toast.success(APP_UPDATED_MSG, {
        toastId: "app-updated",
      });
      setAppModalState({ isOpen: false, currentTab: TAB_INFO });
    } catch (err: any) {
      const { response = {} } = err || {};
      const status = response?.data?.status;
      const message = Messages[status as keyof typeof Messages] || ERROR_MSG;

      toast.error(message, {
        toastId: "app-error",
      });
    }
  };

  const onSaveApp = async (
    values: CreateNewAppData,
    disableBtns: (val: boolean) => void
  ) => {
    try {
      disableBtns(true);
      await createNewApp(values);
      disableBtns(false);
    } catch (err) {
      console.log(err);
      disableBtns(false);
      toast.error(ERROR_MSG, {
        toastId: "app-error",
      });
    }
  };

  const cardActionHandler = (
    elm: AppDataFromBackendWithAddedAppTypes,
    tabToOpen: TAB_TYPES
  ) => {
    setSelectedApp(getAppDataFromLegacyOrUpdatedFields({ ...elm }));
    setAppModalState({ isOpen: true, currentTab: tabToOpen });
  };

  const onCreateApp = () => {
    setSelectedApp(null);
    toggleCreateModal(true);
  };
  const { isOpen, currentTab } = appModalState;
  return (
    <>
      <div className="dash-cta-wrapper">
        <div className="dash-name">Apps</div>
        <Button onClick={onCreateApp} className={"primary-btn clr-transp"}>
          + Create App
        </Button>
      </div>
      <ActiveAppsList
        cardActionHandler={cardActionHandler}
        toggleCreateModal={toggleCreateModal}
      />
      <DisabledAppsList cardActionHandler={cardActionHandler} />
      {createModalOpen && (
        <CreateAppModal
          onClose={() => {
            toggleCreateModal(false);
          }}
          isOpen={createModalOpen}
          onSubmit={onSaveApp}
        />
      )}
      {isOpen && selectedApp && (
        <DetailedAppModal
          isOpen={isOpen}
          selectedApp={selectedApp}
          currentTabProp={currentTab}
          onUpdateHandler={updateApp}
          onClose={() => {
            setSelectedApp(null);
            setAppModalState((prevState) => ({
              ...prevState,
              isOpen: false,
            }));
          }}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

const NoAppFound = ({
  onClick,
  hasDisabledApps,
}: {
  onClick: () => void;
  hasDisabledApps: boolean;
}) => {
  if (hasDisabledApps)
    return (
      <div className="empty-state-sub">
        <span onClick={onClick}>Click here</span> to create a new app.
      </div>
    );
  return (
    <div className="empty-state-sub">
      Let's get you started! <span onClick={onClick}>Click here</span> to begin.
    </div>
  );
};

const OnErrorMessage = () => {
  const onReloadPage = () => {
    window.location.reload();
  };
  return (
    <div className="empty-state-sub">
      Please <span onClick={onReloadPage}>refresh</span> the page to try again.
    </div>
  );
};

const DisabledAppsList = ({
  cardActionHandler,
}: {
  cardActionHandler: (
    elm: AppDataFromBackendWithAddedAppTypes,
    tabToOpen: TAB_TYPES
  ) => void;
}) => {
  const disabledApps: AppDataFromBackendWithAddedAppTypes[] = useSelector(
    selectDisabledAppsListMemoized
  );
  const [showDisabledApps, toggleDisabledApps] = useState(false);
  const disableAppChildren = disabledApps.map((elm, idx) => {
    const { name } = elm;

    return (
      <AppCard
        onClick={() => cardActionHandler(elm, TAB_INFO)}
        key={idx}
        appName={name}
        appTypeData={elm}
        isDisabled={true}
        onViewWallet={() => {}}
        onCardEditAction={(tabToOpen) => cardActionHandler(elm, tabToOpen)}
      />
    );
  });
  if (disabledApps.length === 0) return null;
  return (
    <>
      <hr />
      <div className={styles.apps_collapse_toggler}>
        <Button
          onClick={() => toggleDisabledApps((prevState) => !prevState)}
          className={clx(
            styles.apps_collapse_button,
            !showDisabledApps && styles.closed
          )}
        >
          <span>Disabled Apps</span>
          <span className={styles.app_count}>{` ${disabledApps.length}`}</span>
          <BsChevronUp className={styles.chev_icon} />
        </Button>
      </div>
      <Collapse isOpen={showDisabledApps}>
        <div className="main-content">{disableAppChildren}</div>
      </Collapse>
    </>
  );
};

const ActiveAppsList = ({
  cardActionHandler,
  toggleCreateModal,
}: {
  cardActionHandler: (
    elm: AppDataFromBackendWithAddedAppTypes,
    tabToOpen: TAB_TYPES
  ) => void;
  toggleCreateModal: (val: boolean) => void;
}) => {
  const activeApps: AppDataFromBackendWithAddedAppTypes[] = useSelector(
    selectActiveAppsListMemoized
  );
  const disabledApps: AppDataFromBackendWithAddedAppTypes[] = useSelector(
    selectDisabledAppsListMemoized
  );
  const isLoading: boolean = useSelector(selectDashbordApiLoading);
  const applistErr: string = useSelector(selectApplistError);
  const onViewUserWallet = (appId: string) => {
    const sdk = new MetaKeep({
      environment: SDK_ENV,
      appId,
      internalOptions: {
        // This is needed to prevent auth frame from using
        // cached APP UI data.
        // Otherwise, the latest app changes won't be visible.
        bypassAppDataCache: true,
        // Prefetch is not needed since we aren't initializing the SDK in advance.
        // Else, prefetch slows down the auth frame widget.
        skipPrefetch: true,
      },
    });

    // TODO: Remove ts-ignore and replace with getWallet call from SDK
    // @ts-ignore
    sdk.loginUser();
  };

  const children = activeApps.map((elm, idx) => {
    const { name = "" } = elm;

    return (
      <AppCard
        onClick={() => cardActionHandler(elm, TAB_INFO)}
        key={idx}
        appTypeData={elm}
        appName={name}
        onViewWallet={() => {
          onViewUserWallet(elm.appId);
        }}
        onCardEditAction={(tabToOpen) => cardActionHandler(elm, tabToOpen)}
        isDisabled={false}
      />
    );
  });

  const hasDisabledApps = disabledApps.length > 0;

  const titleString = applistErr
    ? "Failed to fetch apps"
    : hasDisabledApps
    ? "All apps are disabled"
    : "No Application Found";

  const emptyStateChildren = applistErr ? (
    <OnErrorMessage />
  ) : (
    <NoAppFound
      onClick={() => toggleCreateModal(true)}
      hasDisabledApps={hasDisabledApps}
    />
  );

  if (!isLoading && activeApps.length === 0)
    return (
      <div
        className={clx("empty-state-wrapper", hasDisabledApps && "half-height")}
      >
        <EmptyState title={titleString} children={emptyStateChildren} />
      </div>
    );

  return <div className="main-content">{children}</div>;
};
