import { useState, useEffect, useCallback } from "react";
import { getAppLogs } from "../../../../api";
import {
  Button,
  Dropdown,
  DropdownToggle,
  ModalBody,
  Table,
  DropdownItem,
  DropdownMenu,
  UncontrolledTooltip,
} from "reactstrap";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Loader } from "../../../../components";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FiRefreshCw } from "react-icons/fi";
import styles from "./index.module.scss";
import clx from "classnames";
import { toast } from "react-toastify";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { selectActiveAccount } from "../../../../redux/slice/dashboardSlice";
dayjs.extend(relativeTime);

const PAGE_SIZES = [10, 20, 30];
const LOGS_TABLE_HEADERS = [
  {
    name: "Path",
    description: "HTTP request path",
    id: "apiPath",
    customCss: styles.path_header,
  },
  {
    name: "Method",
    description: "HTTP request method",
    id: "apiMethod",
    customCss: styles.method_header,
  },
  {
    name: "Auth Type",
    description: (
      <ul>
        Authentication method. It can be:
        <li>
          <b>API_KEY</b>: Backend API call authenticated using API key.
        </li>
        <li>
          <b>APP_ID</b>: Frontend SDK call authenticated using app id.
        </li>
        <li>
          <b>USER_CONSENT</b>: Frontend SDK operation(getConsent, signMessage,
          signTransaction etc.) authenticated by taking the end user consent.
        </li>
      </ul>
    ),
    id: "authenticationType",
    customCss: styles.auth_type_header,
  },
  {
    name: "Status",
    description: "HTTP response status",
    id: "apiHTTPStatus",
    customCss: styles.status_header,
  },
  {
    name: "Time",
    description: "HTTP request time",
    id: "apiCalledTimeStamp",
    customCss: styles.time_header,
  },
];

export const AppLogsContainer = ({ appId }) => {
  const [logTableMap, setLogTableMap] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, toggleLoading] = useState(false);
  const [isActive, setActive] = useState(false);
  const [hasError, setError] = useState(false);
  const activeSharedAccount = useSelector(selectActiveAccount);

  const onToggle = () => {
    setActive(!isActive);
  };

  const fetchAppLogs = useCallback(
    async (paginationToken = null) => {
      try {
        setError(false);
        toggleLoading(true);
        const body = {
          appId,
          pageSize,
          paginationToken,
          activeSharedAccount,
        };
        const response = await getAppLogs(body);

        setLogTableMap((prevState) => {
          const currentPageNumber = Object.keys(prevState).length + 1;
          return {
            ...prevState,
            [currentPageNumber]: response,
          };
        });
        if (paginationToken) setActivePage((prevState) => prevState + 1);
        else setActivePage(1);
      } catch (err) {
        console.error(err);
        toast.error("Failed to fetch logs, please try again");
        setError(true);
      } finally {
        toggleLoading(false);
      }
    },
    [appId, pageSize, activeSharedAccount]
  );

  useEffect(() => {
    fetchAppLogs();
  }, [fetchAppLogs]);

  const onNextPageClick = () => {
    if (logTableMap[activePage + 1]) {
      setActivePage((prev) => prev + 1);
    } else {
      fetchAppLogs(logTableMap[activePage].paginationToken);
    }
  };

  const onBackPageClick = () => {
    if (activePage === 1) return;
    setActivePage(activePage - 1);
  };
  const handlePageSizeChange = (size) => {
    setLogTableMap({});
    setPageSize(size);
  };

  const onRefreshLogs = () => {
    setLogTableMap({});
    fetchAppLogs();
  };

  const pageSizeOptions = PAGE_SIZES.map((size) => (
    <DropdownItem key={size} onClick={() => handlePageSizeChange(size)}>
      {size}
    </DropdownItem>
  ));
  const currentPage = logTableMap[activePage] || {};
  const logs = currentPage?.logs || [];
  const nextPageExists =
    logTableMap[activePage + 1] || currentPage?.paginationToken;
  const pages = Object.keys(logTableMap);
  return (
    <ModalBody className={styles.log_table_container}>
      <div className={styles.log_controls}>
        <Dropdown id="pageSizeSelect" isOpen={isActive} toggle={onToggle}>
          <DropdownToggle
            caret
            className="w-100 d-flex justify-content-between align-items-center"
            color=""
            disabled={hasError || logs.length === 0}
          >
            {`Page Size: ${pageSize}`}
          </DropdownToggle>
          <DropdownMenu>{pageSizeOptions}</DropdownMenu>
        </Dropdown>
        <Button
          className={clx("primary-btn", "clr-transp", styles.refresh_control)}
          onClick={onRefreshLogs}
        >
          <FiRefreshCw /> Refresh logs
        </Button>
      </div>
      <LogsTable logs={logs} isLoading={isLoading} hasError={hasError} />

      <PageinationControls
        onNextPageClick={onNextPageClick}
        onBackPageClick={onBackPageClick}
        hasNext={nextPageExists}
        canGoBack={activePage > 1}
        pages={pages}
        activePage={activePage}
        setActivePage={setActivePage}
      />

      {isLoading && <Loader />}
    </ModalBody>
  );
};

const LogsTable = ({ logs = [], isLoading, hasError }) => {
  if (!isLoading && logs.length === 0)
    return <LogsTableEmptyState hasError={hasError} />;

  const getStatusCodeColor = (status) => {
    if (status >= 200 && status < 300) return "green";
    else if (status >= 400 && status < 500) return "orange";
    else return "red";
  };
  const tableRows = (logs || []).map((log, idx) => {
    const { authType = "", request = {}, response = {}, timestampUsec } = log;
    const { status } = response;
    const { path = "", method = "" } = request;
    const statusColor = getStatusCodeColor(status);
    return (
      <tr className={styles.table_data_row} key={idx}>
        <td>{path}</td>
        <td>{method}</td>
        <td>{authType}</td>
        <td style={{ color: statusColor }}>{status}</td>
        <td>{timestampUsec ? dayjs(timestampUsec / 1000).fromNow() : "-"}</td>
      </tr>
    );
  });
  const tableHeaders = LOGS_TABLE_HEADERS.map((header) => (
    <TableHead key={header.id} {...header} />
  ));
  return (
    <Table bordered responsive hover>
      <thead>
        <tr className={styles.table_header_row}>{tableHeaders}</tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </Table>
  );
};

const PageinationControls = ({
  onNextPageClick,
  onBackPageClick,
  hasNext,
  canGoBack,
  pages,
  activePage,
  setActivePage,
}) => {
  const pageButtons = pages.map((page) => {
    const isActive = parseInt(page) === activePage;
    return (
      <Button
        key={page}
        className={clx(
          styles.pagination_button,
          styles.number_button,
          isActive && styles.active_page
        )}
        onClick={() => setActivePage(parseInt(page))}
      >
        {page}
      </Button>
    );
  });
  return (
    <div className={styles.pagination_cta}>
      <Button
        disabled={!canGoBack}
        onClick={onBackPageClick}
        className={styles.pagination_button}
      >
        <IoIosArrowBack />
      </Button>
      {pageButtons}
      <Button
        onClick={onNextPageClick}
        className={styles.pagination_button}
        disabled={!hasNext}
      >
        <IoIosArrowForward />
      </Button>
    </div>
  );
};

const LogsTableEmptyState = ({ hasError = false }) => {
  const message = hasError ? "Failed to fetch logs" : "No logs found";
  return <div className={styles.table_empty_state}>{message}</div>;
};

export const TableHead = ({ name, description, id, customCss }) => {
  return (
    <th className={customCss}>
      <span className={styles.log_head_value}>
        {name}
        <AiOutlineQuestionCircle id={id} />
        <UncontrolledTooltip
          autohide={false}
          popperClassName="tooltip-popper"
          target={id}
        >
          {description}
        </UncontrolledTooltip>
      </span>
    </th>
  );
};
