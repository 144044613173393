import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { fetchAccountInfoApiCall, getApps } from "../../api";
import {
  APP_TYPE_BLOCKCHAIN,
  APP_TYPE_CRYPTOGRAPHY,
  APP_TYPE_IDENTITY,
  CUSTOM_CSS_FEATURE,
  DEFAULT_USER_WALLET_FONT,
  DISABLED,
  ENABLED,
} from "../../constants";
import { toast } from "react-toastify";
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    sharedAccounts: [],
    accountSharedWith: [],
    activeSharedAccount: null,
    accountKeys: [],
    isLoading: false,
    error: null,
    allowedFeatures: [],
    allowedFonts: [],
    appsDashboard: {
      isLoading: false,
      error: null,
      apps: [],
    },
  },
  reducers: {
    setActiveAccount: (state, action) => {
      state.activeSharedAccount = action.payload;
    },
    deleteAccountSharedWithAtIndex: (state, action) => {
      state.accountSharedWith.splice(action.payload, 1);
    },
    setDashboardLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAccountInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAccountInfo.fulfilled, (state, action) => {
      state.sharedAccounts =
        action.payload?.devEmailsAccountsSharedWithMe || [];
      state.accountSharedWith =
        action.payload?.devEmailsWithAccessToMyAccount || [];
      state.accountKeys = action.payload?.accountKeys || [];
      state.allowedFeatures = action.payload?.allowedFeatures || [];
      state.allowedFonts = action.payload?.allowedFonts || [];
      state.isLoading = false;
    });
    builder.addCase(fetchAccountInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchAppList.pending, (state) => {
      state.appsDashboard.isLoading = true;
      state.appsDashboard.error = null;
    });
    builder.addCase(fetchAppList.fulfilled, (state, action) => {
      state.appsDashboard.apps = action.payload || [];
      state.appsDashboard.isLoading = false;
      state.appsDashboard.error = null;
    });
    builder.addCase(fetchAppList.rejected, (state, action) => {
      state.appsDashboard.isLoading = false;
      state.appsDashboard.error = "please refresh the page to try again";
      toast.error("Failed to fetch apps, please try again");
    });
  },
});

// Actions
export const {
  setActiveAccount,
  deleteAccountSharedWithAtIndex,
  setDashboardLoading,
} = dashboardSlice.actions;

// Thunks
export const fetchAccountInfo = createAsyncThunk(
  "dashboard/fetchAccountInfo",
  async () => {
    const {
      devEmailsAccountsSharedWithMe = [],
      devEmailsWithAccessToMyAccount = [],
      accountKeys = [],
      allowedFeatures = [],
      allowedUserWalletCustomFonts = [],
    } = await fetchAccountInfoApiCall();
    return {
      devEmailsAccountsSharedWithMe,
      devEmailsWithAccessToMyAccount,
      accountKeys,
      allowedFeatures,
      allowedFonts: allowedUserWalletCustomFonts.sort((a) =>
        a.fontName === DEFAULT_USER_WALLET_FONT ? -1 : 1
      ),
    };
  },
  {
    condition: (_, { getState }) => {
      // Prevents multiple calls to the same API
      // Fetch Account Info is called at multiple levels when the app is loaded
      // If a user is already at settings dashboard and refreshes the page then this api could be called twice
      // as it is called by Dashboard parent and SettingsDashboard child
      // This condition prevents the second call
      const { dashboard } = getState();
      if (dashboard.isLoading) return false;
    },
  }
);

export const fetchAppList = createAsyncThunk(
  "dashboard/fetchAppList",
  async (body = {}) => {
    const { apps } = await getApps({ ...body });
    const appsWithType = apps.map((app) => {
      if (app.blockchain) {
        return {
          ...app,
          appType: APP_TYPE_BLOCKCHAIN,
        };
      }
      if (app.cryptography) {
        return {
          ...app,
          appType: APP_TYPE_CRYPTOGRAPHY,
        };
      }
      if (app.identity) {
        return {
          ...app,
          appType: APP_TYPE_IDENTITY,
        };
      }
      return app;
    });
    return appsWithType;
  }
);

// Selectors
export const selectSharedAccounts = (state) => state.dashboard.sharedAccounts;
export const selectAccountSharedWith = (state) =>
  state.dashboard.accountSharedWith;
export const selectActiveAccount = (state) =>
  state.dashboard.activeSharedAccount;

export const selectNetworkStatus = (state) => state.dashboard.isLoading;
export const selectAccountKeys = (state) => state.dashboard.accountKeys;

export const selectAppsList = (state) => state.dashboard.appsDashboard.apps;

export const selectApplistError = (state) =>
  state.dashboard.appsDashboard.error;

export const selectDashbordApiLoading = (state) =>
  state.dashboard.isLoading || state.dashboard.appsDashboard.isLoading;

// Memoized Selectors
export const selectActiveAppsListMemoized = createSelector(
  selectAppsList,
  (appsList) => appsList.filter((app) => app.state === ENABLED)
);

export const selectDisabledAppsListMemoized = createSelector(
  selectAppsList,
  (appsList) => appsList.filter((app) => app.state === DISABLED)
);

// Feature Selectors

export const selectCustomCssEnabled = (state) =>
  state.dashboard.allowedFeatures.includes(CUSTOM_CSS_FEATURE);

export const isFeatureEnabled = (feature) =>
  createSelector(
    (state) => state.dashboard.allowedFeatures,
    (allowedFeatures) => allowedFeatures.includes(feature)
  );

export const selectAllowedFonts = (state) => state.dashboard.allowedFonts;

export default dashboardSlice.reducer;
