import clx from "classnames";
import { useEffect, useState } from "react";
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CustomInput, Loader } from "../../../../components";
import { useForm } from "../../../../hooks";
import styles from "./index.module.scss";
import { ChainDropdown } from "./components/ChainDropdown";
import { CreateAppMenuDropdown } from "./components/CreateAppMenuDropdown";
import {
  APP_TYPE_CRYPTOGRAPHY,
  APP_TYPE_BLOCKCHAIN,
  APP_TYPE_IDENTITY,
  CRYPTOGRAPHY_ALGORITHMS_CONFIG,
  ENVIRONMENTS_MAP,
  APP_TYPES_MAP,
  ARBITRUM_CHAIN_NAME,
  ARBITRUM_ENVIRONMENTS_MAP,
  IDENTITY_MAP,
} from "../../../../constants";

import {
  environmentTooltipContent,
  appTypeTooltipContent,
  cryptographyAlgorithmTooltipContent,
  identityProtocolTooltipContent,
} from "./constants";

export const CreateAppModal = ({ isOpen, onClose, onSubmit }) => {
  const [chainsDropdwnOpen, toggleChainsDropdown] = useState(false);
  const [envDropdwnOpen, toggleEnvDropdown] = useState(false);
  const [appTypeDropdownOpen, toggleAppTypeDropdown] = useState(false);
  const [cryptographyKeyDropdownOpen, toggleCryptographyKeyDropdown] =
    useState(false);
  const [identityTypeDropdownOpen, toggleIdentityTypeDropdown] =
    useState(false);
  const { onChangeHandler, values, errors, checkFormValidity, setDirectValue } =
    useForm({
      name: "",
      rawAppData: {
        type: "",
        algorithm: "",
        network: "",
        environment: "",
        identityType: "",
      },
    });

  const onChainsDropdownToggle = () =>
    toggleChainsDropdown((prevState) => !prevState);

  const onEnvDropdownToggle = () =>
    toggleEnvDropdown((prevState) => !prevState);

  const onAppTypeDropdownToggle = () =>
    toggleAppTypeDropdown((prevState) => !prevState);

  const onCryptographyKeyDropdownToggle = () =>
    toggleCryptographyKeyDropdown((prevState) => !prevState);

  const onIdentityTypeDropdownToggle = () =>
    toggleIdentityTypeDropdown((prevState) => !prevState);

  const {
    name: nameErr,
    environment: environmentErr,
    network: networkErr,
    algorithm: algorithmErr,
    identityType: identityTypeErr,
    type: typeErr,
  } = errors;
  const {
    name = "",
    rawAppData = {
      type: "",
      algorithm: "",
      network: "",
      environment: "",
      identityType: "",
    },
  } = values;

  useEffect(() => {
    if (!rawAppData?.type) {
      setDirectValue("rawAppData", {
        ...rawAppData,
        type: APP_TYPE_BLOCKCHAIN,
      });
    }
  }, [rawAppData, setDirectValue]);

  const [inProgress, setProgress] = useState(false);

  const onCTA = () => {
    const isValid = checkFormValidity();
    if (!isValid) return;
    onSubmit(values, setProgress);
  };
  return (
    <Modal centered className="app-modal" isOpen={isOpen}>
      <ModalHeader>
        <div className="app-modal-title">Create App</div>
      </ModalHeader>
      <ModalBody className={clx("app-modal-body", styles.create_app_modal)}>
        <CustomInput
          id="name"
          label="Application Name"
          placeholder="App Name"
          error={nameErr}
          onChangeHandler={onChangeHandler}
          value={name}
          required
        />
        <CreateAppMenuDropdown
          dropdownOpen={appTypeDropdownOpen}
          onDropdownToggle={onAppTypeDropdownToggle}
          handleChange={setDirectValue}
          selectedValue={rawAppData.type}
          error={typeErr}
          itemsMap={APP_TYPES_MAP}
          label="App Type"
          placeholder="Select App Type"
          keyToChange="rawAppData"
          keyToAdd="type"
          rawAppData={rawAppData}
          tooltipContent={appTypeTooltipContent}
          tooltipId="tooltip-app-type"
        />
        {rawAppData.type === APP_TYPE_CRYPTOGRAPHY ? (
          <div>
            <CreateAppMenuDropdown
              dropdownOpen={cryptographyKeyDropdownOpen}
              onDropdownToggle={onCryptographyKeyDropdownToggle}
              handleChange={setDirectValue}
              selectedValue={rawAppData.algorithm}
              error={algorithmErr}
              itemsMap={CRYPTOGRAPHY_ALGORITHMS_CONFIG}
              label="Cryptography Algorithm"
              placeholder="Select Cryptography Algorithm"
              keyToChange="rawAppData"
              keyToAdd="algorithm"
              rawAppData={rawAppData}
              tooltipContent={cryptographyAlgorithmTooltipContent}
              tooltipId="tooltip-cryptography-algorithm"
            />
            <CreateAppMenuDropdown
              dropdownOpen={envDropdwnOpen}
              onDropdownToggle={onEnvDropdownToggle}
              handleChange={setDirectValue}
              selectedValue={rawAppData.environment}
              error={environmentErr}
              itemsMap={ENVIRONMENTS_MAP}
              label="Environment"
              placeholder="Select Environment"
              keyToChange="rawAppData"
              keyToAdd="environment"
              rawAppData={rawAppData}
              tooltipContent={environmentTooltipContent}
              tooltipId="tooltip-environment"
            />
          </div>
        ) : null}
        {rawAppData.type === APP_TYPE_IDENTITY ? (
          <div>
            <CreateAppMenuDropdown
              dropdownOpen={identityTypeDropdownOpen}
              onDropdownToggle={onIdentityTypeDropdownToggle}
              handleChange={setDirectValue}
              selectedValue={rawAppData.identityType}
              error={identityTypeErr}
              itemsMap={IDENTITY_MAP}
              label="Identity Type"
              placeholder="Select Identity Type"
              keyToChange="rawAppData"
              keyToAdd="identityType"
              rawAppData={rawAppData}
              tooltipContent={identityProtocolTooltipContent}
              tooltipId="tooltip-identity-protocol"
            />
            <CreateAppMenuDropdown
              dropdownOpen={envDropdwnOpen}
              onDropdownToggle={onEnvDropdownToggle}
              handleChange={setDirectValue}
              selectedValue={rawAppData.environment}
              error={environmentErr}
              itemsMap={ENVIRONMENTS_MAP}
              label="Environment"
              placeholder="Select Environment"
              keyToChange="rawAppData"
              keyToAdd="environment"
              rawAppData={rawAppData}
              tooltipContent={environmentTooltipContent}
              tooltipId="tooltip-environment"
            />
          </div>
        ) : null}
        {rawAppData.type === APP_TYPE_BLOCKCHAIN ? (
          <div>
            {" "}
            <ChainDropdown
              chainsDropdownOpen={chainsDropdwnOpen}
              onChainsDropdownToggle={onChainsDropdownToggle}
              handleChainChange={setDirectValue}
              selectedChain={rawAppData.network}
              networkErr={networkErr}
              rawAppData={rawAppData}
            />
            <CreateAppMenuDropdown
              dropdownOpen={envDropdwnOpen}
              onDropdownToggle={onEnvDropdownToggle}
              handleChange={setDirectValue}
              selectedValue={rawAppData.environment}
              error={environmentErr}
              itemsMap={
                rawAppData.network === ARBITRUM_CHAIN_NAME
                  ? ARBITRUM_ENVIRONMENTS_MAP
                  : ENVIRONMENTS_MAP
              }
              label="Environment"
              placeholder="Select Environment"
              keyToChange="rawAppData"
              keyToAdd="environment"
              rawAppData={rawAppData}
              tooltipContent={environmentTooltipContent}
              tooltipId="tooltip-environment"
            />
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <div className="app-modal-cta">
          <Button
            disabled={inProgress}
            onClick={onClose}
            className={clx("primary-btn", "clr-transp")}
          >
            Cancel
          </Button>
          <Button
            disabled={inProgress}
            onClick={onCTA}
            className={clx("primary-btn")}
          >
            Save
          </Button>
        </div>
      </ModalFooter>
      {inProgress && <Loader />}
    </Modal>
  );
};
