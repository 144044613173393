import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  ModalBody,
} from "reactstrap";
import clx from "classnames";
import { CustomInput } from "../../../../components";
import { CryptoUtils } from "frontend-utils";
import { BsTrash } from "react-icons/bs";
import {
  TRUNCATED_KEY_DESC,
  API_SECRET_DESC,
  API_KEY_DESC,
} from "../../../../constants";

export const ApiKeysContainer = ({
  keysArr,
  onKeyNameChange,
  setKeysArr,
  errorAtIdx,
  onDeleteApiKey,
}) => {
  const [open, setOpen] = useState("0");

  const toggle = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    if (errorAtIdx === -1) return;
    setOpen(errorAtIdx.toString());
  }, [errorAtIdx]);

  const onCreateNewKey = async () => {
    const { publicKey, privateKey } = await CryptoUtils.generateKeyPair(true);
    const signedMsg = await CryptoUtils.signMessage(
      "Hello",
      privateKey,
      publicKey
    );
    const currentState = [
      {
        apiSecret: privateKey,
        apiKey: publicKey,
        signedMsg,
        name: `Key ${keysArr.length + 1}`,
      },
      ...keysArr,
    ];
    setKeysArr(currentState);
    setOpen("0");
  };

  const onDelete = (e, idx) => {
    e.stopPropagation();
    onDeleteApiKey(idx);
  };

  const children = keysArr.map((elm, idx) => {
    const { apiKey, apiSecret, name = "" } = elm;

    return (
      <AccordionItem key={idx}>
        <AccordionHeader className="accordion-header" targetId={idx.toString()}>
          {name || "Default"}
          <div onClick={(e) => onDelete(e, idx)} className="trash-icon">
            <BsTrash title="Delete" />
          </div>
        </AccordionHeader>
        <AccordionBody accordionId={idx.toString()}>
          <CustomInput
            label="Key Name"
            fullWidth={true}
            onChange={(e) => onKeyNameChange(e, idx)}
            value={name}
            placeholder="Enter Key Name"
            id="apiKey"
            error={errorAtIdx === idx}
            required={true}
          />
          {apiSecret && (
            <div className="modal-note">
              Please save your Api <b>Key</b> and <b>Secret</b>. We won't be
              able to recover this once the window closes.
            </div>
          )}
          <CustomInput
            label="Api Key"
            fullWidth={true}
            disabled={true}
            value={apiKey}
            id={`apiKey-${idx}`}
            withCopy={apiSecret ? true : false}
            description={apiSecret ? API_KEY_DESC : TRUNCATED_KEY_DESC}
          />
          {apiSecret && (
            <CustomInput
              label="Api Secret"
              fullWidth={true}
              disabled={true}
              value={apiSecret}
              id={`apiSecret-${idx}`}
              withCopy={true}
              description={API_SECRET_DESC}
            />
          )}
        </AccordionBody>
      </AccordionItem>
    );
  });
  return (
    <ModalBody className="app-modal-body">
      <div className="app-modal-cta mb-3">
        <Button
          onClick={onCreateNewKey}
          className={clx("primary-btn", "clr-transp")}
        >
          Create New Key
        </Button>
      </div>
      {keysArr.length ? (
        <Accordion open={open} toggle={toggle}>
          {children}
        </Accordion>
      ) : (
        <div className="modal-empty-state">No Keys Found</div>
      )}
    </ModalBody>
  );
};
