import clx from "classnames";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;

const INITIAL_STATE = {
  1: "",
  2: "",
  3: "",
  4: "",
  5: "",
  6: "",
};

export const OTPField = ({
  onChangeHandler,
  hasError = false,
  isDisabled = false,
}) => {
  const fieldRefs = useRef({});
  // can be dynamic prop based on the length of the OTP
  const [values, setValues] = useState({ ...INITIAL_STATE });

  useEffect(() => {
    if (hasError) {
      setValues({ ...INITIAL_STATE });
      if (fieldRefs && fieldRefs.current && fieldRefs.current[1])
        updateFocus(1);
    }
  }, [hasError]);

  useEffect(() => {
    //set focus to first input
    if (fieldRefs && fieldRefs.current && fieldRefs.current[1]) updateFocus(1);
  }, []);

  /**
   * Call prop (Container) change handler
   */
  useEffect(() => {
    const entries = Object.values(values).join("");
    if (entries) onChangeHandler(entries);

    // once the user has entered all fields for OTP loose focus from all fields
    if (entries.length === 6) {
      Object.keys(fieldRefs.current).forEach((key) => {
        fieldRefs.current[key].blur();
      });
    }
  }, [onChangeHandler, values]);

  /**
   *
   * @param {String} value - value of the input
   * @returns Boolean - true if the value is a number
   */
  const isValidInput = (value) => {
    return !isNaN(parseInt(value, 10)) && value < 10;
  };

  /**
   * @param {String} targetId - target id of element
   * @param {String} value - value of target input
   * @description - updates the state of individual input
   */
  const setValueAtTarget = (targetId, value) => {
    if (targetId > 0)
      setValues((prevState) => ({ ...prevState, [targetId]: value }));
  };

  /**
   * @param {String} targetId - target id of element
   * @description - update focus to corresponding input target
   */
  const updateFocus = (targetId) => {
    const fieldRef = fieldRefs.current[targetId];
    if (fieldRef) fieldRef.focus();
  };

  const onChange = useCallback(
    (e) => {
      e.preventDefault();
      const { id } = e.target;
      let value = e.target.value;
      // if field is already filled replace
      //the current value with incoming value
      if (value.length > 1)
        value =
          value.indexOf(values[id]) === 0 ? value.slice(1) : value.slice(0, 1);
      if (isValidInput(value)) {
        setValueAtTarget(id, value);
        updateFocus(parseInt(id, 10) + 1);
      }
    },
    [values]
  );

  /**
   * Handle keydown event on input
   * checks for special keys and handles accordingly
   * Handles - Backspace, Left Arrow, Right Arrow, Delete, Spacebar
   */
  const onKeyDown = useCallback(
    (e) => {
      const { id } = e.target;
      if (e.keyCode === BACKSPACE || e.key === "Backspace") {
        e.preventDefault();
        const prevTarget = parseInt(id) - 1;
        if (values[id]) setValueAtTarget(id, "");
        else {
          setValueAtTarget(prevTarget, "");
          updateFocus(prevTarget);
        }
      } else if (e.keyCode === DELETE || e.key === "Delete") {
        e.preventDefault();
        setValueAtTarget(id, "");
      } else if (e.keyCode === LEFT_ARROW || e.key === "ArrowLeft") {
        e.preventDefault();
        const prevTarget = parseInt(id) - 1;
        updateFocus(prevTarget);
      } else if (e.keyCode === RIGHT_ARROW || e.key === "ArrowRight") {
        e.preventDefault();
        const nextTarget = parseInt(id) + 1;
        updateFocus(nextTarget);
      } else if (
        e.keyCode === SPACEBAR ||
        e.key === " " ||
        e.key === "Spacebar" ||
        e.key === "Space"
      ) {
        e.preventDefault();
      }
    },
    [values]
  );

  const onPaste = useCallback((e) => {
    e.preventDefault();
    const { id } = e.target;
    const pasteData = e.clipboardData.getData("text");
    const pasteDataArray = pasteData.split("");
    for (let i = id; i <= 6 && pasteDataArray.length > 0; i++) {
      if (isValidInput(pasteDataArray[0])) {
        setValueAtTarget(i, pasteDataArray.shift());
        updateFocus(parseInt(i) + 1);
      }
    }
  }, []);

  const fieldsArr = useMemo(() => {
    return Object.keys(values).map((key) => {
      return (
        <input
          key={key}
          id={key}
          ref={(elm) => (fieldRefs.current[key] = elm)}
          className={clx("otp-field", hasError && "error")}
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={values[key]}
          data-testid={`field-${key}`}
          placeholder="0"
          onPaste={onPaste}
          autoComplete="off"
          disabled={isDisabled}
          inputMode="numeric"
          pattern="[0-9]*"
        />
      );
    });
  }, [values, hasError, onChange, onKeyDown, onPaste, isDisabled]);

  return (
    <>
      <div className="otp-field-container">{fieldsArr}</div>
    </>
  );
};
