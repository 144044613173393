import { BsTrash } from "react-icons/bs";
import { useSelector } from "react-redux";
import clx from "classnames";
import { selectAccountSharedWith } from "../../../../../redux/slice/dashboardSlice";
import { selectUserEmail } from "../../../../../redux/slice/authSlice";
import { SettingsHeader } from "../Common/SettingsHeader";
import styles from "./index.module.scss";
import baseStyles from "../../index.module.scss";
import { CustomInput } from "../../../../../components";
import { SettingsEmptyState } from "../Common/SettingsEmptyState";

export const ShareAccountSettings = ({
  onInputChange,
  inputValue,
  inputErr,
  onExistingItemDelete,
}) => {
  const accountSharedWith = useSelector(selectAccountSharedWith);
  const userEmail = useSelector(selectUserEmail);
  const isEmpty = accountSharedWith.length === 0;
  if (!userEmail) return null;
  return (
    <div className={baseStyles.settings_item}>
      <SettingsHeader name="Share Your Account" />
      <div className={baseStyles.settings_cotainer}>
        <div
          className={clx(
            styles.account_sharing_item_wrapper,
            isEmpty && styles.max_height
          )}
        >
          <div className={styles.account_owner}>
            <CustomInput
              wrapperClass={clx(styles.account_sharing_input)}
              label="Owner"
              value={`You (${userEmail})`}
              disabled
            />
          </div>
          <div className={styles.share_with_container}>
            <CustomInput
              wrapperClass={clx(styles.account_sharing_input)}
              customCss={clx(inputErr && styles.on_err)}
              label="Share With"
              value={inputValue}
              onChange={onInputChange}
              placeholder="Enter comma separated email addresses"
            />
            {inputErr && (
              <div className={styles.input_err_text}>{inputErr}</div>
            )}
          </div>
          <div className={styles.account_sharing_info}>
            People you add will have full access to all your apps, and can
            create and manage new apps on your behalf. They can’t re-share your
            account or create/view your account keys. As the owner, you can
            revoke these permissions at any time.
          </div>
          <ExistingAccountsList onExistingItemDelete={onExistingItemDelete} />
        </div>
      </div>
    </div>
  );
};

const ExistingAccountsList = ({ onExistingItemDelete }) => {
  const accountSharedWith = useSelector(selectAccountSharedWith);
  if (accountSharedWith.length === 0)
    return <SettingsEmptyState text="Account not shared with anyone" />;
  const existingSharedAccounts = accountSharedWith.map((val, idx) => {
    return (
      <div key={idx} className={clx(styles.account_sharing_item)}>
        <div className={styles.account_sharing_email}>{val}</div>
        <div className={styles.account_sharing_actions}>
          <BsTrash onClick={() => onExistingItemDelete(idx)} />
        </div>
      </div>
    );
  });
  return (
    <>
      <div className={styles.with_access_label}>People with access</div>
      <div className={styles.account_sharing_items_container}>
        {existingSharedAccounts}
      </div>
    </>
  );
};
