import { Navigate } from "react-router-dom";
import clx from "classnames";
import styles from "./index.module.scss";
import { Button } from "reactstrap";
import { useEffect, useMemo, useState } from "react";
import { manageAccount } from "../../../api";
import { Loader } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveAccount,
  selectAccountSharedWith,
  deleteAccountSharedWithAtIndex,
  fetchAccountInfo,
  selectNetworkStatus,
  setDashboardLoading,
  selectAccountKeys,
} from "../../../redux/slice/dashboardSlice";
import { CryptoUtils } from "frontend-utils";

import { toast } from "react-toastify";
import { validate } from "../../../utils";
import {
  ShareAccountSettings,
  AccountKeysSettings,
  TwoFactorAuthSetting,
} from "./components";
import {
  APPS_OWNER_PERMISSION,
  ENABLE_ACCOUNT_SHARING,
  MAX_SHARE_ACCOUNT_ITEM_LENGTH_MSG,
  SETTINGS_UPDATED_MSG,
  USER_NOT_REGISTERED_MSG,
} from "../../../constants";

export const SettingsDashboard = () => {
  const dispatch = useDispatch();
  const activeSharedAccount = useSelector(selectActiveAccount);
  const accountSharedWith = useSelector(selectAccountSharedWith);
  const fetchAccInfoLoading = useSelector(selectNetworkStatus);
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [removedValues, setRemovedValues] = useState([]);
  const [inputErr, setInputError] = useState(null);
  const [deletedAccountKeys, setDeletedKeys] = useState([]);
  const existingAccountKeys = useSelector(selectAccountKeys);
  const [accountKeys, setAccountKeys] = useState([]);
  const [accountKeyNameErrIdx, setAccountKeyNameErrIdx] = useState(null);

  useEffect(() => {
    dispatch(fetchAccountInfo());
  }, [dispatch]);

  useEffect(() => {
    if (existingAccountKeys.length === 0) return;
    const existingKeys = existingAccountKeys.map((keyData) => ({ ...keyData }));
    setAccountKeys(existingKeys);
  }, [existingAccountKeys]);

  const getValidatedInput = () => {
    if (inputValue.length === 0) return [];
    const inputsArr = [...new Set(inputValue.split(","))]; // remove duplicates
    let validatedEmails = [];
    inputsArr.forEach((val) => {
      const trimmedVal = val.trim();
      if (validate("email", trimmedVal).email) {
        setInputError(
          `The email address ${trimmedVal} is invalid. Please check that all addresses are formatted correctly.`
        );
        throw new Error("Invalid email");
      }
      validatedEmails.push(trimmedVal);
    });
    return validatedEmails;
  };

  const hasValidKeyNames = () => {
    const keyWithEmptyName = accountKeys.findIndex(
      (key) => key.hasOwnProperty("name") && !key.name
    );
    if (keyWithEmptyName > -1) {
      setAccountKeyNameErrIdx(keyWithEmptyName);
      return false;
    }
    return true;
  };

  const onSaveSettings = async () => {
    if (inputValue.split(",").length + removedValues.length > 10) {
      toast.error(MAX_SHARE_ACCOUNT_ITEM_LENGTH_MSG);
      return;
    }
    try {
      const itemsToUpdate = getValidatedInput();
      if (!hasValidKeyNames()) return;
      setLoading(true);
      const newAccountKeys = [];
      const updatedAccountKeys = [];
      accountKeys.forEach((keyData) => {
        const { name, accountKey, signedHelloMessage } = keyData;
        if (signedHelloMessage) {
          newAccountKeys.push({
            name,
            accountKey,
            signedHelloMessage,
          });
        } else {
          const filteredKey = existingAccountKeys.find(
            (k) => k.accountKey === accountKey
          );
          if (filteredKey && name && filteredKey.name !== name)
            updatedAccountKeys.push({
              name,
              accountKey,
            });
        }
      });

      await manageAccount({
        shareWithDevEmails: itemsToUpdate.map((val) => ({
          email: val,
          permission: APPS_OWNER_PERMISSION,
        })),
        revokeAccessFromDevEmails: removedValues,
        newAccountKeys,
        deletedAccountKeys,
        updatedAccountKeys,
      });
      setRemovedValues([]);
      setInputValue("");
      setDeletedKeys([]);
      dispatch(fetchAccountInfo());
      toast.success(SETTINGS_UPDATED_MSG);
    } catch (err) {
      dispatch(setDashboardLoading(false));
      if (err?.message === "Invalid email") return;
      if (err?.response?.data?.status === "USER_NOT_FOUND") {
        const { usersNotFound = [] } = err?.response?.data || {};
        if (usersNotFound.length > 0 && usersNotFound.length <= 2) {
          const usersNotFoundStr = usersNotFound.join(", ");
          toast.error(
            `${usersNotFoundStr} ${
              usersNotFound.length > 1 ? "are" : "is"
            } not registerd with MetaKeep`
          );
          return;
        } else {
          toast.error(USER_NOT_REGISTERED_MSG);
          return;
        }
      }
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const onAppShareInputChange = (e) => {
    setInputError(null);
    setInputValue(e.target.value);
  };

  const onExistingItemDelete = (idx) => {
    setRemovedValues((prev) => [...prev, accountSharedWith[idx]]);
    dispatch(deleteAccountSharedWithAtIndex(idx));
  };

  const onCreateNewAccountKey = async () => {
    const { publicKey, privateKey } = await CryptoUtils.generateKeyPair(true);
    const signedMsg = await CryptoUtils.signMessage(
      "Hello",
      privateKey,
      publicKey
    );
    const currentState = [
      {
        accountSecret: privateKey,
        accountKey: publicKey,
        signedHelloMessage: signedMsg,
        name: `Key ${accountKeys.length + 1}`,
      },
      ...accountKeys,
    ];
    setAccountKeys(currentState);
  };

  const onKeyNameChange = (e, idx) => {
    const { value } = e.target;
    const currentState = [...accountKeys];
    currentState[idx].name = value;
    setAccountKeys(currentState);
  };

  const onDeleteAccountKey = (idx) => {
    const keyAtIndex = accountKeys[idx];
    const currentState = [...accountKeys];
    currentState.splice(idx, 1);
    setAccountKeys(currentState);
    if (keyAtIndex?.signedHelloMessage) return;
    const currentDeletedKeys = [...deletedAccountKeys];
    currentDeletedKeys.push(keyAtIndex.accountKey);
    setDeletedKeys(currentDeletedKeys);
  };

  const isUpdated = useMemo(() => {
    if (
      accountKeys.length !== existingAccountKeys.length ||
      removedValues.length > 0 ||
      inputValue.length > 0
    )
      return true;

    if (deletedAccountKeys.length > 0) return true;

    if (accountKeys.length === existingAccountKeys.length) {
      return accountKeys.some((keyData) =>
        existingAccountKeys.some(
          (k) =>
            k.accountKey === keyData.accountKey && k?.name !== keyData?.name
        )
      );
    }
  }, [
    accountKeys,
    deletedAccountKeys.length,
    existingAccountKeys,
    inputValue.length,
    removedValues.length,
  ]);

  if (activeSharedAccount || !ENABLE_ACCOUNT_SHARING)
    return <Navigate to="/dashboard" />;
  const loading = fetchAccInfoLoading || isLoading;
  return (
    <>
      <div className={styles.setting_header}>Account</div>
      <div className={styles.settings_wrapper}>
        <div className={styles.settings_container}>
          <ShareAccountSettings
            onInputChange={onAppShareInputChange}
            inputErr={inputErr}
            inputValue={inputValue}
            onExistingItemDelete={onExistingItemDelete}
          />
          <AccountKeysSettings
            accountKeys={accountKeys}
            onCreateNewAccountKey={onCreateNewAccountKey}
            onKeyNameChange={onKeyNameChange}
            onDeleteAccountKey={onDeleteAccountKey}
            keyNameErrIdx={accountKeyNameErrIdx}
          />
        </div>
        <div className={styles.settings_cta}>
          <Button
            disabled={!isUpdated}
            onClick={onSaveSettings}
            className={clx("primary-btn lg")}
          >
            Save
          </Button>
        </div>
      </div>
      <div className={styles.setting_header}>Security</div>
      <div className={styles.settings_wrapper}>
        <div className={styles.settings_container}>
          <TwoFactorAuthSetting />
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};
