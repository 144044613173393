import {
  JsonRpcProvider,
  keccak256,
  solidityPackedKeccak256,
  getCreate2Address,
  dataLength,
} from "ethers";
import { AA_WALLET_ADDRESS_GEN_SALT, AA_WALLET_CONFIG_MAP } from "./constants";

/**
 * Web3 uses eval to parse the code, which is a security risk.
 * and breaks on firefox if used hence using ethers
 */

/**
 *  @param {string} ethAddress
 *  @returns {string} saltHex
 *  @description generate saltHex
 */
export const generateAAWalletSaltHex = (ethAddress) =>
  solidityPackedKeccak256(
    ["address", "bytes32"],
    [ethAddress, AA_WALLET_ADDRESS_GEN_SALT]
  );

/**
 * @param {string} ethAddress
 * @param {string} byteCode
 * @param {string} factoryAAWalletAddress
 * @returns {string} AAWalletAddress
 * @description generate AAWalletAddress
 */
export const generateAAWalletAddress = (
  ethAddress,
  byteCode,
  factoryAAWalletAddress
) => {
  if (!byteCode) throw new Error("byteCode is required");
  if (!factoryAAWalletAddress)
    throw new Error("factoryAAWalletAddress is required");
  const walletGenSaltHex = generateAAWalletSaltHex(ethAddress);
  return getCreate2Address(
    factoryAAWalletAddress,
    walletGenSaltHex,
    keccak256(byteCode)
  ).toLowerCase();
};

/**
 * @param {string} userEOAAddress
 * @param {string} rpcUrl
 * @param {number} chainId
 * @returns {Promise<string> | null} AAWalletAddress
 * @description
 * check if the contract is deployed on the network
 * return the address if deployed
 * otherwise return null
 */
export const getDeployedAAWallet = async (
  userEOAAddress,
  rpcUrl = null,
  chainId = null
) => {
  const aaWalletConfig = AA_WALLET_CONFIG_MAP[chainId];
  if (
    !aaWalletConfig ||
    !aaWalletConfig?.byteCode ||
    !aaWalletConfig?.factoryAAWalletAddress
  )
    return null;
  const { byteCode, factoryAAWalletAddress } = aaWalletConfig;
  const computedAAWalletAddress = generateAAWalletAddress(
    userEOAAddress,
    byteCode,
    factoryAAWalletAddress
  );
  if (await isContractDeployed(computedAAWalletAddress, rpcUrl))
    return computedAAWalletAddress;
  return null;
};

/**
 * @param {string} contractAddress
 * @param {string} rpcUrl - rpcUrl of the network on which the contract is deployed
 * @returns {Promise<boolean>}
 * @description check if the contract is deployed on the network
 */
export const isContractDeployed = async (contractAddress, rpcUrl = null) => {
  if (!rpcUrl) return false;
  try {
    const rpcProvider = new JsonRpcProvider(rpcUrl);
    const byteCode = await rpcProvider.getCode(contractAddress);
    return dataLength(byteCode) > 5;
  } catch (e) {
    console.log(e);
    return false;
  }
};
