"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.seal = void 0;
const core_1 = require("@hpke/core");
const cryptoUtils_1 = require("./cryptoUtils");
const seal = (data) => __awaiter(void 0, void 0, void 0, function* () {
    const suite = new core_1.CipherSuite({
        kem: new core_1.DhkemP256HkdfSha256(),
        kdf: new core_1.HkdfSha256(),
        aead: new core_1.Aes256Gcm(),
    });
    if (!process.env.REACT_APP_METAKEEP_FIRMWARE_HPKE_PUBLIC_KEY)
        throw new Error("HPKE Public key is not set");
    const pkR = yield suite.kem.importKey("jwk", JSON.parse(process.env.REACT_APP_METAKEEP_FIRMWARE_HPKE_PUBLIC_KEY), true);
    const sender = yield suite.createSenderContext({
        recipientPublicKey: pkR,
    });
    const ct = yield sender.seal(new TextEncoder().encode(data));
    return {
        cipherText: (0, cryptoUtils_1.bytesArrayToBase64)(ct),
        senderPublicKey: (0, cryptoUtils_1.bytesArrayToBase64)(sender.enc),
    };
});
exports.seal = seal;
