"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetakeepAuth = void 0;
const axios_1 = __importStar(require("axios"));
const storageWrapper_1 = require("./storageWrapper");
const jwt_decode_1 = require("jwt-decode");
const CryptoUtils = __importStar(require("./cryptoUtils"));
const hpkeUtils_1 = require("./hpkeUtils");
const sessionKeyUtils_1 = require("./sessionKeyUtils");
const SessionKeysUtils = __importStar(require("./sessionKeyUtils"));
const ParsedUser_1 = require("./ParsedUser");
const METAKEEP_USER_IDENTITY_PREFIX = process.env.REACT_APP_METAKEEP_IDENTITY_PROVIDER_PREFIX;
const LAST_AUTH_USER_LS = `${METAKEEP_USER_IDENTITY_PREFIX}.LastAuthUser`;
const ID_TOKEN_SUFFIX_LS = "idToken";
const REFRESH_TOKEN_SUFFIX_LS = "refreshToken";
const INVALID_REFRESH_TOKEN = "INVALID_REFRESH_TOKEN";
const REFRESH_TOKEN_EXPIRED = "REFRESH_TOKEN_EXPIRED";
const X_API_SIGNATURE_HEADER = "X-Api-Signature";
const X_TIMESTAMP_HEADER = "X-Timestamp";
const X_ORIGIN_HEADER = "x-referer-origin";
const MISSING_REQUESTER_DOMAIN = "MISSING_REQUESTER_DOMAIN";
const ID_TOKEN_EXPIRY_BUFFER = 1000 * 60; // 1 minute
const getApiHost = (baseUrl) => baseUrl.replace(/^https:\/\//, "");
class MetakeepAuth {
    constructor() { }
    static getStorageKey(key, dataType) {
        return `${METAKEEP_USER_IDENTITY_PREFIX}.${key.toLowerCase()}.${dataType}`;
    }
    static storeToLocalStorage(idToken, refreshToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const decodedJWT = (0, jwt_decode_1.jwtDecode)(idToken);
            if (!decodedJWT.user) {
                throw new Error("User not found in jwt.");
            }
            const parsedUser = new ParsedUser_1.ParsedUser(decodedJWT.user);
            const idTokenKey = this.getStorageKey(parsedUser.serializedUser, ID_TOKEN_SUFFIX_LS);
            storageWrapper_1.storage.setItem(idTokenKey, idToken);
            if (refreshToken) {
                const refreshTokenKey = this.getStorageKey(parsedUser.serializedUser, REFRESH_TOKEN_SUFFIX_LS);
                storageWrapper_1.storage.setItem(refreshTokenKey, refreshToken);
            }
        });
    }
    static clearLocalStorage() {
        const lastAuthUser = storageWrapper_1.storage.getItem(LAST_AUTH_USER_LS);
        if (lastAuthUser) {
            storageWrapper_1.storage.removeItem(LAST_AUTH_USER_LS);
            storageWrapper_1.storage.removeItem(this.getStorageKey(lastAuthUser, ID_TOKEN_SUFFIX_LS));
            storageWrapper_1.storage.removeItem(this.getStorageKey(lastAuthUser, REFRESH_TOKEN_SUFFIX_LS));
        }
    }
    static signIn(_a) {
        return __awaiter(this, arguments, void 0, function* ({ parsedUser, appId, requesterDomain, }) {
            const payload = {
                initiateAuth: {
                    user: parsedUser.rawUser,
                    appId,
                    version: "V1",
                },
            };
            const headers = new axios_1.AxiosHeaders();
            yield this.addRequesterOriginHeader(headers, requesterDomain);
            const { data } = yield this.instance.post(this.authUrl, payload, { headers });
            storageWrapper_1.storage.setItem(LAST_AUTH_USER_LS, parsedUser.serializedUser);
            const { sessionId, recoveryOptionsAvailable } = data.initiateAuthResponse;
            return ({
                sessionId,
                recoveryOptionsAvailable,
            } || null);
        });
    }
    static sendCustomChallengeAnswer(params) {
        return __awaiter(this, void 0, void 0, function* () {
            let payload = {};
            const isVerifyOtp = Boolean("verifyOtp" in params);
            const isResendOtp = Boolean("resendOtp" in params);
            const isUseRecoveryEmail = Boolean("useRecoveryEmail" in params);
            if (isVerifyOtp) {
                const verifyOtpParams = params;
                const hasWebAuthn = !!verifyOtpParams.verifyOtp.webAuthnRegistrationCredential;
                const dataToSeal = {
                    otp: verifyOtpParams.verifyOtp.otp,
                };
                if (!verifyOtpParams.verifyOtp.sessionKey) {
                    throw new Error("Session key is required.");
                }
                if (hasWebAuthn) {
                    dataToSeal.webAuthnRegistrationCredential =
                        verifyOtpParams.verifyOtp.webAuthnRegistrationCredential;
                }
                dataToSeal.sessionKeys = [verifyOtpParams.verifyOtp.sessionKey];
                const { cipherText, senderPublicKey } = yield (0, hpkeUtils_1.seal)(JSON.stringify(dataToSeal));
                payload = {
                    authChallengeAnswer: {
                        sessionId: params.sessionId,
                        verifyOtp: { encryptedOtp: { cipherText, senderPublicKey } },
                    },
                };
            }
            else if (isResendOtp) {
                payload = {
                    authChallengeAnswer: params,
                };
            }
            else if (isUseRecoveryEmail) {
                payload = {
                    authChallengeAnswer: params,
                };
            }
            else {
                throw Error("Invalid auth challenge request.");
            }
            const { data } = yield this.instance.post(this.authUrl, payload);
            if (isVerifyOtp) {
                const { idToken, refreshToken, askForRecoveryEmail = false, } = data.authChallengeAnswerResponse.verifyOtpResponse || {};
                if (!idToken) {
                    console.error("No idToken received after custom challenge answer.");
                    return undefined;
                }
                this.storeToLocalStorage(idToken, refreshToken);
                return { idToken, askForRecoveryEmail };
            }
            else if (isUseRecoveryEmail) {
                const { maskedRecoveryEmail } = data.authChallengeAnswerResponse.useRecoveryEmailResponse || {};
                return { maskedRecoveryEmail };
            }
            return data.status;
        });
    }
    static currentSession() {
        return __awaiter(this, void 0, void 0, function* () {
            const lastAuthUser = storageWrapper_1.storage.getItem(LAST_AUTH_USER_LS);
            const idToken = storageWrapper_1.storage.getItem(this.getStorageKey(lastAuthUser !== null && lastAuthUser !== void 0 ? lastAuthUser : "", ID_TOKEN_SUFFIX_LS));
            const refreshToken = storageWrapper_1.storage.getItem(this.getStorageKey(lastAuthUser !== null && lastAuthUser !== void 0 ? lastAuthUser : "", REFRESH_TOKEN_SUFFIX_LS));
            if (!idToken) {
                throw Error("No ID token present. Please authenticate");
            }
            if (!refreshToken) {
                throw Error("No refresh token present. Please authenticate");
            }
            const decodedJWT = (0, jwt_decode_1.jwtDecode)(idToken);
            const { exp = 0 } = decodedJWT;
            const idTokenExpiryTimeMillis = exp * 1000;
            if (idTokenExpiryTimeMillis - Date.now() <= ID_TOKEN_EXPIRY_BUFFER) {
                const currentSession = yield this.refreshSession(refreshToken);
                return currentSession;
            }
            return {
                getIdToken: () => idToken ? { getJwtToken: () => idToken, payload: decodedJWT } : null,
            };
        });
    }
    static signOut() {
        this.clearLocalStorage();
    }
    static refreshSession(refreshToken) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            try {
                const payload = {
                    refreshTokenAuth: {
                        refreshToken,
                    },
                };
                const { data } = yield this.instance.post(this.authUrl, payload);
                const { idToken } = data.refreshTokenAuthResponse;
                this.storeToLocalStorage(idToken);
                const decodedJWT = (0, jwt_decode_1.jwtDecode)(idToken);
                return {
                    getIdToken: () => idToken ? { getJwtToken: () => idToken, payload: decodedJWT } : null,
                };
            }
            catch (error) {
                const status = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.status;
                const shouldLogout = [
                    INVALID_REFRESH_TOKEN,
                    REFRESH_TOKEN_EXPIRED,
                ].includes(status);
                if (shouldLogout) {
                    this.signOut();
                }
                throw Error("An error occurred while refreshing id token. Please sign in again.");
            }
        });
    }
    static updateAttribute(params) {
        return __awaiter(this, void 0, void 0, function* () {
            let payload = {};
            const isUpdateRecoveryEmail = Boolean("recoveryEmail" in params);
            const isUpdateUserAttribute = Boolean("updateAttributeRequestToken" in params);
            if (isUpdateRecoveryEmail) {
                const recoveryEmailParams = params;
                payload = {
                    recoveryEmail: recoveryEmailParams.recoveryEmail,
                    appId: recoveryEmailParams.appId,
                };
            }
            else if (isUpdateUserAttribute) {
                const updateAttributeParams = params;
                payload = {
                    updateAttributeRequestToken: updateAttributeParams.updateAttributeRequestToken,
                    otpCode: updateAttributeParams.otpCode,
                };
            }
            else {
                throw Error("Invalid update attribute request.");
            }
            const headers = new axios_1.AxiosHeaders();
            headers["Content-Type"] = "application/json";
            yield this.addApiSignature(this.updateAttributeUrl, headers, payload);
            yield this.addAuthorizationHeader(headers);
            yield this.addRequesterOriginHeader(headers, params.requesterDomain);
            const { data } = yield this.instance.post(this.updateAttributeUrl, JSON.stringify(payload), { headers });
            if (isUpdateRecoveryEmail) {
                return { updateAttributeRequestToken: data.updateAttributeRequestToken };
            }
            return data.status;
        });
    }
    static addApiSignature(path, existingHeaders, requestBody) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            const timeStamp = Date.now().toString();
            const currentUser = MetakeepAuth.getCurrentAuthenticatedUser();
            const requestBodyString = requestBody ? JSON.stringify(requestBody) : "";
            const timestampHeader = `${X_TIMESTAMP_HEADER}:${timeStamp}`;
            const sessionKey = (yield SessionKeysUtils.fetchKeysFromDb({
                keyPrefix: METAKEEP_USER_IDENTITY_PREFIX,
                parsedUser: currentUser,
                sessionId: (_c = (_b = (_a = (yield this.currentSession())) === null || _a === void 0 ? void 0 : _a.getIdToken()) === null || _b === void 0 ? void 0 : _b.payload) === null || _c === void 0 ? void 0 : _c.origin_jti,
                keyType: sessionKeyUtils_1.SESSION_KEY_TYPES.CRYPTO,
            }));
            if (!sessionKey) {
                throw new Error("Session key not found.");
            }
            const signedRequest = yield CryptoUtils.signRequest(requestBodyString, sessionKey, timestampHeader, "POST", path, getApiHost((_d = process.env.REACT_APP_API_BACKEND_URL) !== null && _d !== void 0 ? _d : ""));
            existingHeaders[X_TIMESTAMP_HEADER] = timeStamp;
            if (signedRequest) {
                existingHeaders[X_API_SIGNATURE_HEADER] = signedRequest;
            }
        });
    }
    static addAuthorizationHeader(existingHeaders) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e;
            const jwtPayload = (_b = (_a = (yield this.currentSession())) === null || _a === void 0 ? void 0 : _a.getIdToken()) === null || _b === void 0 ? void 0 : _b.payload;
            if (!(jwtPayload === null || jwtPayload === void 0 ? void 0 : jwtPayload.sessionKey) && !((_c = jwtPayload === null || jwtPayload === void 0 ? void 0 : jwtPayload.sessionKeys) === null || _c === void 0 ? void 0 : _c.length))
                throw new Error("Session key not found in token.");
            const jwtToken = (_e = (_d = (yield this.currentSession())) === null || _d === void 0 ? void 0 : _d.getIdToken()) === null || _e === void 0 ? void 0 : _e.getJwtToken();
            existingHeaders["Authorization"] = `Bearer ${jwtToken}`;
        });
    }
    static addRequesterOriginHeader(existingHeaders, requesterDomain) {
        return __awaiter(this, void 0, void 0, function* () {
            const hasDomain = requesterDomain !== MISSING_REQUESTER_DOMAIN;
            if (hasDomain)
                existingHeaders[X_ORIGIN_HEADER] = requesterDomain;
        });
    }
    static setCurrentAuthenticatedUser(parsedUser) {
        storageWrapper_1.storage.setItem(LAST_AUTH_USER_LS, parsedUser.serializedUser);
    }
    static getCurrentAuthenticatedUser() {
        const lastAuthUser = storageWrapper_1.storage.getItem(LAST_AUTH_USER_LS);
        if (!lastAuthUser) {
            throw Error("No user is currently authenticated.");
        }
        return ParsedUser_1.ParsedUser.fromSerializedUser(lastAuthUser);
    }
}
exports.MetakeepAuth = MetakeepAuth;
MetakeepAuth.instance = axios_1.default.create({
    baseURL: process.env.REACT_APP_API_BACKEND_URL,
    timeout: 15000,
});
MetakeepAuth.authUrl = "/v2/user/authentication";
MetakeepAuth.updateAttributeUrl = "/v2/user/updateAttribute";
