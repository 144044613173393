import { useState } from "react";
import { SketchPicker } from "react-color";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { UncontrolledTooltip } from "reactstrap";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { selectCustomCssEnabled } from "../../redux/slice/dashboardSlice";

export const ColorPicker = ({ currentColor = "#000", onChange }) => {
  const customCssEnabled = useSelector(selectCustomCssEnabled);
  const [pickerState, setPickerState] = useState({
    displayColorPicker: false,
    color: currentColor,
  });
  const togglePicker = () => {
    setPickerState({
      ...pickerState,
      displayColorPicker: !pickerState.displayColorPicker,
    });
  };
  const handleChange = (color) => {
    const hex = color.hex;
    setPickerState((prevState) => ({ ...prevState, color: hex }));
    onChange(hex);
  };
  const { displayColorPicker, color } = pickerState;
  return (
    <div className={styles.color_picker_wrapper}>
      <div className="label-wrapper">
        <label>Color Schemes</label>
        <AiOutlineQuestionCircle id={`tooltip-settings`} />
        <UncontrolledTooltip
          popperClassName="tooltip-popper"
          target={`tooltip-settings`}
          placement="top"
          autohide={false}
        >
          <span>Choose the color scheme for your user's wallet.</span>
          <ul>
            <li>
              <b>Button Color</b>: This will apply to buttons/CTAs
            </li>
          </ul>
          {customCssEnabled && (
            <div className={styles.picker_tooltip_note}>
              <b>
                Note that this only applies if a custom CSS has not been
                provided for the button.
              </b>
            </div>
          )}
        </UncontrolledTooltip>
      </div>
      <div className="color-scheme-settings">
        <div className="color-setting">
          <div className="label-wrapper mt-1">
            <label>Button Color</label>
          </div>
          <div className={styles.swatch} onClick={togglePicker}>
            <div style={{ background: color }} className={styles.color} />
          </div>
          {displayColorPicker ? (
            <div className={styles.popover}>
              <div className={styles.cover} onClick={togglePicker} />
              <SketchPicker
                color={color}
                onChange={handleChange}
                disableAlpha
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
