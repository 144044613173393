import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../slice/authSlice";
import dashboardReducer from "../slice/dashboardSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
  },
});
