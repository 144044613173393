"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logger = void 0;
const NO_OP = () => { };
const LOG_LEVEL_DEBUG = "debug";
const LOG_LEVEL_LOG = "log";
const LOG_LEVEL_WARN = "warn";
const LOG_LEVEL_ERROR = "error";
const DEFAULT_LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || LOG_LEVEL_WARN;
class ConsoleLogger {
    constructor(options) {
        const { level } = options || {};
        // if level is error, then don't log warn or log
        this.error = console.error.bind(this);
        if (level === LOG_LEVEL_ERROR) {
            this.warn = NO_OP;
            this.log = NO_OP;
            this.debug = NO_OP;
            return;
        }
        // if level is warn, then don't log log
        this.warn = console.warn.bind(console);
        if (level === LOG_LEVEL_WARN) {
            this.log = NO_OP;
            this.debug = NO_OP;
            return;
        }
        // if level is log, then don't log debug
        this.log = console.log.bind(console);
        if (level === LOG_LEVEL_LOG) {
            this.debug = NO_OP;
            return;
        }
        this.debug = console.debug.bind(console);
    }
}
exports.logger = new ConsoleLogger({ level: DEFAULT_LOG_LEVEL });
