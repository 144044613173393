import { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserEmail,
  selectUserEmail,
  selectAuthLoading,
} from "./redux/slice/authSlice";
import { Loader } from "./components";
import { AppDashboard } from "./containers/Dashboard/AppDashboard";
import { SettingsDashboard } from "./containers/Dashboard/SettingsDashboard";

const Home = lazy(() => import("./containers/Home"));
const Dashboard = lazy(() => import("./containers/Dashboard"));
const NotFound = lazy(() => import("./containers/NotFound"));

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserEmail());
  }, [dispatch]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route element={<PrivateRoute />}>
          <Route path="dashboard" element={<Dashboard />}>
            <Route index element={<AppDashboard />} />
            <Route path="settings" element={<SettingsDashboard />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer autoClose={3000} />
    </Suspense>
  );
}

export default App;

// This will check if the user is authenticated and then only load the underlying component.
// If the user is not authenticated, it will redirect to the Login page.
const PrivateRoute = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserEmail());
  }, [dispatch]);
  const userEmail = useSelector(selectUserEmail);
  const isLoading = useSelector(selectAuthLoading);
  if (isLoading) return <Loader />;

  if (userEmail) return <Outlet />;
  return <Navigate to="/" />;
};
