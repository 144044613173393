"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AA_WALLET_CONFIG_MAP = exports.AA_WALLET_ADDRESS_GEN_SALT = exports.IS_METAKEEP_IDENTITY_PROVIDER_PREFIX_SET = exports.EVM_CHAIN_ID = exports.LS_APP_DATA_KEY = exports.THEME = void 0;
var THEME;
(function (THEME) {
    THEME["DARK"] = "DARK";
    THEME["LIGHT"] = "LIGHT";
    THEME["AUTO"] = "AUTO";
})(THEME || (exports.THEME = THEME = {}));
exports.LS_APP_DATA_KEY = "metakeep_app_data_info";
var EVM_CHAIN_ID;
(function (EVM_CHAIN_ID) {
    EVM_CHAIN_ID[EVM_CHAIN_ID["POLYGON_AMOY"] = 80002] = "POLYGON_AMOY";
    EVM_CHAIN_ID[EVM_CHAIN_ID["POLYGON_MAINNET"] = 137] = "POLYGON_MAINNET";
})(EVM_CHAIN_ID || (exports.EVM_CHAIN_ID = EVM_CHAIN_ID = {}));
exports.IS_METAKEEP_IDENTITY_PROVIDER_PREFIX_SET = Boolean(process.env.REACT_APP_METAKEEP_IDENTITY_PROVIDER_PREFIX);
// This is the salt used for generating the Business wallet address
// keccak256("METAKEEP_BUSINESS_WALLET")
// generated using https://emn178.github.io/online-tools/keccak_256.html
exports.AA_WALLET_ADDRESS_GEN_SALT = "0xd5e5760dcc499f1741859efd6d37ac7468e8e4cd000be144ba0f71168582424b";
exports.AA_WALLET_CONFIG_MAP = {
    [EVM_CHAIN_ID.POLYGON_AMOY]: {
        byteCode: "0x3d602d80600a3d3981f3363d3d373d3d3d363d730cCC70de332e4Da3Ab3E6E801B9aEd06a2E62bb45af43d82803e903d91602b57fd5bf3",
        factoryAAWalletAddress: "0xd3267742204734985a990E1739481300B1A4b3F0",
    },
    [EVM_CHAIN_ID.POLYGON_MAINNET]: {
        byteCode: "0x3d602d80600a3d3981f3363d3d373d3d3d363d730cCC70de332e4Da3Ab3E6E801B9aEd06a2E62bb45af43d82803e903d91602b57fd5bf3",
        factoryAAWalletAddress: "0xd3267742204734985a990E1739481300B1A4b3F0",
    },
};
