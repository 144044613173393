"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectAuthLoading = exports.selectUserEmail = exports.setAuthEmail = exports.getUserEmail = exports.authSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const utils_1 = require("../../utils");
const authUtils_1 = require("../../utils/authUtils");
const logger_1 = require("frontend-utils/logger");
exports.authSlice = (0, toolkit_1.createSlice)({
    name: "auth",
    initialState: {
        email: null,
        isLoading: true,
        error: null,
    },
    reducers: {
        setAuthEmail: (state, action) => {
            state.email = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(exports.getUserEmail.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(exports.getUserEmail.fulfilled, (state, action) => {
            state.email = action.payload;
            state.isLoading = false;
        });
        builder.addCase(exports.getUserEmail.rejected, (state) => {
            state.isLoading = false;
            state.email = null;
        });
    },
});
// Thunks
exports.getUserEmail = (0, toolkit_1.createAsyncThunk)("user/getUserEmail", () => __awaiter(void 0, void 0, void 0, function* () {
    // This is called when the dashboard is loaded.
    // Here we make sure that the user is logged in and has a valid session.
    if (!(yield (0, authUtils_1.isValidSession)())) {
        logger_1.logger.debug("getUserEmail: User is not logged in or has an invalid session");
        return null;
    }
    const parsedUser = yield (0, utils_1.getUserInfo)();
    if (!parsedUser) {
        logger_1.logger.debug("getUserEmail: user info is not found");
        return null;
    }
    return parsedUser.displayString;
}));
// Actions
exports.setAuthEmail = exports.authSlice.actions.setAuthEmail;
const selectUserEmail = (state) => state.auth.email;
exports.selectUserEmail = selectUserEmail;
const selectAuthLoading = (state) => state.auth.isLoading;
exports.selectAuthLoading = selectAuthLoading;
exports.default = exports.authSlice.reducer;
