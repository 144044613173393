// Path: fe/frontend-utils/indexDB-wrapper.js

// this is a mock of the indexedDB API
class InMemoryIndexDB {
  open(name, version, storeName, keyName) {
    return new Promise((resolve, _reject) => {
      this.name = name;
      this.storeName = storeName;
      this.version = version;
      this.keyName = keyName;
      if (!this.objectStores) {
        this.objectStores = {};
      }
      resolve(this);
    });
  }

  add(data) {
    return new Promise((resolve, _reject) => {
      const objectStore = this._getObjectStore();
      const key = data[this.keyName];
      objectStore[key] = data;
      resolve(key);
    });
  }

  get(key, _) {
    return new Promise((resolve, reject) => {
      const objectStore = this._getObjectStore();
      if (objectStore[key]) {
        resolve(objectStore[key]);
      } else {
        reject(new Error(`Failed to get data from database: ${key} not found`));
      }
    });
  }

  close() {
    // do nothing
  }

  _getObjectStore() {
    const storePath = `${this.name}/${this.storeName}`;

    if (!this.objectStores[storePath]) {
      this.objectStores[storePath] = {};
    }
    return this.objectStores[storePath];
  }
}

// this is a actual implementation of the indexedDB API
class IndexedDB {
  constructor() {
    this.name = null;
    this.keyName = null;
    this.version = null;
    this.db = null;
    this.storeName = null;
    this.objectStoreNames = [];
  }

  open(name, version, storeName, keyName) {
    return new Promise((resolve, reject) => {
      if (this.db) {
        resolve(this.db);
        return;
      }

      const request = window.indexedDB.open(name, version);

      request.onsuccess = (event) => {
        this.db = event.target.result;
        this.name = name;
        this.storeName = storeName;
        this.keyName = keyName;
        this.version = version;
        this.objectStoreNames = this.db.objectStoreNames;
        resolve(this.db);
      };

      request.onerror = (event) => {
        reject(
          new Error(
            `Failed to open Database ${name}@{version}: ${event.target.errorCode}`
          )
        );
      };

      request.onupgradeneeded = (event) => {
        this.db = event.target.result;
        this.name = name;
        this.storeName = storeName;
        this.keyName = keyName;
        this.version = version;
        this.objectStoreNames = this.db.objectStoreNames;

        const objectStore = this.db.createObjectStore(this.storeName, {
          keyPath: this.keyName,
        });
        objectStore.createIndex(this.keyName, this.keyName, { unique: true });

        objectStore.transaction.oncomplete = (event) => {
          resolve(this.db);
        };
      };
    });
  }

  close() {
    if (!this.db) return;
    this.db.close();
    this.db = null;
  }

  add(data) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(this.storeName, "readwrite");
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.put(data);

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(
          new Error(`Failed to add data to database: ${event.target.errorCode}`)
        );
      };
    });
  }

  get(key) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(this.storeName, "readonly");
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.get(key);
      request.onsuccess = (event) => {
        if (event.target.result) {
          resolve(event.target.result);
        } else {
          reject(new Error(`No data found in database for ${key}`));
        }
      };
    });
  }
}

// Create a singleton in-memory DB so that data can be shared for the same session.
const inMemoryDb = new InMemoryIndexDB();

// Wrapper Class that will use the indexedDB API or the InMemoryIndexDB class
export const indexDBWrapperInstance = (function () {
  async function createInstance(name, version, storeName, keyName) {
    try {
      const instance = new IndexedDB();
      // we will just try opening and closing the db to check if it exists.
      // else we will use InMemory DB
      await instance.open(name, version, storeName, keyName);
      await instance.close();
      return instance;
    } catch (e) {
      console.log("Using InMemory indexDB");
      console.error(e);
      return inMemoryDb;
    }
  }

  return {
    getInstance: async function (name, version, storeName, keyName) {
      return await createInstance(name, version, storeName, keyName);
    },
  };
})();
